.recruiter-contact-link {
  display: flex;
  align-items: center;

  line-height: 0;

  margin-left: 15px;

  color: $color-coolblue;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.recruiter-contact-link__image {
  margin-right: 5px;

  svg {
    width: 24px;
    height: 24px;
  }

}
