@use "sass:math";

.nav-header .about-banner .container {
  background-color: $color-greyblue;

  .container__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.about-banner {
  height: 0;
  overflow: hidden;
  transition: none;
}

.about-banner__content {
  width: math.percentage(math.div(8, 12));

  @include media('>medium') {
    width: math.percentage(math.div(7, 12));
  }
}

.about-banner__title {
  @include typography_h3();
  font-size: 16px;
  line-height: 20px;
  color: $color-dayblue;
  margin-top: 0;
  margin-bottom: grid-size(2);
}

.about-banner__text {
  &--mobile {
    display: inline;

    @include media('>small') {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media('>small') {
      display: inline;
    }
  }
}

.about-banner__link {
  display: inline;
}

.about-banner__aside {
  position: relative;

  @include media('>medium') {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.about-banner__video-wrapper {
  width: 104px;

  @include media('>medium') {
    width: 145px;
  }

  .video__play-button {
    width: 30px;
    height: 30px;
    background-size: 30px;
  }
}

.about-banner__close-button {
  cursor: pointer;
  position: absolute;
  top: -8px;
  right: -8px;
  border: none;
  background-color: $color-white;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 0;
  margin-left: grid-size(3);

  @include media('>medium') {
    position: relative;
    top: 0;
    right: 0;
  }

  svg {
    position: absolute;
    width: 16px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $color-coolblue;
  }
}
