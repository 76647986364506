$flip-layout: ">medium";
$border: 1px solid #3DB1EB;
$mobile-padding: 24px;

.nav-footer {
  --nav-footer-background: var(--theme-background-primary);

  font-size: 13px;
  color: $color-white;
  background-color: var(--nav-footer-background);

  padding-top: 38px;
  padding-bottom: 30px;

  a {
    color: $color-white;
  }

  @include media($flip-layout) {
    padding-top: 50px;
  }
}

.nav-footer__sections {
  display: flex;
  flex-direction: column;

  @include media($flip-layout) {
    flex-direction: row;

    .nav-footer__left-section {
      flex: 2.5;
      padding-right: 30px;
    }

    .nav-footer__right-section {
      flex: 1;
    }
  }
}

.nav-footer__right-section {
  display: flex;
  flex-direction: column-reverse;

  @include media($flip-layout) {
    flex-direction: column;
  }
}

.nav-footer__block {
  margin-bottom: $mobile-padding;
  max-width: 350px;

  a {
    font-family: $font-opensans--bold;
  }

  .chevron-link {
    margin-top: 12px;
  }

  @include media($flip-layout) {
    margin-bottom: 60px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.nav-footer__title {
  font-family: $font-opensans--bold;
  font-size: 14px;
  line-height: 15px;

  margin-bottom: 4px;

  @include media($flip-layout) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.nav-footer__social {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-bottom: $border;
  padding-bottom: $mobile-padding;

  .social-icons {
    margin-top: 8px;
  }

  @include media($flip-layout) {
    margin-bottom: 30px;
    align-items: flex-start;
    border: 0;
  }
}

.nav-footer__site-link {
  display: block;
  margin-top: 8px;

  svg {
    width: 13px;
    height: 13px;
    margin-left: 4px;
  }
}

.nav-footer__links {
  border-top: $border;
  border-bottom: $border;

  padding-top: $mobile-padding;
  padding-bottom: $mobile-padding;

  margin-bottom: $mobile-padding;

  @include media($flip-layout) {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.nav-footer__link {
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    font-family: $font-opensans;
  }

  @include media($flip-layout) {
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

}

.nav-footer__bottom-links {
  margin-top: 40px;
  text-align: center;
}
