.vacancy-page {
  .nav-header {
    position: absolute;
  }
  .cookie-bar {
    z-index: 5;
  }
}

// Experiment with foldouts.
.vacancy-page--foldouts {
  @include media("<=small") {
    .page-content {
      display: flex;
      flex-direction: column;

      .container, .recruiter {
        order: 3;
      }

      .container--narrow {
        order: 1;

        .container__inner {
          padding-top: 0;
        }
      }

      .calendar, .interactive-calendar {
        order: 2;
      }
    }
  }
}

.vacancy-page__apply-bar {
  position: fixed;
  z-index: 5;
  bottom: 0;
  width: 100%;
  background-color: $color-white;
  transform: translateY(100%);
  transition: transform 300ms;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);

  @include media('>medium') {
    top: 0;
    bottom: auto;
    transform: translateY(-100%);
  }
}

.vacancy-page__apply-bar--visible {
  transform: translateY(0);
  padding-bottom: env(safe-area-inset-bottom);
}

.vacancy-page__apply-bar--visible + .page-content + .nav-footer {
  margin-bottom: grid-size(18);
  @include media('>medium') {
    margin-bottom: 0;
  }
}

.vacancy-page--apply,
.vacancy-page--applied {
  .nav-header__button,
  .nav-footer,
  .nav-header__items {
    @include media('>large') {
      display: none;
    }
  }

  .page-header,
  .page-content .apply {
    .container__inner {
      @include media('>medium') {
        max-width: grid-size(163);
      }
    }
  }
}

.vacancy-page--apply,
.vacancy-page--alternatives {
  .page-header___background {
    height: 129px;
    @include media('>small') {
      height: 220px;
    }
  }
}

.vacancy-page--apply {
  .about-banner {
    display: none;
  }
  .page-content .container:last-child {
    margin-bottom: 0;
  }
}

