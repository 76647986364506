@use "sass:math";

.not-available {
  hr {
    border: none;
    border-top: 1px solid $color-brightgrey;
    margin: 12px 0;
  }
}

.not-available__content {
  background-color: $color-greyblue;
  border-radius: $border-radius;
  padding: grid-size(3) grid-size(5) grid-size(7);
  margin-top: grid-size(10);
  margin-bottom: grid-size(10);
}

.not-available__title {
  @include typography_h2;
}

.not-available__text {
  @include typography_p;
  margin-bottom: grid-size(4);

  @include media('>medium') {
    width: math.percentage(math.div(6, 8));
  }
}
