.active-filters-tag {
  display: flex;
  min-width: 0;
  padding: 7px;
  background-color: $color-greyblue;
  border-radius: $border-radius;

  &:hover {
    cursor: pointer;
    background-color: $color-arcticblue;

    .active-filters-tag__icon svg {
      fill: $color-coolblue;
    }
  }
}

.active-filters-tag__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: $font-opensans--semibold;
  font-size: 13px;
  line-height: 16px;

  pointer-events: none;
}

.active-filters-tag__icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-top: 4px;

  pointer-events: none;

  svg {
    display: block;

    width: 10px;
    height: 10px;

    fill: $color-raingrey;
  }
}
