.banner-block {

}

.banner-block__inner {
  display: block;
  overflow: hidden;
  border-radius: $border-radius;

  @include media(">small") {
    display: flex;
    flex-direction: row;

    max-height: 270px;
    min-height: 180px;
  }
}

.banner-block__image,
.banner-block__content {
  padding: grid-size(3);

  @include media(">small") {
    flex: 1;
    padding: grid-size(8);
  }
}

.banner-block__image {
  min-height: 150px;
  transition: $image-transition;

  @include media(">small") {
    min-height: inherit;
  }
}

.banner-block__link {
  @include media(">small") {
    &:hover {
      .banner-block__image {
        transform: scale(1.1);
      }

      .chevron-link {
        text-decoration: underline;
      }
    }
  }
}

.banner-block__content {
  background-color: $color-coolblue;
  color: $color-white;
  z-index: 1;

  @include media(">small") {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .chevron-link {
    color: $color-white;

    display: block;
    margin-top: grid-size(3);
    margin-bottom: grid-size(2);

    @include media(">small") {
      margin-top: grid-size(3);
      margin-bottom: 0;
    }
  }
}

.banner-block__title {
  font-family: $font-dobraslab-book;
  font-size: 20px;
  line-height: 20px;

  @include media(">small") {
    font-size: 32px;
    line-height: 32px;
  }
}

.banner-block__text {
  margin-top: grid-size(3);
}
