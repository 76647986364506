@use "sass:math";
$spacing: 15px;
$spacing-list: 25px;

.language-switch-extended {
  font-size: 14px;
  line-height: 14px;
  color: $color-coolblack;

  cursor: default;
}

.language-switch-extended__title {
  padding: $spacing;
  color: $color-raingrey;

  .language-switch--list & {
    padding: $spacing-list;
  }
}

.language-switch-extended__country {
  border-top: 1px solid $color-grey;
  padding-bottom: math.div($spacing, 2);

  .language-switch--list & {
    padding-bottom: math.div($spacing-list, 2);
  }
}

.language-switch-extended__country-heading {
  display: flex;
  align-items: center;

  padding: $spacing 15px math.div($spacing, 2);

  .language-switch--list & {
    padding: $spacing-list 15px math.div($spacing-list, 2);
  }
}

.language-switch-extended__country-flag {
  margin-right: 10px;
  width: 13px;
  height: 13px;
  background-size: cover;
  @include language-flag-modifiers;
}

.language-switch-extended__country-label {
  font-family: $font-opensans--bold;
}

.language-switch-extended__country-languages {
  padding-left: 15px + 13px + 10px;

  a, div {
    display: block;
    padding: math.div($spacing, 2) 0;
  }
}
