// Add A/B testing classes here

// Add the .fixed-navbar class to the body tag and all will be well
.fixed-navbar {
  .nav-header {
    position: fixed !important;
    transform: none !important;
  }

  .apply-bar {
    position: fixed;
    transform: translateY(100%);

    @include media('>medium') {
      top: -20px;
    }
    @include media('>large') {
      top: -2px;
    }
  }

  .vacancy-header {
    @include media('>medium') {
      margin-top: 120px;
    }
    @include media('>large') {
      margin-top: 140px;
    }
  }
}
