.talentpool-popover {
  position: relative;
  height: 100%;
}

.talentpool-popover__pool-selection {
  padding: grid-size(3) grid-size(5);
}

.talentpool-popover__pool-selection--hidden {
  display: none;
}

.talentpool-popover__button-row {
  padding: grid-size(6) 0;
  button {
    width: 100%;
  }
}

.talentpool-card-list__item {
  margin-bottom: grid-size(2);
  &:last-child {
    margin-bottom: 0;
  }
}

.talentpool-card {
  @include button-border;
  box-shadow: none;
  position: relative;

  a:hover,
  a:active {
    text-decoration: none;
  }

  &:hover {
    box-shadow: none;
  }

  &--group {
    padding: 0 grid-size(2) grid-size(2);
    > .talentpool-card__title {
      padding-left: 26px;
      margin-left: 6px;
    }
  }

  &--collapsed {
    padding-bottom: 0;
    > .talentpool-card__title {
      background-image: static-url('images/chevron-down-blue.svg');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 12px;
    }
    .form__options--radio-list {
      max-height: 0;
      overflow: hidden;
    }
  }
}

.form__options--radio-list {
  max-height: 100vh;
  transition: max-height .3s ease-out;
}

.talentpool-card__inner {
  margin-left: 40px;
  padding-bottom: grid-size(3);
}

.talentpool-card__radio:checked {
  & + .talentpool-card__title {
    &:after {
      transform: rotate(180deg);
    }
  }
  & + .talentpool-card__title + .talentpool-card__inner {
    display: block;
  }
}

.talentpool-card__radio {
  position: absolute;
  top: grid-size(3);
  left: grid-size(3);
  margin: 0;
  margin-top: 3px;
}

.talentpool-card__title {
  @include typography_h3;
  color: $color-coolblue;
  margin: 0;
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: grid-size(3) grid-size(3) grid-size(3) grid-size(4);

  &:before {
    top: 14px !important;
  }

  &:after {
    position: absolute;
    content: '';
    right: grid-size(3);
    top: grid-size(4);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 15px;
    width: 15px;
  }
}

.talentpool-card__text {
  color: $color-text;
  padding: grid-size(3) grid-size(3) grid-size(1) grid-size(3);
}
