@use 'sass:math';
$circle-border-size: 6px;
$circle-size: 30px;

.apply-timeline-step {
  position: relative;
  padding-left: 36px;
  padding-bottom: 24px;
  margin-left: math.div($circle-size, 2);

  &--active {
    padding-left: 40px;
    padding-bottom: 50px;

    & + .apply-timeline-step {
      padding-bottom: 16px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -2px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: $color-greyblue;
  }

  &:first-of-type {
    &:before {
      top: 19px;
    }
  }

  &:last-of-type {
    &:before {
      bottom: calc(100% - 19px);
    }
  }
}

.hide-timeline {
  &:before {
    display: none;
  }
}

.check-green {
  width: 26px !important;
  height: 26px !important;
  background-color: #00b900 !important;
  top: 4px !important;
}

.text-only {
  padding: 0 !important;
  width: 100% !important;
  background-color: $color-white !important;
  &:before {
    border-color: $color-white !important;
  }
  a {
    color: black;
    text-decoration: underline;
  }
}

.apply-timeline-step.apply-timeline-step--completed.hide-timeline {
  padding-bottom: 48px;
}

.apply-timeline-step__number {
  display: block;
  box-sizing: content-box;
  position: absolute;
  left: 0;
  top: 16px;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  font-family: $font-opensans--bold;
  font-size: 18px;
  text-align: center;
  line-height: $circle-size;
  background-color: #cce9f9;
  border-radius: 100%;
  border: solid 3px $color-white;

  .apply-timeline-step--active & {
    border: solid 5px $color-coolblue;
    top: 8px;
    width: 21px;
    height: 21px;
    background-color: $color-white;
  }

  .apply-timeline-step--completed & {
    width: 20px;
    height: 20px;
    border: none;
    background-image: static-url('images/check.svg');
    background-color: #0090e3;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.apply-timeline-step__content {
  position: relative;
  padding: 16px;
  border-radius: 3px;
  background-color: $color-greyblue;
  width: 85%;

  @include media('>medium') {
    width: 75%;
    padding: 16px 24px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 13px;
    left: 0;
    width: 0;
    height: 0;
    transform: translateX(-100%);
    border-style: solid;
    border-width: 11px 16px 11px 0;
    border-color: transparent $color-greyblue transparent transparent;
  }

  .apply-timeline-step--active & {
    width: 100%;
    color: $color-white;
    background-color: $color-coolblue;

    &:before {
      border-width: 11px 20px 11px 0;
      border-color: transparent $color-coolblue transparent transparent;
    }
  }

  p {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.apply-timeline-step--active {
  &:before {
    top: 12px;
  }
}

.apply-timeline-step__title {
  @include typography_h3;
  margin-top: 0;
}

.apply-timeline-step__cta {
  .apply-timeline-step--active & {
    color: white;

    svg path {
      fill: white;
    }
  }
}

.apply-timeline-step__cta.chevron-link {
  font-family: $font-opensans;
  font-size: 14px;

  path {
    fill: $color-coolblue;
  }
}

.apply-timeline-step__button {
  margin-top: 10px;
}
