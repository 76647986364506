.form__checkbox {
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .form__checkbox-label {
    display: inline-block;
  }

  .form__checkbox-widget {
    width: 15px;
    height: 15px;
    border-radius: $border-radius;
    border: 1px solid $color-grey;
    margin: grid-size(1) grid-size(3) grid-size(1) 0;

    @include input-hover;
  }

  input:checked + .form__checkbox-widget {
    border-color: $color-coolblue;
    background-color: $color-coolblue;
    background-image: static-url('images/check.svg');
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
  }
}
