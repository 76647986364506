.workarea-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
}

@mixin workarea-grid-columns($n) {
  @include media(">large") {
    grid-template-columns: repeat($n, 1fr);
  }
}

.workarea-grid--2-columns {
  @include workarea-grid-columns(2);
}

.workarea-grid--3-columns {
  @include workarea-grid-columns(3);
}

.workarea-grid--4-columns {
  @include workarea-grid-columns(4);
}
