.language-switch-button {
  --language-switch-hover: var(--theme-hover);
}

.language-switch-button__inner {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 14px;
}

.language-switch-button--list {
  color: $color-coolblue;

  .language-switch-button__title {
    display: none;
    visibility: hidden;
  }

  .language-switch-button__icon {
    svg path {
      fill: $color-silver;
    }
  }
}

.language-switch-button--header {
  color: $color-white;
  font-family: $font-opensans--bold;
  border-radius: $border-radius;

  &:focus,
  &:hover {
    cursor: pointer;
    background-color: var(--language-switch-hover);
  }

  .language-switch-button__inner {
    padding: 10px 10px;
  }

  .language-switch-button__title-expanded {
    display: none;
    visibility: hidden;
  }

  .language-switch-button__icon {
    transform: rotate(90deg);
  }
}

.language-switch-button--header.language-switch-button--active {
  .language-switch-button__icon {
    transform: rotate(-90deg);
  }
}

.language-switch-button__flag {
  margin-right: 10px;
  width: 22px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  @include language-flag-modifiers;
}

.language-switch-button__title {
  margin-right: 10px;
}

.language-switch-button__title-expanded {
  flex: 1;
  margin-right: 10px;
}
