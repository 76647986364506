.account-menu-button {
  border-radius: $border-radius;
  padding: 10px;

  &:focus,
  &:hover {
    cursor: pointer;
    background-color: $nav-link-hover;
  }
}

.account-menu-button__inner {
  display: flex;
  align-items: center;
}

.account-menu-button__greeting {
  padding: 0 15px;
  color: $color-white;
  font-size: 14px;
  line-height: 14px;

  span {
    display: block;
    font-family: $font-opensans--bold;
  }
}

.account-menu-button__icon {
  transform: rotate(90deg);

  svg {
    display: block;
  }
}

.account-menu-button--active {
  .account-menu-button__icon {
    transform: rotate(-90deg);
  }
}
