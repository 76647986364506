.image-block {
  .container__inner {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    @include media('>large') {
      max-width: 1038px;
    }
  }
}

.image-block__image {
  height: 100%;
  border-radius: $border-radius;
}
