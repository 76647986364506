.progress-bar {
  border-bottom: 2px solid $color-grey;
  position: relative;
  z-index: 4;
}

.progress-bar__indicator {
  position: absolute;
  left: 0;
  top: -15px;
  transition: transform 300ms ease-in-out;

}
.progress-bar__icon {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 200vw;
    height: 2px;
    background-color: $color-hotorange;
    position: absolute;
    left: -200vw;
    top: 15px;
  }
}
