.tooltip {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: $color-hurricanegrey;
  background-color: $color-greyblue;
  box-shadow: 0px 2px 8px rgba(68, 68, 68, 0.5);
  max-width: 500px;
  max-height: 50px;
  opacity: 1;
  transition: max-width 200ms ease-out,
  opacity 200ms ease-out,
  max-height 100ms ease-out 100ms,
  margin-top 100ms ease-out 100ms;
  margin-top: grid-size(4);
}

.tooltip--large {
  max-height: none;
  margin-top: 0;

  .tooltip__arrow {
    width: 20px;
    height: 20px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);

    @include media('>medium') {
      left: 48px;
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .tooltip__content {
    padding: grid-size(4);
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include media('>medium') {
      flex-direction: row;
    }

    svg {
      flex: 0 0 46px;
      width: 46px;
      margin-right: 0;
      margin-bottom: grid-size(4);

      @include media('>medium') {
        margin-right: grid-size(6);
        margin-bottom: grid-size(0);
      }
    }
  }

  .tooltip__text {
    @include typography_h3;
    margin-top: 0;
    white-space: normal;
    text-align: left;
  }
}

.tooltip--large.tooltip--arrow-bottom .tooltip__arrow {
  transform: translate(-50%, 50%) rotate(45deg);

  @include media('>medium') {
    transform: translateY(50%) rotate(45deg);
  }
}

.tooltip--no-shadow {
  box-shadow: none;
}

.tooltip--closed {
  max-width: 1px;
  max-height: 0;
  opacity: 0;
  margin-top: 0;
  pointer-events: none;
}

.tooltip--blue {
  color: $color-white;
  background-color: $color-coolblue;

  .tooltip__arrow {
    background-color: $color-coolblue;
  }
}

.tooltip--orange {
  color: $color-white;
  background-color: $color-hotorange;

  .tooltip__arrow {
    background-color: $color-hotorange;
  }
}

.tooltip__content {
  display: flex;
  align-items: center;
  padding: grid-size(2) grid-size(9) grid-size(2) grid-size(4);
  overflow: hidden;

  svg {
    width: grid-size(6);
    height: auto;
    margin-right: grid-size(3);
  }

  @include media('>medium') {
    padding-right: grid-size(11)
  }
}

.tooltip__text {
  @include typography_p;

  @include media('>medium') {
    white-space: nowrap;
  }
}

.tooltip__arrow {
  position: absolute;
  display: block;
  background-color: $color-greyblue;
  z-index: 0;
  width: grid-size(4);
  height: grid-size(4);
  top: 1px;
  left: 48px;
  border-radius: $border-radius;
  transform: translateY(-50%) rotate(45deg);

  .tooltip--arrow-bottom & {
    top: auto;
    bottom: 1px;
    transform: translateY(50%) rotate(45deg);
  }
}

.tooltip__close {
  position: absolute;
  top: 11px;
  right: 12px;
  border: none;
  background-color: transparent;
  padding: 0;
  pointer-events: all;

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
    width: 16px;
    height: auto;
  }
}
