.interview-block {
  border-top: solid 1px $color-brightgrey;
  padding-top: $margin-medium;

  @include media('>medium') {
    padding-top: $margin;
  }
}

.interview-block__question,
.interview-block__answer {
  display: flex;
  margin-bottom: $margin-medium;

  @include media('>medium') {
    margin-bottom: $margin;
  }
}

.interview-block__question {
}

.interview-block__answer {
  flex-direction: row-reverse;
}

.interview-block__avatar {
  position: relative;
  overflow: hidden;
  flex: 0 0 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 6px;
  color: $color-white;
  font-family: $font-opensans--bold;
  font-size: 15px;
  text-align: center;
  line-height: 32px;

  @include media('>medium') {
    flex: 0 0 42px;
    height: 42px;
    line-height: 42px;
  }

  .interview-block__question & {
    margin-right: $margin-small;
    background-color: $color-hotorange;

    @include media('>medium') {
      margin-right: $margin-medium;
    }
  }

  .interview-block__answer & {
    margin-left: $margin-small;
    background-color: $color-coolblue;

    @include media('>medium') {
      margin-left: $margin-medium;
    }
  }
}
.interview-block__avatar svg,
.interview-block__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: auto;

  @include media('>medium') {
    width: 42px;
  }
}

.interview-block__text {
  position: relative;
  padding: 16px 24px;
  border-radius: $border-radius;

  @include media('>medium') {
    max-width: 75%;
  }

  &:before,
  &:after {
    display: block;
    position: absolute;
    top: 14px;
    width: 0;
    height: 0;
    border-style: solid;

    @include media('>medium') {
      top: 16px;
    }
  }

  .interview-block__question & {
    background-color: $color-antarcticblue;

    &:before {
      content: '';
      left: 1px;
      transform: translateX(-100%);
      border-width: 8px 8px 8px 0;
      border-color: transparent $color-antarcticblue transparent transparent;

      @include media('>medium') {
        border-width: 12px 16px 12px 0;
      }
    }
  }

  .interview-block__answer & {
    background-color: $color-greyblue;

    &:after {
      content: '';
      right: 1px;
      transform: translateX(100%);
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent $color-greyblue;

      @include media('>medium') {
        border-width: 12px 0 12px 16px;
      }
    }
  }
}
