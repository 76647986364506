.language-switch {}

// Header variant
.language-switch--header {
  opacity: 0;
  transform: scale(0.8);
  transform-origin: top right;
  transition: transform 200ms, opacity 200ms;
  pointer-events: none;

  border: 1px solid $color-grey;
  border-radius: $border-radius;
  background-color: white;

  .language-switch-extended {
    display: none;
    visibility: hidden;
  }

  &.language-switch--visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}

// Mobile list variant
.language-switch--list {
  .language-switch-simple {
    display: none;
    visibility: hidden;
  }
}

// Used for showing all countries in header variant
.language-switch--show-extended {
  .language-switch-simple {
    display: none;
    visibility: hidden;
  }

  .language-switch-extended {
    display: block;
    visibility: visible;
  }
}
