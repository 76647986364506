.extra-qualifications {
  .page-content &.container {
    margin-top: 0;
  }
}

.extra-qualifications__item {
  max-height: 28px;
  transition: max-height .2s ease-in-out;
}

.extra-qualifications__label {
  @include typography_h4;
  color: $color-coolblack;
  margin-top: 0;
}

.extra-qualifications__list {
  margin-top: grid-size(3)
}

.extra-qualifications__title {
  color: $color-coolblue;
  padding: 0 0 0 grid-size(6);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 12px;
    height: 12px;
    background-image: static-url('images/chevron-down-blue.svg');
    background-repeat: no-repeat;
    background-size: 12px;
    transform-origin: center;
    transition: transform .2s linear .2s;
  }
}

.extra-qualifications__content {
  padding: grid-size(2) 0 grid-size(3);
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all .2s ease-in-out;
  max-height: 0;

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    padding: 0 0 grid-size(1) grid-size(1);
    &:before {
      display: none;
    }
  }
}

.extra-qualifications__item--open {
  max-height: 200vh;
  .extra-qualifications__title:before {
    transform: rotateX(180deg) translateY(5px);
    transition: transform .2s linear;
  }
  .extra-qualifications__content {
    opacity: 1;
    transform: scaleY(1);
    transition: all .2s ease-in-out;
    max-height: 200vh;
  }
}
