.location-popover {
  position: relative;
  height: 100%;
}

.location-popover__inner {
  padding: grid-size(3) grid-size(5);
  @include media('<=small') {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(76px + env(safe-area-inset-bottom));
    width: 100%;
  }

  .location-card-list {
    max-height: none;
  }
}

.location-popover__button-row {
  padding: grid-size(3) grid-size(5);
  button {
    width: 100%;
  }
  @include media('<=small') {
    position: absolute;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    width: 100%;
  }
}
