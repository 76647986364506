.slider {
  .page-content &:first-child {
    margin-top: grid-size(5);
    @include media('>medium') {
      margin-top: grid-size(7);
    }
  }
}

.slider--workarea {
  // This puts the slides in one grid. Sadly we could not use CSS Grid yet.
  @include media('>large') {
    .slider__container {
      flex-wrap: wrap;
      margin-right: -12px;
    }
    .slider__item {
      flex: 0 0 25%;

      &:last-child {
        flex: 0 0 25%;

        & > div {
          margin-right: 12px;
        }
      }
    }
  }

  @include media('>extra-large') {
    &:last-child {
      flex: 0 0 25%;
    }
  }
}

.slider__title,
.slider__title--desktop,
.slider__title--mobile {
  @include typography_h2;
  color: $color-coolblack;
  margin: 0;
}

.slider__title--desktop {
  display: none;

  @include media('>small') {
    display: block;
  }
}

.slider__title--mobile {
  display: block;

  @include media('>small') {
    display: none;
  }
}

.slider__text {
  margin: grid-size(1) 0 grid-size(2) 0;
}

.slider__inner {
  position: relative;
  max-width: initial;
  padding: 0 20px;

  -ms-overflow-style: none;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @include media('>small') {
    scroll-snap-type: x mandatory;
    scroll-padding-left: 64px;
    scroll-padding-right: 64px;
    padding: 0 64px;
  }

  @include media('>medium') {
    scroll-padding-left: 84px;
    scroll-padding-right: 84px;
    padding: 0 84px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include media('>large') {
    scroll-padding-left: map_get($container-padding, large);
    scroll-padding-right: map_get($container-padding, large);
    max-width: grid-size(250);
    padding: 0 map_get($container-padding, large);
  }
  @include media('>extra-large') {
    max-width: grid-size(312);
  }
}

.slider__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.slider__item {
  scroll-snap-align: start;
  flex: 0 0 70%;

  &:last-child {
    flex: 0 0 calc(70% + 7px);

    & > div {
      margin-right: 20px;
    }
  }

  @include media('>small') {
    flex: 0 0 calc(50% + 6px);
    &:last-child {
      flex: 0 0 calc(50% + 54px);

      & > div {
        margin-right: 64px;
        opacity: 1;
      }
    }
  }

  @include media('>medium') {
    margin-bottom: 13px;
    flex: 0 0 calc(33.3% + 4px);
    &:last-child {
      flex: 0 0 calc(33.3% + 74px);

      & > div {
        margin-right: 84px;
      }
    }
  }

  @include media('>large') {
    flex: 0 0 calc(25% + 3px);
    &:last-child {
      flex: 0 0 calc(25% + 22px);

      & > div {
        margin-right: map_get($container-padding, large);
      }
    }
  }

  @include media('>extra-large') {
    &:last-child {
      flex: 0 0 calc(25% + 22px);
    }
  }
}

.slider__item--hidden {
  @include media('>small') {
    opacity: 0.3;
  }
}

.slider__nav {
  overflow: visible;
  position: relative;
  padding: 0;

  @include media('<=small') {
    display: none;
  }
}

.slider__button {
  position: absolute;
  top: -200px;
  border: none;
  width: 70px;
  height: 200px;
  border-radius: $border-radius;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    transition: transform 0.1s linear;
  }
}

.slider__button--prev {
  left: -40px;
  background-position: center left;
  background-image: static-url('images/chevron-left.svg');

  &:hover {
    transform: translateX(-2px);
  }
}

.slider__button--next {
  right: -40px;
  background-position: center right;
  background-image: static-url('images/chevron-right.svg');

  &:hover {
    transform: translateX(2px);
  }
}

.slider--start {
  .slider__button--prev {
    opacity: 0;
    pointer-events: none;
  }
}

.slider--end {
  .slider__button--next {
    opacity: 0;
    pointer-events: none;
  }
}
