.vacancy-text {

}

.vacancy-text--no-checks {
  .vacancy-text__content {
    ul {
      list-style-type: disc;
      margin-left: 15px;
    }

    li {
      padding: 0;

      &:before {
        display: none;

      }
    }
  }
}

.vacancy-text__title {
  @include typography_h2;
  color: $color-coolblack;
  margin: 0;
}

.vacancy-text__content {
  p {
    margin-top: $margin-small;
  }

  a {
    color: $color-coolblue;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
    margin-top: $margin-small;
  }

  ul + ul {
    margin-top: 0;
  }

  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 7px;

    &:before {
      content: '';
      display: inline-block;
      background-image: static-url('images/check-green.svg');
      background-repeat: no-repeat;
      background-size: 11px;
      width: 11px;
      height: 11px;

      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}


// Experiment with foldouts.
// If permanently implemented change this to something like 'vacancy-text--foldout' instead of parent class.
.vacancy-page--foldouts {
  @include media("<=small") {
    .container--vacancy-text {
      margin-top: 0;
    }

    .vacancy-text {
      margin-bottom: 20px;
    }

    .vacancy-text--foldout {
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 0;

      .vacancy-text__title {
        cursor: pointer;

        position: relative;
        font-size: 13px;
        font-family: $font-opensans--bold;
        color: $color-coolblue;
        padding: 0 0 4px 0;

        &:after {
          content: '';
          position: absolute;
          right: 12px;
          bottom: 4px;
          width: 12px;
          height: 12px;
          background-image: static-url('images/chevron-down-blue.svg');
          background-repeat: no-repeat;
          background-size: 12px;
          transition: transform .2s linear;
          transform-origin: center;
        }
      }

      .vacancy-text__content {
        opacity: 0;
        max-height: 0;
        transform: scaleY(0);
        transform-origin: top;
        padding: 4px 0;

        p, ul {
          margin-top: 0;
        }
      }

      &.vacancy-text--open {
        .vacancy-text__title:after {
          transform: rotateX(180deg);
          transition: transform .2s linear;
        }

        .vacancy-text__content {
          padding-top: grid-size(3);
          padding-bottom: grid-size(3);
          opacity: 1;
          max-height: 200vh;
          transform: rotateX(0);
          transition: all .2s ease-in-out;
        }
      }
    }
  }
}
