.suggest {
  opacity: 0;
  max-height: 0;
  transform: rotateX(90deg);
  padding: 0 grid-size(5) grid-size(5);
  transition: all .2s ease-in-out;
  transform-origin: top;

  position: absolute;
  background-color: $color-white;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-top: 2px solid $color-whitesmoke;

  &--open {
    opacity: 1;
    max-height: 200vh;
    transform: rotateX(0);
  }
}

.suggest__result--desktop {
  display: flex;
  flex-direction: row;

  .suggest__title {
    @include typography_h4;
    margin-top: grid-size(6);
    margin-bottom: grid-size(2);
  }

  .suggest__cards {
    width: 100%;
  }

  .suggest__links {
    padding-left: grid-size(6);
    flex: 0 0 grid-size(66);
  }

  .suggest__item {
    margin-top: grid-size(2);
  }
  .suggest__link {
    font-size: 13px;
    line-height: 1.8;
  }
}

.suggest__title {
  @include typography_h5;
  color: $color-text;
  margin-top: grid-size(5);
}

.suggest__link {
  color: $color-text;
  font-size: 13px;
  font-family: $font-opensans;
  .highlight {
    font-family: $font-opensans--semibold;
  }
}

.suggest__show-all {
  font-family: $font-opensans--semibold;
  width: 100%;
  text-align: center;
  display: block;
  margin-top: grid-size(6);
}
