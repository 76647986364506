.vacancy-flyer {
  position: relative;
  flex: 0 0 calc((100% - #{grid-size(3)}) / 2);
  border: 1px solid $color-grey;
  box-shadow: inset 0px -2px 0px $color-grey;
  border-radius: $border-radius;
  overflow: hidden;
  scroll-snap-align: start;
  background-color: $color-white;

  @include media('>medium') {
    flex: 0 0 360px;
  }

  &--main {
    @include media('>medium') {
      margin-right: grid-size(6);
      margin-top: grid-size(6);
      margin-bottom: grid-size(6);
    }

    .vacancy-flyer__label {
      display: block;
    }

    .vacancy-flyer__apply {
      display: none;
    }
  }

  &--mobile-only {
    @include media('>medium') {
      display: none;
    }
  }
}

.vacancy-flyer__image {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
}

.vacancy-flyer__tip {
  position: absolute;
  top: grid-size(2);
  left: grid-size(2);
  display: flex;
  align-items: center;
  padding: grid-size(1) grid-size(2);
  border-radius: $border-radius;
  color: $color-white;
  background-color: $color-hotorange;
  font-family: $font-dobraslab-medium;


  svg {
    display: none;

    @include media('>medium') {
      display: block;
      width: 23px;
      height: auto;
      margin-right: grid-size(1)
    }
  }
}

.vacancy-flyer__content {
  padding: grid-size(4);

  @include media('>medium') {
    padding: grid-size(6);
  }
}

.vacancy-flyer__title {
  @include typography_h2;
  display: block;
  color: $color-coolblue;
  margin-top: 0;
  text-align: center;

  // set fixed height at 2 lines to assert aligning all cards.
  height: 40px;
  overflow: hidden;

  @include media('>small') {
    height: 45px;
  }

}

.vacancy-flyer__intro {
  display: none;

  @include media('>medium') {
    display: block;
    @include typography_p;
    font-size: 13px;
    text-align: center;
    margin-bottom: grid-size(8);

    // set fixed height at 4 lines to assert aligning all cards.
    height: 84px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

  }
}

.vacancy-flyer__link {
  display: block;
  text-align: center;
  margin-bottom: grid-size(8);

  svg {
    margin-left: grid-size(1);
    vertical-align: middle;
    width: 8px;
    height: auto;
  }

  @include media('>medium') {
    display: none;
  }
}

.vacancy-flyer__properties {
  margin-bottom: grid-size(8);
}

.vacancy-filter__property {
  text-align: center;
  margin-bottom: grid-size(6);

  &:last-child {
    margin-bottom: 0;
  }

  @include media('>medium') {
    display: grid;
    grid-template-columns: 1fr repeat(3, auto) 1fr;
    align-items: center;
    text-align: left;
  }

  svg {
    width: 32px;
    height: auto;
    grid-column-start: 2;
  }
}

.vacancy-flyer__label,
.vacancy-flyer__value {
  svg {
    width: 12px;
    height: auto;
    cursor: pointer;
  }
}

.vacancy-flyer__label {
  @include typography_p;
  display: block;

  svg {
    display: inline-block;
  }

  @include media('>medium') {
    display: none;

    svg {
      display: none;
    }
  }
}

.vacancy-flyer__value {
  @include typography_p;
  font-family: $font-opensans--bold;
  font-size: 15px;
  grid-column-start: 2;

  svg {
    display: none;
  }

  @include media('>medium') {
    svg {
      display: inline-block;
    }
  }
}

.vacancy-flyer__buttons {
  display: flex;
  gap: grid-size(2);
  flex-direction: column;
  align-items: flex-start;

  @include media('>medium') {
    flex-direction: row;
  }

  .button {
    flex: 1 1 50%;
    text-align: center;
    padding: grid-size(3);
  }
}
