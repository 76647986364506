.vacancy-header-media {
  position: relative;
}

.vacancy-header-media__background {
  background-color:  var(--vacancy-header-background);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
}

.vacancy-header--form {
  .vacancy-header-media {
    padding-top: grid-size(4);
  }

  .vacancy-header-media__background {
    background-color: transparent;
  }
}

.vacancy-header-media__image {
  border-radius: $border-radius;
}
