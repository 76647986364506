.apply-bar {
  .vertical-scrollbar.no-overflow & {
    padding-right: 15px;
  }
}

.apply-bar__inner {
  @include media('>medium') {
    display: flex;
    justify-content: space-between;
  }
}

.apply-bar__content {
  display: none;
  @include media('>medium') {
    display: block;
  }
}

.apply-bar__title {
  @include typography_h3;
  margin-top: 0;
}

.apply-bar__meta {
  display: flex;
  flex-direction: row;
}

.apply-bar__meta-item {
  background-position: center left;
  background-repeat: no-repeat;
  color: $color-text;
  padding-left: grid-size(6);
  padding-right: grid-size(7);
}
.apply-bar__meta-item--education {
  background-image: static-url('images/study.svg');
  background-size: 16px 13px;
}
.apply-bar__meta-item--hours {
  background-image: static-url('images/clock.svg');
  background-size: 14px;
}
.apply-bar__meta-item--location {
  padding-left: grid-size(5);
  background-image: static-url('images/pin.svg');
  background-size: 12px 15px;
}

.apply-bar__button {
  width: 100%;
  text-align: center;
  @include media('>small') {
    width: auto;
  }
}

.apply-bar__buttons {
  text-align: center;
  @include media('>medium') {
    text-align: left;
  }
}
