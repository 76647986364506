.quote {
  display: flex;
  flex-direction: row;
  background-color: $color-greyblue;
  border-radius: $border-radius;
  height: 100%;
  text-decoration: none;

  .container--wide & {
    @include media('>medium') {
      margin-right: 62px;
    }
  }
}

.quote__content {
  flex: 0 0 70%;
  padding: grid-size(4);
  display: flex;
  flex-direction: column;
  align-content: space-between;

  @include media('>medium') {
    flex: 0 0 60%;
    padding: grid-size(6) grid-size(4);
  }

  .container--wide & {
    flex: 0 0 75%;
    order: 2;

    @include media('>medium') {
      flex: 0 0 65%;
      padding: 24px 32px;
    }
  }
}

.quote__image-wrapper {
  flex: 0 0 30%;
  position: relative;

  @include media('>medium') {
    flex: 0 0 40%;
  }

  .container--wide & {
    flex: 0 0 25%;
    order: 1;

    @include media('>medium') {
      margin-left: $margin-tiny;
      flex: 0 0 20%;
    }
  }
}

.quote__quote {
  font-family: $font-dobraslab-book;
  font-size: 16px;
  line-height: 20px;
  color: $color-darkgrey;
  margin-bottom: grid-size(3);
  flex: 1 1;

  @include media('>medium') {
    font-size: 20px;
    line-height: 25px;
  }
}

.qoute__title {
  font-family: $font-opensans--bold;
  line-height: 1.25;
  margin-top: 20px;
  font-size: 13px;
  margin-bottom: 0;

  @include media('>small') {
    font-size: 16px;
  }
}

.quote__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  @include media('>medium') {
    top: -16px;
    bottom: 0;
    height: auto;
  }
}
