.active-filters {
}

.active-filters--hidden {
  display: none;
  visibility: hidden;
}

.active-filters__header {
  display: flex;
  margin-bottom: 12px;
}

.active-filters__title {
  @include typography_h5;
  margin-top: grid-size(1);

  flex: 1;
}

.active-filters__clear {
  @include typography_h5;
  margin-top: grid-size(1);

  font-family: $font-opensans;
  color: $color-coolblue;

  &:hover {
    cursor: pointer;
  }
}

.active-filters__tags {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
}

.active-filters__tag {
  margin-right: 10px;
  margin-bottom: 5px;

  @include media('>medium') {
    max-width: 230px;
  }
}

.active-filters__tag--hidden {
  display: none;
  visibility: hidden;
}
