.about-usp-block {
  flex: 0 0 250px;
  margin-right: grid-size(3);
  scroll-snap-align: center;

  @include media('>medium') {
    flex: 1 1 50%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.about-usp-block__title {
  @include typography_h3;
  color: $color-white;
  margin-bottom: grid-size(3);
}

.about-usp-block__inner {
  padding: grid-size(6) grid-size(4);
  background-color: $color-white;
  color: $color-coolblue;
}

.about-usp-block__item {
  display: flex;
  align-items: center;

  svg {
    width: 46px;
    height: auto;
    margin-right: grid-size(3);
  }
}

.about-usp-block__fact {
  @include typography_fact;
  font-size: 14px;
  line-height: 12px;

  @include media('>small') {
    font-size: 14px;
    line-height: 12px;
  }

  @include media('>medium') {
    font-size: 16px;
    line-height: 12px;
  }
}

.about-usp-block__slogan {
  @include typography_slogan;
  font-size: 12px;
  line-height: 12px;

  @include media('>small') {
    font-size: 12px;
    line-height: 12px;
  }

  @include media('>medium') {
    font-size: 12px;
    line-height: 12px;
  }
}
