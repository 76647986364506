.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  input,
  textarea {
    appearance: none;

    &:focus {
      outline: none;
    }
  }
}

::placeholder {
  color: $color-silver;
}

.form__row {
  width: 100%;
  margin-bottom: grid-size(5);
}

.form__row--hidden,
.form__row--recaptcha {
  display: none;
}

/*
  Render checkboxes left, their labels to their right,
  and other elements (like errors) on the next row.
*/
.form__row--checkbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

/* Display checkbox input elements before (to the left of) the label */
.form__checkbox {
  order: -1;
}

/* Push elements other than the checkbox and label to the next row */
.form__label--checkbox {
  width: 90%;
}

.form__label {
  color: $color-raingrey;
  margin-bottom: grid-size(2);

  label {
    @include typography_h4;
    color: $color-text;
    margin-right: grid-size(3);
  }
}

.form__label--screening-question {
  label {
    @include typography_p;
  }
}

.form__help {
  color: $color-hurricanegrey;
  margin-bottom: grid-size(2);
}

.form__field {
  @include typography_p;
  padding: 9px 12px;
  border: 1px solid $color-grey;
  border-radius: $border-radius;
  width: 100%;

  @include input-hover;
}

textarea.form__field:not([disabled]):hover {
  cursor: text;
}

select.form__field {
  appearance: none;
  background-color: #fff;
  background-image: static-url('images/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 17px auto;
  font-family: $font-opensans--bold;

  &:focus {
    outline: none;
  }
}

select.form__field--is-valid {
  border-bottom-color: $color-freshgreen;
  background-image: static-url('images/check-green.svg');
  background-repeat: no-repeat;
  background-position: bottom 12px right 12px;
  background-size: 14px;
}

.form__field--is-invalid {
  border-color: $color-scarletred;
  background-color: $color-errorbg;
}

.form__field--is-readonly {
  pointer-events: none;
}

.form__options .form__field--is-invalid + .form__label {
  border-color: $color-scarletred;
  background-color: $color-errorbg;
}

.form__options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form__option {
  input {
    display: none;
  }

  .form__label {
    display: inline-block;
    color: $color-text;
    padding: 12px 12px;
    margin: 0 grid-size(1) grid-size(1) 0;
    border: 1px solid $color-grey;
    border-radius: $border-radius;
    min-width: 70px;
    text-align: center;
    font-family: $font-opensans--semibold;
    @include input-hover;
  }

  input:checked + .form__label {
    border-color: $color-coolblue;
    background-color: $color-arcticblue;
    font-family: $font-opensans--bold;
  }

  input[disabled] + .form__label {
    color: $color-silver;
    border-color: $color-grey;
    font-family: $font-opensans;

    &:hover {
      cursor: not-allowed;
      border-color: $color-grey;
    }
  }
}

.form__option--hidden {
  display: none;
  visibility: hidden;
}

.form__options--checkbox-list,
.form__options--radio-list {
  .form__option {
    width: 100%;
  }

  .form__label {
    width: 100%;
    padding-left: grid-size(10);
    position: relative;
    text-align: left;

    &:before {
      content: '';
      border: 1px solid $color-grey;
      border-radius: $border-radius;
      background-color: $color-white;
      width: grid-size(4);
      height: grid-size(4);
      position: absolute;
      left: grid-size(3);
      top: calc(50% - #{grid-size(2)})
    }
  }

  input:checked + .form__label {
    &:before {
      border-color: $color-coolblue;
      background-color: $color-coolblue;
      background-image: static-url('images/check.svg');
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.form__options--radio-list {
  .form__label {
    &:before {
      border-radius: 100%;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  input:checked + .form__label {
    &:before {
      background: white none;
      border: 5px solid $color-coolblue;
    }
  }
}

.form__error {
  margin-top: grid-size(1);
  font-size: 13px;
  line-height: 16px;
  color: $color-scarletred;

  &--hidden {
    display: none;
  }
}

.form__line {
  border: none;
  border-bottom: 1px solid $color-raingrey;
  opacity: .2;
  width: 100%;
  margin-bottom: grid-size(8);
}

.form__errors {
  width: 100%;
  border: 1px solid $color-scarletred;
  border-radius: $border-radius;
  background-color: $color-errorbg;
  padding: grid-size(5) grid-size(4);
  margin-bottom: grid-size(6);
  position: relative;

  li {
    color: $color-scarletred;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.form__validation-errors {
  width: 100%;
}

.form__errors-title {
  @include typography_h3;
  margin-top: 0;
}

// Modifiers

.form--popup {
  .form__line {
    display: none;
    border-bottom: none;
  }

  .form__option {
    flex: 1 1;

    &:not(:last-child) {
      margin-right: grid-size(3);
    }

    .form__label {
      width: 100%;
      text-align: center;
    }
  }

  .button--wide {
    margin-top: 10px;
    width: 100%;
  }
}

// Make sure the options stay centered in the case of hours
// We can't rely on :last-child because we show/hide elements
.form--popup .form__row--hours {
  margin-top: -16px;
  .form__options {
    margin: 0 -6px;
  }

  .form__option {
    margin: 0 6px;
  }
}

.form--popup .form__notification {
  color: $color-darkgrey;
  font-size: 13px;
  display: none;

  &.form__notification--visible {
    display: block;
  }
}
