.search {
  --search-hover: var(--theme-hover);
}

.search--expanded {
  width: 100%;
}

.search__expand-button {
  display: flex;
  align-items: center;

  padding: 10px;
  padding-right: 0;
  border-radius: $border-radius;

  @include media($nav-header-flip) {
    padding: 10px;
    &:focus,
    &:hover {
      cursor: pointer;
      background-color: var(--search-hover);
    }
  }

  .search--expanded & {
    display: none;
    visibility: hidden;
  }

  svg {
    width: auto;
    height: 21px;
  }
}

$input-height: 41px;
$input-height-mobile: 38px;

.search__input {
  position: relative;
  display: none;
  visibility: hidden;
  align-items: center;
  height: $input-height-mobile;

  @include media($nav-header-flip) {
    height: $input-height;
    width: 300px;
  }

  .search--expanded & {
    display: flex;
    visibility: visible;
  }
}

.search__input-text {
  @include typography_p;
  height: $input-height-mobile;
  width: 100%;
  outline: none;

  padding: 9px 12px;
  border: none;
  border-radius: $border-radius;

  @include media($nav-header-flip) {
    height: $input-height;
  }
}

.search__input-button {
  position: absolute;
  top: 0;
  right: 32px;
  border: none;
  border-radius: $border-radius;
  background-color: transparent;
  height: $input-height-mobile;

  padding-top: 9px;
  padding-bottom: 9px;

  svg {
    width: auto;
    height: 21px;
  }

  @include media($nav-header-flip) {
    right: 3px;
    height: $input-height;
  }

  &:hover {
    cursor: pointer;
  }

  svg line, svg circle {
    stroke: $color-coolblue;
  }
}

.search__input-reset {
  position: absolute;
  top: 3px;
  right: 55px;
  padding: 9px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms linear;

  @include media($nav-header-flip) {
    top: 1px;
    right: 36px;
  }

  svg {
    height: 12px;
    width: 12px;

    path {
      fill: $color-silver;
    }
  }
}

.search__input-close {
  display: none;
  visibility: hidden;

  @include media($nav-header-flip-reverse) {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 10px;
    visibility: visible;

    svg path {
      fill: white;
    }
  }
}

.search__suggest.suggest--open + .search__input-reset {
  opacity: 1;
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}

.search__suggest {
  left: 0;
  right: 29px;
  top: grid-size(9);

  @include media($nav-header-flip) {
    top: 39px;
    right: 0;
  }
}
