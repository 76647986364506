.social-icons {
  display: flex;
  flex-direction: row;
}

.social-icons__item {
  margin-right: 20px;

  transition: opacity 200ms;

  &:hover {
    opacity: 0.8;
  }

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    fill: $color-white;
    width: 40px;
    height: 40px;
  }
}
