.results {
  position: relative;
}

.results--list {
  .results__items,
  .results__button-row {
    display: block;
  }
}

.results--map {
  .results__map {
    display: block;
  }
}

.results__title {
  @include typography_h3;
  margin-top: 0;

  @include media('>medium') {
    margin-top: grid-size(3)
  }
}

.results__toolbar {
  display: flex;
  margin-top: 20px;

  .results__tool-button--map {
    margin-left: 30px;
    flex: 1;
  }

  @include media('>medium') {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;

    .results__tool-button--filter {
      display: none;
    }
  }
}

.results__tool-button {
  @include typography_h3;
  margin: 0;

  color: $color-coolblue;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 18px;

  padding-left: grid-size(6);

  cursor: pointer;
}

.results__tool-button--inactive {
  color: $color-raingrey;
}

.results__tool-button--list {
  background-image: static-url('images/list.svg');
  background-size: 21px;
  padding-left: 27px;

  &.results__tool-button--inactive {
    background-image: static-url('images/list-inactive.svg');
  }
}

.results__tool-button--map {
  background-image: static-url('images/map.svg');

  &.results__tool-button--inactive {
    background-image: static-url('images/map-inactive.svg');
  }
}

.results__tool-button--filter {
  background-image: static-url('images/filter.svg');
}

.results__floating-bar {
  text-align: center;
}

.results__filter-button {
  @include typography_h3;
  margin: 0;

  position: static;
  margin-top: 16px;

  color: $color-white;
  background-image: static-url('images/filter-white.svg');
  background-position: left 60px center;
  background-repeat: no-repeat;
  background-size: 18px;

  padding-left: 88px;
  padding-right: 70px;

  @include media('>medium') {
    display: none;
  }
}

.results__floating-bar--floating {
  position: fixed;
  padding-bottom: grid-size(2);
  left: 0;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  z-index: 4;
  animation: .2s ease-in slide-up;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.results__map,
.results__items {
  display: none;
  margin-top: grid-size(5);
}

.results__map {
  min-height: 200px;
}

.results__item {
  box-sizing: border-box;
  margin-bottom: grid-size(2);
  max-height: 500px;

  &:last-child {
    margin-bottom: 0;
  }
}

.results__item,
.results__cross-link--anim {
  transition: opacity .35s ease-in-out, transform .25s linear;
}

.results__button-row {
  display: none;
}

.results__loadmore {
  margin-top: grid-size(4);
}

.results__cross-link {
  margin-top: 10px;
  background-color: $color-greyblue;
  border-radius: $border-radius;
  padding: grid-size(3) grid-size(3) grid-size(2);

  @include media('>small') {
    padding: grid-size(3) grid-size(6);
  }

  hr {
    border: none;
    border-top: 1px solid $color-brightgrey;
    margin: 12px 0;
  }
}

.results__banner--hidden,
.results__cross-link--hidden,
.results__item--hidden {
  pointer-events: none;
  padding: 0;
  border: none;
  margin-bottom: 0;
  max-height: 0;
  opacity: 0;
  transform: translateY(50%);
}

.results__nothing-found {
  display: none;
  background-color: $color-greyblue;
  padding: grid-size(3) grid-size(3) grid-size(2);
  border-radius: $border-radius;

  margin-top: 20px;

  h2 {
    @include typography_h2;
    margin-top: 0;
  }

  .form__line {
    margin: grid-size(5) 0;
  }

  @include media('>small') {
    padding: grid-size(3) grid-size(6);
  }
}

.results__banner {
  list-style: none;
  margin-top: grid-size(4);
}

.results__nothing-found-banner {
  display: none;
  list-style: none;
  margin-top: grid-size(4);
}
