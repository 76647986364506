.popover {
  pointer-events: none;

  .video__play-button--enabled {
    pointer-events: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
  overflow: hidden;
}

.popover__backdrop {
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 300ms;
}

.popover__outer {
  height: 100%;
  width: 85%;
  pointer-events: none;

  transform: translateX(-100%);
  transition: transform 300ms;

  @include media('>medium') {
    min-height: 100%;
    height: auto;
    opacity: 0;
    width: 100%;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    padding: grid-size(10) 0;
  }
}

.popover__inner {
  background-color: $color-white;
  height: 100%;
  display: flex;
  flex-direction: column;
  @include media('>medium') {
    display: block;
    height: auto;
    width: grid-size(161);
    margin: auto;
    border-radius: $border-radius;
  }
}

.popover__image {
  height: 188px;
  background-size: cover;
  order: 2;
  @include media('>medium') {
    border-top-left-radius: $border_radius;
    border-top-right-radius: $border_radius;
  }
}

.popover__header {
  order: 1;
  background-color: $color-coolblue;
  padding: 0 grid-size(5);
  position: relative;

  @include media('>medium') {
    background-color: $color-white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}

.popover__title {
  @include typography_h2;
  margin: 0;
  padding: grid-size(4) 0;
  color: $color-white;

  @include media('>medium') {
    padding-right: grid-size(4);
    color: $color-text;
  }
}

.popover-single-location__intro {
  margin-bottom: 27px;
}

.popover-single-location__location {
  @include typography_h2;
  margin: 0;
  padding: grid-size(2) 0;
  color: $color-text;

  @include media('>medium') {
    padding-right: grid-size(4);
  }
}

.popover-single-location__address,
.popover-single-location__link {
  line-height: 20px;
  font-size: 13px;
  margin-top: 0;
}

.popover__button--close {
  background-image: static-url('images/close-white.svg');
  position: absolute;
  top: 19px;
  right: grid-size(5);
  text-indent: 100%;
  overflow: hidden;
  width: 14px;
  height: 14px;
  cursor: pointer;

  @include media('>medium') {
    background-image: static-url('images/close.svg');
  }
}

.popover__usps {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $color-antarcticblue;
  margin-bottom: grid-size(4);
  padding-bottom: grid-size(3);
}

.popover__usp {
  font-size: 12px;
  background-image: static-url('images/check-blue.svg');
  background-repeat: no-repeat;
  background-position: left top 3px;
  background-size: 12px;
  padding-left: grid-size(5);
  &:not(:last-child) {
    margin-right: grid-size(3);
  }
}

.popover__content {
  order: 3;
  padding: grid-size(6) grid-size(5);
  height: 100%;

  p + ul {
    margin-top: grid-size(4);
  }

  .rich-text + .video {
    margin-top: grid-size(4);
  }

  @include media('>medium') {
    padding-top: 0;
  }
}

.popover__buttons {
  margin-top: grid-size(6);
  display: grid;
  column-gap: grid-size(3);
  row-gap: grid-size(3);
  @include media('>small') {
    grid-template-columns: auto auto;
  }
}

.popover__button {
  flex: 1 1;
  text-align: center;
}

.popover--open {
  pointer-events: auto;
  overflow-y: auto;

  .popover__backdrop {
    opacity: 1;
  }

  .popover__outer {
    transform: translateX(0);

    @include media('>medium') {
      opacity: 1;
    }
  }

  .popover__inner {
    pointer-events: auto;
  }
}

.popover--small {
  .popover__inner {
    @include media('>medium') {
      width: grid-size(104);
    }
  }
}

.popover--no-padding {
  .popover__content {
    padding: 0;
  }
}

.popover--from-bottom {
  @include media('<=medium') {
    .popover__outer {
      transform: translate(0, 100%);
      width: 100%;
      height: auto;
      max-width: none;
      position: absolute;
      bottom: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      overflow: hidden;
    }

    .popover__header {
      background-color: $color-white;
    }

    .popover__button--close {
      background-image: static-url('images/close.svg');
    }

    .popover__title {
      color: #000;
    }

    .popover__content {
      padding-top: 0px;
    }

    &.popover--open {
      .popover__outer {
        transform: translate(0, 0);
      }
    }

    .popover__inner {
      max-height: calc(100vh - 60px);
      overflow-y: auto;
    }
  }
}

.popover--mobile-centered {
  .popover__outer {
    min-height: 100%;
    height: auto;
    opacity: 0;
    width: 100%;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    padding: grid-size(10) 0;
  }

  .popover__inner {
    display: block;
    height: auto;
    margin: auto;
    border-radius: $border-radius;
  }

  .popover__image {
    border-top-left-radius: $border_radius;
    border-top-right-radius: $border_radius;
  }

  .popover__header {
    background-color: $color-white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .popover__title {
    color: $color-text;
    padding-right: grid-size(4);
  }

  .popover__button--close {
    background-image: static-url('images/close.svg');
  }

  .popover__content {
    padding-top: 0;
  }

  &.popover--open {
    .popover__outer {
      opacity: 1;
    }
  }

  .popover__inner {
    width: 90vw;
  }

  @include media('>small') {
    .popover__inner {
      width: 80vw;
    }
  }
  @include media('>medium') {
    .popover__inner {
      width: grid-size(104);
    }
  }
}

.popover--fixed-from-top {
  @include media('>medium') {
    top: 190px;
    bottom: inherit;
  }
}

.popover--transparent {
  .popover__inner {
    background-color: transparent;
  }
}

.popover--full-width-mobile {
  .popover__inner {
    width: 100vw;

    @include media('>small') {
      width: 80vw;
    }

    @include media('>medium') {
      width: 416px;
    }
  }
}

.popover--blue {
  .popover__inner,
  .popover__header {
    background-color: $color-coolblue;
    color: $color-white;
  }

  .popover__title {
    color: $color-white;
  }

  .popover__button--close {
    background-image: static-url('images/close-white.svg');
  }
}
