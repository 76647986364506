@use "sass:math";

.dialog {
  position: relative;
  width: 100%;
  padding: grid-size(8);
  background-color: $color-white;
  border-radius: $border-radius;
  top: 50vh;
  transform: translateY(-50%);

  @include media('>medium') {
    width: math.percentage(math.div(8, 12));
    margin-left: math.percentage(math.div(2, 12));
    margin-right: math.percentage(math.div(2, 12));
  }

  @include media('>large') {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}

.dialog__fact {
  @include typography_fact;
}

.dialog__slogan {
  @include typography_slogan;
}

.dialog__content {
  @include typography_p;
  margin-top: grid-size(4);
}

.dialog__button {
  margin-top: grid-size(10);
  margin-bottom: grid-size(2);
}

.dialog__footer {
  color: $color-raingrey;
}
