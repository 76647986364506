@keyframes body {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(2px);
  }
}

@keyframes drive {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(100vw)
  }
}

@keyframes wheel {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}

.bus {
  display: none;
  visibility: hidden;
  position: absolute;
  left: 0;
  bottom: -5px;
  z-index: 10;


  svg {
    height: 60px;
  }

  #body {
    animation: body 0.8s linear infinite;
  }

  .wheel {
    //animation: wheel 0.8s linear infinite;
  }
}

.bus--drive {
  display: block;
  visibility: visible;

  animation: drive 5s linear;
}
