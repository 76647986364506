.text-image-block__inner {
  @include media('>large') {
    display: flex;
    flex-direction: row;
    max-width: 1038px;
    padding-left: 0;
    padding-right: 0;
  }
}

.text-image-block__image-wrapper {
  margin: 0 #{map-get($container-padding, small) * -1};
  @include media('>medium') {
    margin: 0 #{map-get($container-padding, medium) * -1};
  }
  @include media('>large') {
    flex: 0 0 grid-size(128);
    margin-left: 0;
    margin-right: grid-size(10);
  }
}

.text-image-block__content-wrapper {
  @include media('>large') {
    flex: 0 0 grid-size(98);
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-image-block__cta {
  margin-top: grid-size(3);
  @include media('<=small') {
    .button {
      width: 100%;
      text-align: center;
    }
  }
}

.text-image-block--right {
  .text-image-block__inner {
    justify-content: flex-end;
  }
  .text-image-block__image-wrapper {
    order: 2;
    @include media('>large') {
      margin-right: 0;
    }
  }
  .text-image-block__content-wrapper {
    order: 1;
    @include media('>large') {
      margin-right: grid-size(10);
    }
  }
}

.text-image-block__image {
  border-radius: $border-radius;
}
