.event__slider {
  flex: 0 0 calc(50% + 6px);
}

.related-event-card {
  width: 306px;
  background-color: $color-white;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  @include media('>medium') {
    width: 461px;
  }
}

.related-event-card--button {
  @include button-border;
}

.related-event-card__image-wrapper {
}

.related-event-card__link {
  display: block;
  padding: 0px;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.related-event-card__image {
  height: 204px;
  box-sizing: border-box;
  background-size: cover;

  @include media('>medium') {
    height: 203px;
  }
}

.related-event-card__body {
  padding: 15px 12px 15px 17px;
}

.related-event-card__header {
}

.related-event-card__title {
  @include typography_h2;
  margin-top: 0;
  margin-right: grid-size(2);

  @include media('>small') {
    font-size: 16px;
  }
}

.related-event-card__usps {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-top: grid-size(3);
}

.related-event-card__usp {
  flex: 0 0 initial;
  color: $color-raingrey;
  font-size: 12px;
  position: relative;
  padding-left: grid-size(6);
  padding-right: grid-size(4);
  margin-bottom: grid-size(1);
  white-space: nowrap;

  @include media('>small') {
    padding-right: grid-size(7);
  }

  &:before {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: grid-size(4);
    height: grid-size(4);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:last-child {
    flex: 1 0;
  }
}

.related-event-card__usp--location {
  padding-left: 21px;

  &:before {
    width: 13px;
    background-image: static-url('images/pin.svg');
  }
}

.related-event-card__usp--education {
  padding-left: grid-size(7);

  &:before {
    width: grid-size(6);
    left: -4px;
    background-image: static-url('images/study.svg');
  }
}

.related-event-card__usp--timeslots {
  padding-left: grid-size(7);

  &:before {
    width: grid-size(6);
    left: -4px;
    background-image: static-url('images/calendar_blue.svg');
  }
}

.related-event-card__date-time {
  color: $color-coolblack;
  margin-bottom: 21px;
  b {
    font-family: $font-opensans--bold;
  }
}
