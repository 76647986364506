$flip-layout: ">medium";

.tour-block {
  @include media($flip-layout) {
    .video {
      width: 50%;
      padding-bottom: 28.11%;
    }
  }
}

.tour-block__inner {
  display: flex;
  flex-direction: column-reverse;

  @include media($flip-layout) {
    flex-direction: row;
    min-height: 375px;

    .tour-block--video & {
      min-height: auto;
    }
  }
}

.tour-block__link,
.tour-block__content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  flex: 1;
}

.tour-block__link {
  border-radius: $border-radius;
  align-items: center;
  position: relative;
  overflow: hidden;

  min-height: 245px;

  &:hover {
    text-decoration: none;

    .tour-block__image {
      transform: scale(1.1);
    }

    .tour-block__button {
      @include button-hover($primary: false);
    }
  }

  @include media($flip-layout) {
    min-height: inherit;
    padding: grid-size(3);
  }
}

.tour-block__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: $image-transition;
}

.tour-block__content {
  @include media($flip-layout) {
    padding: grid-size(8);
    padding-right: 0;
  }
}

.tour-block__title {
  @include typography_h2;
  margin-top: 0;
}

.tour-block__fact {
  @include typography_fact;
}

.tour-block__slogan {
  @include typography_slogan;
}

.tour-block__text {
  margin-top: 16px;
  margin-bottom: 24px;

  @include media($flip-layout) {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.tour-block__button {
  background-color: $color-white;
  z-index: 1;
}
