.related {
  .slider__inner {
    @include media('>extra-large') {
      max-width: grid-size(250);
    }
  }

  .slider__container {
    .slider__item {
      .related__item {
        margin-right: 10px;
      }

      &:last-child {
        .related__item {
          margin-right: 0;
        }
      }
    }
  }

  .slider__button {
    height: 129px;
    top: -145px;
  }

}

.related .slider__item {
  flex: 0 0 95%;

  &:last-child {
    flex: 0 0 calc(95% + 10px);

    & > div {
      margin-right: 20px;
    }
  }

  @include media('>small') {
    flex: 0 0 calc(100% + 10px);
    &:last-child {
      flex: 0 0 calc(100% + 64px);

      & > div {
        margin-right: 64px;
        opacity: 1;
      }
    }
  }

  @include media('>medium') {
    flex: 0 0 calc(50% + 5px);
    &:last-child {
      flex: 0 0 calc(50% + 80px);

      & > div {
        margin-right: 84px;
      }
    }
  }

  @include media('>large') {
    flex: 0 0 calc(50% + 5px);
    &:last-child {
      flex: 0 0 calc(50% + 75px);

      & > div {
        margin-right: 84px;
      }
    }
  }
}

.related__item {
  margin-right: 10px;
}
