.label {
  align-items: center;
  background-color: $color-hotorange;
  border-radius: $border-radius;
  color: $color-white;
  display: flex;
  float: left;
  font-family: $font-dobraslab-medium;
  font-size: 13px;
  left: 12px;
  line-height: 1.15;
  padding: 5px 16px 5px 8px;
  pointer-events: none;
  position: relative;
  top: 12px;
  transform: translateY(-4px);
  z-index: 1;

  svg {
    height: 14px;
    margin-right: 8px;
    width: auto;
    path {
      fill: $color-white;
    }
  }
}
