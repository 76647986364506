.apply {
  background-color: white;
}

.apply__inner {
  position: relative;
  z-index: 3;
}

.apply__title {
  @include typography_h2;
  color: $color-coolblue;
  @include media('<medium') {
    svg {
      height: grid-size(4);
      width: grid-size(4);
    }
  }
  display: flex;
  justify-content: space-between;
}

.apply__title-link {
  transform: translateY(3px);
  svg {}
  &:hover {
    path {
      stroke: $color-dayblue;
    }
  }
}

.apply__usps {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-bottom: grid-size(2);
}

.apply__usp {
  flex: 0 0 initial;
  color: $color-raingrey;
  font-size: 12px;
  position: relative;
  padding-left: grid-size(6);
  padding-right: grid-size(4);
  margin-bottom: grid-size(1);
  white-space: nowrap;

  @include media('>small') {
    padding-right: grid-size(7);
  }

  &:before {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: grid-size(4);
    height: grid-size(4);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:last-child {
    flex: 1 0;
  }
}

.apply__usp--location {
  &:before {
    background-image: static-url('images/pin.svg');
  }
}

.apply__usp--contract-hours {
  &:before {
    background-image: static-url('images/clock.svg');
  }
}

.apply__usp--education {
  padding-left: grid-size(7);
  &:before {
    width: grid-size(6);
    left: -4px;
    background-image: static-url('images/study.svg');
  }
}

 .apply__usp--salary {
   &:before {
     background-image: static-url('images/euro-alt.svg');
   }
 }

.apply__line {
  border: none;
  border-bottom: 1px solid $color-raingrey;
  opacity: .2;
  margin: grid-size(5) 0;
}

.apply__form {
  margin-bottom: grid-size(17);

  // First name and last name on same row
  .form__row--first_name {
    @include media('>small') {
      width: calc(50% - #{grid-size(2)});
      margin-right: grid-size(4);
    }
  }
  .form__row--last_name {
    @include media('>small') {
      width: calc(50% - #{grid-size(2)});
    }
  }

  .form__row--consent {
    .form__label {
      display: none;
    }
  }

  .form__row--contract_option,
  .form__row--hours_option {
    .form__option {
      flex: 1 0 0;
      margin-right: grid-size(3);

      &:last-of-type {
        margin-right: 0;
      }
    }

    .form__label {
      width: 100%;
    }
  }
}

.apply__progress--header {
  position: absolute;
  top: 50px;
  width: 100%;
  @include media('>large') {
    top: 70px;
  }

  .progress-bar {
    border: none;
  }

  .progress-bar__icon {
    svg path {
      fill: white;
    }
  }
}

.apply--floating {
  .apply__title {
    margin-top: 0;
    color: $color-text;
  }
}

.button {
  //border: 10px solid red !important;
}
