.procedure {
  .slider__inner {
    @include media('>extra-large') {
      max-width: grid-size(250);
    }
  }
  .slider__button {
    top: -240px;
  }

  &--desktop {
    display: none;
  }
  @include media ('>small') {
    &--mobile {
      display: none;
    }
    &--desktop {
      display: block;
    }
  }
}

.procedure__step {
  height: grid-size(70);
  border-radius: $border-radius;
  position: relative;
  overflow: hidden;
  margin-right: grid-size(3);
}

.procedure__step--min-height {
  .procedure__text {
    min-height: 95px;
  }
}

.procedure__index {
  position: absolute;
  top: grid-size(2);
  left: grid-size(2);
  width: grid-size(10);
  height: grid-size(10);
  border-radius: 50%;
  background-color: $color-white;
  z-index: 2;
}

.procedure__index-text {
  display: inline-block;
  width: 100%;
  color: $color-dayblue;
  font-family: $font-avenir;
  font-size: 24px;
  line-height: grid-size(10);
  text-align: center;

  @include media('>small') {
    font-size: 24px;
    line-height: grid-size(10);
  }
}

.procedure__item {
  height: 100%;
}

.procedure__item--clickable {
  cursor: pointer;
  &:hover {
    .procedure__image {
      transform: scale(1.1);
    }
  }
}

.procedure__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: $image-transition;
  background-size: cover;
  z-index: 1;
}

.procedure__video-play {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  width: grid-size(15);
  height: grid-size(15);

  background-image: static-url('images/play.svg');
  background-size: grid-size(15);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}

.procedure__text {
  background-color: $color-coolblue;
  padding: grid-size(4) grid-size(4) grid-size(2);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.procedure__fact {
  @include typography_fact;
  color: $color-white;
  font-size: 18px;
  line-height: 18px;
  @include media('>small') {
    font-size: 18px;
    line-height: 18px;
  }
}

.procedure__slogan {
  @include typography_slogan;
  color: $color-antarcticblue;
  font-size: 15px;
  line-height: 18px;
  @include media('>small') {
    font-size: 15px;
    line-height: 18px;
  }
}

.procedure__cta {
  @include typography_p();
  height: 25px;
  margin-top: 10px;
  color: $color-white;
}

.procedure--mobile {
  .procedure__step {
    padding: grid-size(3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    margin-right: 0;
    margin-bottom: grid-size(2);
    border-bottom: none;
    background-color: $color-coolblue;
  }

  .procedure__item {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .procedure__index,
  .procedure__header {
    position: static;
    margin-right: grid-size(3);
  }

  .procedure__index {
    flex: 0 0 40px;
  }

  .panel__title {
    padding: 0;
    &:after {
      bottom: auto;
      background-image: static-url('images/chevron-down-white.svg');
    }
  }

  .procedure__text {
    position: static;
    padding: 0 grid-size(3);
    color: $color-white;
    background-color: transparent;

    .rich-text,
    .video {
      margin: grid-size(3) 0;
    }
  }
}

.procedure__item--mobile .procedure__header {
  flex: 1 0 auto;
}
