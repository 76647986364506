.about-popover {
  .popover__content {
    overflow-y: auto;
  }

  .video {
    width: auto;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: grid-size(7);
    border-radius: 0;

    @include media('>medium') {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      border-radius: $border-radius;
    }
  }
}

.about-popover__fact {
  @include typography_fact;
  color: $color-white;
}

.about-popover__slogan {
  @include typography_slogan;
  color: $color-white;
  margin-bottom: grid-size(4);
}

.about-popover__intro {
  @include typography_p;
  margin-bottom: grid-size(6);
}

.about-popover__usps {
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  margin-bottom: grid-size(6);
}

.about-popover__footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media('>medium') {
    flex-direction: row;
    align-items: flex-start;
  }
}

.about-popover__richtext {
  margin-bottom: grid-size(8);

  @include media('>medium') {
    margin-bottom: 0;
    margin-right: grid-size(8);
  }
}

.about-popover__kununu-link {
  display: inline-block;
}

.about-popover__kununu-image {
  width: 92px;
  height: auto;
}
