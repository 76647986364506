.calendar {
  @include media('>small') {
    background-color: $color-greyblue;
  }
}
.page-content .calendar .calendar__inner {
  padding-bottom: 0;

  @include media('>small') {
    background-color: $color-greyblue;
  }
}

.calendar__title {
  @include typography_h2;
  color: $color-coolblack;
  margin-bottom: grid-size(7);

}

.calendar__image-wrapper {
  position: relative;
  @include media('>small') {
    padding-top: grid-size(10);
    border-top: 1px solid $color-antarcticblue;
  }
}

.calendar__overlay {
  display: none;
  @include media('>small') {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: grid-size(10);
    background-image: linear-gradient(180deg, transparent, $color-dayblue);
    opacity: .2;
  }
}

.calendar__image--desktop {
  display: none;
  @include media('>small') {
    display: block;
  }
}

.calendar__image--mobile {
  display: block;
  @include media('>small') {
    display: none;
  }
}
