.vacancy-map {
  height: 80vh;
  min-height: 500px;
  width: 100%;

  border-radius: $border-radius;

  // Reset some info window styling
  .gm-style .gm-style-iw-c {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    min-width: 290px !important;
    max-width: 290px !important;

    @include media(">small") {
      min-width: 450px !important;
      max-width: 450px !important;
    }

    @include media(">medium") {
      min-width: 290px !important;
      max-width: 290px !important;
    }

    @include media(">large") {
      min-width: 450px;
      max-width: 450px !important;
    }

    button {
      display: none;
      visibility: hidden;
    }
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style .gm-style-iw-t::after {
    display: none;
    visibility: hidden;
  }
}

.vacancy-map .cluster {
  border-radius: 35px;
  height: 40px;
  width: 40px;
  background-color: $color-hotorange;

  color: $color-white;
  font-family: $font-opensans--bold;
  font-size: 20px;

  > div {
    top: 6px;
  }
}

.vacancy-map--overlay {
  .vacancy-map__overlay {
    opacity: 0.2;
    transition: opacity 300ms;
  }
}

.vacancy-map__overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #285DAB;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  width: 5000px;
  height: 5000px;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 300ms;
}
