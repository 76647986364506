.image-card__wrapper {
  position: relative;
  overflow: hidden;
  height: 200px;
  border-radius: $border-radius;
}

.image-card__label {
  position: absolute;
  top: 10px;
  left: 10px;
  margin-right: 10px;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  pointer-events: none;
  color: $color-white;
  background-color: $color-hotorange;
  font-family: $font-dobraslab-medium;
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 1.15;

  @include media('>medium') {
    font-size: 14px;
    line-height: 1.25;
  }

  svg {
    width: 12px;
    height: auto;
    margin-right: 4px;

    @include media('>large') {
      width: 14px;
      margin-right: 8px;
    }
  }

  span {
    margin-top: -1px;
  }
}

.image-card__link {
  width: 100%;
  height: 100%;
  display: flex;

  &:hover {
    text-decoration: none;

    .image-card__image {
      transform: scale(1.1);
      transition: $image-transition;
    }
  }

  &--no-zoom {
    &:hover {
      .image-card__image {
        transform: none;
        transition: none;
      }
    }
  }
}

.image-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: $image-transition;
  background-size: cover;
}

.image-card__video {
  position: absolute;
  height: 100%;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity .3s linear;

  &:hover {
    opacity: 1;
  }
}

.image-card__title {
  z-index: 1;
  width: 100%;
  padding: 0 8px;
  margin: 8px;
  text-align: center;
  line-height: grid-size(11);
  align-self: flex-end;
  border-radius: 22px;
  background-color: $color-white;
  color: $color-coolblue;
  font-family: $font-opensans--bold;
  text-decoration: none;
  pointer-events: none;

  @include media('>small') {
    padding: 0 grid-size(5);
    width: auto;
    margin: 0 auto grid-size(4);
    min-width: grid-size(37);
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.image-card__frame {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: static-url('images/lijst_barok.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @include media('>large') {
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.3))
  }
}

@mixin glare {
  &:after {
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0.0) 100%
    );
  }

  .image-card__link:hover & {
    &:after {
      animation: glare 5s ease-in-out infinite;
    }
  }

  .image-card__link:focus {
    text-decoration: none;
  }
}

@keyframes glare {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
