@mixin language-flag-modifiers {
  &--nl {
    background-image: static-url('images/flags/nl.svg');
  }
  &--be {
    background-image: static-url('images/flags/be.svg');
  }
  &--de {
    background-image: static-url('images/flags/de.svg');
  }
}
