.login-page {
  position: relative;
  background-color: $color-dayblue;
  overflow: hidden;
}

.login-page__logo {
  position: absolute;
  width: 150px;
  height: 150px;
  left: -18px;
  bottom: -27px;

  @include media('>large') {
    width: 300px;
    height: 300px;
    left: -36px;
    bottom: -54px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}
