$background-color: transparentize($color-dayblue, 0.7);
$background-color-light: #f2f7fc;
$padding: 12px 20px 13px 20px;

.event-header__primary-buttons {
  display: flex;
  order: 5;
  flex-grow: 1;
  margin-bottom: grid-size(3);

  @include media('>small') {
    margin-right: auto;
    order: 6;
  }

  @include media('>medium') {
    flex-grow: 0;
  }
}

.event-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $color-white;

  @include media('>small') {
    flex-direction: row;
    border-radius: $border-radius;
    flex-wrap: wrap;
  }

  @include media('>medium') {
    background-color: $background-color;
    padding: 16px 32px;
    flex-wrap: nowrap;
  }
}

.event-header--light {
  color: $color-text;

  @include media('>small') {
    background-color: $background-color-light;
  }
}

.event-header__column {
  display: flex;
  align-self: flex-start;
  width: 100%;
  margin-right: grid-size(3);

  &:last-child {
    margin-right: 0;
  }

  @include media('>small') {
    width: initial;
    margin-right: 0px;
    align-self: stretch;
  }

  @include media('>medium') {
    align-self: center;
    margin-right: grid-size(3);
    padding: 0px 32px;
  }
}

.event-header__column--button {
  order: 1;
  margin-top: grid-size(3);

  @include media('>small') {
    order: 1;
  }

  @include media('>medium') {
    order: 0;
    margin-top: 0;
    padding: 0;
  }
}

.event-header__column--date,
.event-header__column--location {
  background-color: $background-color;
  padding: 0px;

  .event-header--light & {
    background-color: $background-color-light;
  }

  @include media('>small') {
    background-color: $background-color;
    margin-top: grid-size(1);
    flex-basis: 50%;
    margin-right: 0;

    .event-header--light & {
      background-color: initial;
    }
  }

  @include media('>medium') {
    background-color: initial;
    margin-top: grid-size(1);
    flex-basis: initial;
    height: auto;

    .event-header--light & {
      background-color: initial;
    }
  }
}

.event-header__column--date {
  border-radius: $border-radius $border-radius 0 0;

  @include media('>small') {
    padding: 12px 4px 12px 16px;
  }

  @include media('>medium') {
    margin-right: grid-size(3);
    padding: 0px 16px;
    margin-top: 0;
  }
}

.event-header__column--location {
  padding-top: 0;
  border-radius: 0 0 $border-radius $border-radius;

  @include media('>small') {
    padding: 12px 16px 12px 4px;
  }

  @include media('>medium') {
    margin-right: grid-size(3);
    padding: 0px 16px;
    margin-top: 0;
  }
}

.event-header__column-icon {
  margin-right: 5px;
  padding-top: 2px;

  .event-header--light & {
    svg {
      path,
      g {
        fill: $color-text;
      }
    }
  }
}

.event-header__column-text {
  b {
    font-family: $font-opensans--bold;
  }
}

a.event-header__button,
.apply-bar__button {
  &[disabled] {
    pointer-events: none;
  }
}

a.event-header__button {
  @include media('>small') {
    width: 214px;
    margin-right: grid-size(3);
  }

  @include media('>medium') {
    width: auto;
  }

  .event-header--unpublished & {
    order: 5;
  }
}
