.gdpr {
  .form__line {
    display: none;

    @include media('>small') {
      display: block;
      margin: grid-size(5) 0;
    }
  }
}

.gdpr--minimal + .form__error {
    margin-left: 26px;
  }

.gdpr__intro,
.gdpr__outro {
  display: none;

  @include media('>small') {
    display: block;
  }
}

.gdpr__intro {
  @include media('>small') {
    margin-bottom: grid-size(5);
  }
}

.gdpr__label {
  display: flex;
  cursor: pointer;
  margin-bottom: grid-size(2);

  @include media('>small') {
    margin-bottom: grid-size(5);

    .gdpr--minimal & {
      margin-bottom: grid-size(2);
    }
  }
}

.gdpr__link {
  margin-left: 26px;

  @include media('>small') {
    display: none;

    .gdpr--minimal & {
      display: block;
      margin-bottom: grid-size(2);
    }
  }

}
