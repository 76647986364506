.talent-pool-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: grid-size(7);
  background-color: var(--theme-background-primary);
  border-radius: $border-radius;

  @include media('>large') {
    flex-direction: row;
  }
}

.talent-pool-block__content {
  width: 100%;

  @include media('>large') {
    width: 50%;
  }
}

.talent-pool-block__fact {
  @include typography_fact;
  color: $color-white;

  &:after {
    display: none;
  }

  @include media('>small') {
    font-size: 28px;
    line-height: 28px;
  }
}

.talent-pool-block__slogan {
  @include typography_slogan;
  color: $color-white;
  margin-bottom: grid-size(3);

  @include media('>small') {
    font-size: 20px;
    line-height: 20px;
  }
}

.talent-pool-block__text {
  color: $color-white;

  @include media('>small') {
    font-size: 13px;
  }
}

.talent-pool-block__button {
  width: 100%;
  text-align: center;
  align-self: flex-end;
  margin-top: grid-size(8);

  @include media('>large') {
    width: auto;
  }
}
