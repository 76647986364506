.form__field.form__file {
  width: 0;
	//height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.form__file-button {
  padding: 14px 20px;
  cursor: pointer;
}

.form__file-button--hidden {
  display: none;
}

.form__file-selected {
  display: none;
}

.form__file-button--hidden + .form__file-selected {
  display: flex;
}
.form__file-name {
  padding: 14px 12px 13px 36px;
  border: 1px solid $color-grey;
  border-radius: $border-radius;
  background-color: #F8F8F8;
  background-image: static-url('images/paperclip.svg');
  background-repeat: no-repeat;
  background-position: center left 12px;
  background-size: 14px;
  font-size: 13px;
  line-height: 16px;
  cursor: default;
}

.form__file-clear {
  padding: 14px 12px 13px 24px;
  margin-left: 20px;
  color: $color-coolblue;
  background-image: static-url('images/close.svg');
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
