.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;

  transition: opacity 300ms;

  background-color: black;
  opacity: 0;

  pointer-events: none;
}

.nav-overlay--visible {
  opacity: .5;
  pointer-events: all;
}
