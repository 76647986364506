.chevron-link {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-family: $font-opensans--bold;
  line-height: 18px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 5px;
    height: 10px;
    width: 10px;
  }

  span {
    display: block;
    padding-left: 15px;
  }
}
