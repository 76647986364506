$slider-background: #EBEBEB;
$slider-thumb-border: #0090E3;

@mixin slider-thumb() {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: white;
  cursor: pointer;

  border: 3px solid $slider-thumb-border;
  border-radius: 21px;
}

.range-slider {
  position: relative;
  margin-bottom: 25px;
}

.range-slider__input {
  position: relative;
  z-index: 2;
  width: 100%;

  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  background: $slider-background;
  outline: none;
  margin: 0;
  padding: 0;
}

.range-slider__input::-webkit-slider-thumb {
  @include slider-thumb();
}

.range-slider__input::-moz-range-thumb {
  @include slider-thumb();
  width: 15px;
  height: 15px;
}

.range-slider__input::-ms-thumb {
  @include slider-thumb();
}

.range-slider__ticks {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 5px;
  width: 100%;
  justify-content: space-between;
  padding: 0 11px;
}

.range-slider__tick {
  display: flex;
  justify-content: center;
  height: 18px;
  width: 1px;
  background: #e3e3e3;
  color: #B5B5B5;
  font-size: 14px;
  line-height: 70px;

  //&:first-child,
  //&:last-child {
  //  visibility: hidden;
  //}
}
