.events {
  min-height: 25vh;
}

.event-results__title {
  display: flex;
  gap: grid-size(4);
  align-items: center;
}

.event-results__count {
  @include typography_h3;

  display: none;
  margin: 0px;

  @include media('>medium') {
    display: block;
  }
}

.event-results__count--hidden {
  display: none;
}

.event__filter-buttons {
  display: flex;
  gap: grid-size(3);
  justify-content: space-around;
  align-items: center;
  padding: 0px;

  @include media('>medium') {
    justify-content: flex-start;
  }
}

.button--past-events,
.button--current-events {
  @include typography_button;
  @include button-border;

  font-family: $font-opensans--semibold;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: none;
  color: #111111;

  flex-shrink: 0;

  &:hover {
    border-color: #999;
    box-shadow: none;
  }
}

.button--past-events {
  width: grid-size(46);
}

.button--current-events {
  width: grid-size(35);
}

.event__filter-button--active {
  border: 1px solid #0090e3;
  background: #e5f6ff;
  font-family: $font-opensans--bold;
  box-shadow: none;
}

.event-results {
  display: flex;
  flex-wrap: wrap;
  gap: grid-size(3);
  margin-top: grid-size(11);
}

.event-results__item {
  flex: 0 1 100%;
  margin: 0px;
  transition: opacity 0.35s ease-in-out, transform 0.25s linear;

  @include media('>medium') {
    flex: 0 1 calc(50% - #{grid-size(2)});
  }
}

.event-header__column-text {
  .chevron-link {
    font-family: $font-opensans;
    font-size: 14px;

    span {
      display: inline-block;
      margin-right: grid-size(2);
      padding-left: 0;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
