.filters {
}

.filters__container {
  transform: translateX(-100%);
  transition: transform .2s linear;
  position: fixed;
  left: 0;
  top: 0;
  width: 85%;
  height: 100%;
  background-color: $color-white;
  z-index: 5;
}

.filters__container--visible {
  transform: translateX(0);
}

.filters__header {
  padding: 0 grid-size(5);
  background-color: $color-coolblue;
  color: $color-white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 0px $color-darkgrey;
}

.filters__title {
  @include typography_h3;
  margin: 0;
  line-height: grid-size(13);
}

.filters__close-button {
  background-image: static-url('images/close-white.svg');
  position: absolute;
  top: 19px;
  right: grid-size(5);
  text-indent: 100%;
  overflow: hidden;
  width: 14px;
  height: 14px;
}

.filters__form {
  padding: grid-size(2) 0 0;
  position: absolute;
  overflow-y: auto;
  top: 52px;
  bottom: calc(76px + env(safe-area-inset-bottom));
  width: 100%;
}

.filters__button-row {
  position: absolute;
  padding: grid-size(3) grid-size(5);
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  box-shadow: 0px 0px 2px -1px $color-darkgrey;
}

.filters__button[disabled] {
  display: none;
}

@include media ('>medium') {
  .filters {
    padding-right: grid-size(6);
  }

  .filters__container {
    transform: none;
    position: static;
    width: auto;
  }

  .filters__header {
    background-color: transparent;
    color: $color-text;
    padding: 0;
    box-shadow: none;
  }

  .filters__title {
    margin-top: 12px;
    margin-bottom: 4px;
    line-height: 1.25;
  }

  .filters__close-button {
    display: none;
  }

  .filters__form {
    position: static;
    margin-top: grid-size(4);
    padding: 0;
  }

  .filters__button-row {
    display: none;
  }

  .filters__floating-bar {
    display: none;
  }
}
