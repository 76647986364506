.vacancy-header {
  --vacancy-header-background: var(--theme-background-primary);
  --vacancy-header-accent: var(--theme-background-primary);
  margin-top: map-get($nav-header-height, small);

  @include media($nav-header-flip) {
    margin-top: map-get($nav-header-height, medium);
  }
}

.vacancy-header__external-title {
  background: rgba(0, 0, 0, 0.3);
  color: $color-white;
  padding: grid-size(1) grid-size(2);
  margin-top: grid-size(3);
  border-radius: $border-radius;
}

.vacancy-header__external-name {
  font-family: $font-opensans--bold;
}

.vacancy-header__container {
  background-color: var(--vacancy-header-background);
}

.vacancy-header__image {
  @include media('>medium') {
    width: 100%;
    height: 378px;
    background-size: cover;
  }
  & + .vacancy-header__container .header-form {
    @include media('>large') {
      margin-top: -126px;
    }
  }
}

.vacancy-header__inner {
  background-color: transparent;
  padding-top: grid-size(10);
  padding-bottom: grid-size(7);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include media('>medium') {
    padding-top: grid-size(18);

    &--form {
      display: grid;
      padding-top: grid-size(8);
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 12px;
      align-items: start;
    }
  }
}

.vacancy-header__info {
  @extend .vacancy-header__inner;
  padding-top: grid-size(4);
  padding-bottom: 0;
  grid-column: 1 / span 12;
  @include media('>large') {
    grid-column: 1 / span 6;
  }
}

.vacancy-header__form {
  grid-column: 1 / span 12;
  width: 100%;
  @include media('>large') {
    grid-column: 7 / span 6;
  }
  @include media('>extra-large') {
    grid-column: 8 / span 5;
  }
}

.vacancy-header__back {
  transform: translateY(-4px);
  @include media('>small') {
    transform: translateY(-12px);
  }
  @include media('>large') {
    transform: translateY(-20px);
  }
}

.vacancy-header__link {
  color: $color-white;
  font-family: $font-opensans--semibold;
  position: relative;
  padding-left: grid-size(4);

  &:before {
    content: '';
    background-image: static-url('images/chevron-left-white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: grid-size(3);
    height: grid-size(3);
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.vacancy-header__title {
  @include typography_fact;
  line-height: 1;
  color: $color-white;
  order: 0;
  flex-basis: 100%;

  @include media('>small') {
    line-height: 1;
  }
}

.vacancy-header__tooltip {
  order: 1;
}

.vacancy-header__intro-wrapper {
  order: 4;
  flex-basis: 100%;
}

.vacancy-header__intro-title {
  @include typography_h2;
  color: $color-white;
}

.vacancy-header__intro {
  color: $color-white;
  margin: 0 0 $margin-small 0;
  max-width: 720px;
  @include typography_intro;
}

.vacancy-header__about-button {
  flex-basis: 100%;
  order: 5;
  display: flex;
  align-items: center;
  margin-bottom: grid-size(6);

  svg {
    width: 16px;
    height: auto;
    margin-right: grid-size(3);

    &:last-child {
      width: 10px;
      margin-right: 0;
    }
  }

  a {
    color: $color-white;
    font-family: $font-opensans--bold;
    margin-right: grid-size(2);
  }
}

.vacancy-header__primary-buttons {
  position: sticky;
  bottom: grid-size(3);

  display: flex;
  order: 5;
  flex-grow: 1;
  margin-bottom: grid-size(3);

  @include media('>small') {
    position: relative;

    margin-right: auto;
    flex-grow: 0;
    order: 6;
  }
}

.vacancy-header__inner--form {
  .share-button {
    margin-left: 0;
  }
  .vacancy-header__primary-buttons {
    display: none;
  }
}

.vacancy-header__extra-buttons {
  order: 2;
  width: 100%;

  @include media('>small') {
    flex-grow: 1;
    order: 5;
  }
}

.vacancy-header__button {
  width: 100%;
  text-align: center;

  @include media('>small') {
    width: auto;
    margin-right: grid-size(3);
  }

  .vacancy-header--unpublished & {
    order: 5;
  }
}

.vacancy-header__meta {
  order: 2;
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: $margin-medium 0 $margin-extratiny 0;
}

.vacancy-header__meta-item {
  order: 2; // Use order: 2 so we make space for something in front

  background-position: top left;
  background-repeat: no-repeat;
  color: $color-white;
  padding-right: grid-size(7);
  padding-bottom: grid-size(2);
  padding-left: grid-size(6);
  margin-bottom: $margin-extratiny;
}

.vacancy-header__meta-item--education {
  padding-left: grid-size(8);
  background-image: static-url('images/study-white.svg');
}

.vacancy-header__meta-item--hours {
  background-image: static-url('images/clock-white.svg');
}

.vacancy-header__meta-item--location {
  background-image: static-url('images/pin-white.svg');

  @include media('>medium') {
    position: relative;
  }
}

.vacancy-header__meta-item--timeslots {
  background-image: static-url('images/calendar.svg');

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.vacancy-header__location-chooser {
  display: block;
  position: relative;
  margin-right: 16px;
  padding-right: 3px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    .vacancy-header__location-chooser-number {
      text-decoration: none;
    }
  }
}

.vacancy-header__location-chooser-number {
  position: absolute;
  left: 100%;
  top: -2px;
  text-align: center;
  line-height: 16px;
  height: 16px;
  padding: 0 5px;

  border-radius: 10px;
  background-color: $color-hotorange;
  font-family: $font-opensans--bold;
  font-size: 12px;
}

.vacancy-header__meta-item--message {
  display: flex;
  align-items: center;
  transform: translateY(-4px);
  padding: 4px 16px 4px 16px;
  background-color: transparentize($color-dayblue, 0.7);
  border-radius: $border-radius;

  svg {
    height: 14px;
    margin-right: 6px;
    width: auto;

    path {
      fill: $color-white;
    }
  }
}

.vacancy-header__meta-item--left {
  order: 1;
  margin-right: grid-size(7);
}

.vacancy-header__meta-item--has-icon {
  padding-left: 8px;
}

.vacancy-header__meta-item--internal {
  order: 0;
  background-color: $color-hotorange;
}

.vacancy-header__meta-item--link {
  cursor: pointer;

  span,
  &:hover {
    text-decoration: underline;
  }

  @include media('>small') {
    span {
      text-decoration: none;
    }
  }
}

.vacancy-header__usps {
  flex-basis: 100%;
  order: 4;
  margin: grid-size(3) 0 grid-size(6) 0;

  & + .vacancy-header__detail-text {
    margin-top: grid-size(-6);
  }
}

.vacancy-header__usp-item {
  background-image: static-url('images/check-green.svg');
  background-repeat: no-repeat;
  background-position: top 2px left;
  color: $color-white;
  padding-left: grid-size(7);
  margin-bottom: grid-size(2);

  .body--internal & {
    background-image: static-url('images/check.svg');
  }
}

.vacancy-header__usp-title {
  font-family: $font-opensans--bold;
  margin-right: grid-size(1);
}

.vacancy-header__detail-text {
  flex-basis: 100%;
  order: 5;
  margin: 0 0 grid-size(9) 0;

  span {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}

.vacancy-header__salary {
  -webkit-tap-highlight-color: transparent;
  color: $color-white;
  cursor: pointer;
  padding: 12px 20px 13px 20px;
  border-radius: $border-radius;
  background-color: transparentize($color-dayblue, 0.7);
  background-color: var(--theme-accent);
  font-size: 13px;
  margin-bottom: grid-size(3);
  width: 100%;

  display: none;
  visibility: hidden;

  &--enable-mobile {
    display: inline-block;
    visibility: visible;
  }

  @include media('>small') {
    width: auto;
    display: inline-block;
    visibility: visible;
  }
}

.vacancy-header__salary--text-popover {
  display: inline-block;
  visibility: visible;
}

.vacancy-header__salary-text {
  display: block;
  background-image: static-url('images/info.svg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 14px;
  padding-top: 10px;
  padding-right: 24px;

  .vacancy-header__salary:hover & {
    text-decoration: underline;
  }

  @include media('>small') {
    display: inline-block;
    float: right;
    padding-top: 2px;
  }
}

.vacancy-header__salary--calculator {
  display: inline-block;
  visibility: visible;

  @include media('>small') {
    display: none;
    visibility: hidden;
  }

  .vacancy-header__salary-text {
    background-image: static-url('images/calculator.svg');
  }
}

.vacancy-header__salary--scroll-button {
  display: none;
  visibility: hidden;

  @include media('>small') {
    display: inline-block;
    visibility: visible;
  }

  .vacancy-header__salary-text {
    background-image: static-url('images/calculator.svg');
  }
}

.vacancy-header__salary-amount {
  display: block;
  font-size: 14px;
  font-family: $font-opensans--bold;
  margin-right: grid-size(4);

  @include media('>small') {
    display: inline-block;
  }
}

$offset-top: grid-size(9);
.vacancy-header__read-more {
  text-align: center;
  color: $color-white;
  grid-column: 1 / span 12;
  width: 100%;
  display: none;
  pointer-events: none;
  position: relative;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '';
    display: block;
    height: grid-size(8);
    width: grid-size(8);
    top: -#{$offset-top};
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: $color-hotorange;
    position: absolute;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: grid-size(8);
    width: grid-size(8);
    top: -#{$offset-top};
    left: 50%;
    transform: translateX(-50%);
    background-image: static-url('images/chevron-down-white.svg');
    background-position: center;
    background-size: 43.75%;
    background-repeat: no-repeat;
  }
  @include media('>extra-large') {
    display: block;
    pointer-events: auto;
  }
}
