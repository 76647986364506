.share-button {
  position: sticky;
  bottom: 0;

  display: flex;
  flex-direction: row;
  order: 7;
  margin-left: grid-size(3);

  @include media('>small') {
    position: relative;
    margin-left: auto;
  }

  button {
    height: 48px;
    z-index: 2;
    text-indent: -9999em;
    padding-right: 0;

    @include media('>small') {
      display: none;
    }
  }
}

.share-button--clipboard-copied {
  .share-button__clipboard-message {
    opacity: 1;
  }
}

.share-button__clipboard-message {
  position: absolute;

  opacity: 0;
  pointer-events: none;

  transition: opacity 200ms;
  font-size: 13px;

  top: grid-size(5);
  left: 50%;
  transform: translateY(-100%) translateX(-50%);

  width: 100%;
  text-align: right;

  color: white;
}
