.vacancies {
  @include media('>medium') {
    display: flex;
    flex-direction: row;
  }
}
.vacancies--empty {
  .results__nothing-found {
    display: block;
  }

  .results__nothing-found-banner {
    display: block;
  }

  .results__banner {
    display: none;
  }

  .results__loadmore,
  .results__items,
  .results__map {
    display: none;
  }
  .filters__button[disabled] {
    display: block;
  }
  .results__toolbar {
    display: none;
  }
  .filters__button {
    display: none;
  }
  .results__cross-link {
    display: none;
  }
}
.vacancies--loading {
  .vacancies__loader {
    display:  block;
  }
  .results {
    pointer-events: none;
    opacity: .2;
    transition: opacity .2s linear;
  }
  .results__loadmore,
  .results__banner {
    display: none;
  }
}

.vacancies__filter {
  @include media('>medium') {
    flex: 0 0 264px;
  }

}

// RW-152: Highlight department
.vacancies__filter--highlighted {
  .filter-form__row--highlighted {
    background-color: $color-greyblue;
    border-radius: $border-radius;
    padding: grid-size(4);

    @include media('<medium') {
      margin-left: grid-size(5);
      margin-right: grid-size(5);
      border: none;
    }

    .filter-form__checkbox-label {
      display: flex;
    }

    .filter-form__checkbox-text {
      &:before {
        border-color: $color-coolblue;
        background-color: $color-greyblue;
      }
    }
    .filter-form__has-subopts > .filter-form__checkbox-label .filter-form__checkbox-text:after {
      right: auto;
      margin-left: grid-size(1);
    }
  }

  .filter-form__checkbox-icon {
    display: block;
  }
}

.vacancies__results {
  position: relative;
  width: 100%;
}

.vacancies__loader {
  display: none;
  position: absolute;
  left: 50%;
  top: 150px;
  z-index: 1;

  @include media('>medium') {
    top: 110px;
  }
}
