.panel {
  border-bottom: 1px solid $color-grey;
  padding-top: grid-size(2);
  // safari transform3d fix for z-index issue
  perspective: 1000px;
}

.panel__title {
  font-family: $font-opensans--bold;
  color: $color-coolblue;
  padding: grid-size(2) 0 grid-size(1) 0;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 12px;
    bottom: 4px;
    width: 12px;
    height: 12px;
    background-image: static-url('images/chevron-down-blue.svg');
    background-repeat: no-repeat;
    background-size: 12px;
    transition: transform .2s linear .2s;
    transform-origin: center;
  }
}

.panel__text {
  padding: grid-size(1) 0;
  opacity: 0;
  max-height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all .2s ease-in-out .2s;

  .rich-text {
    max-width: grid-size(220);
  }
}

.panel--open {
  .panel__title:after {
    transform: rotateX(180deg);
    transition: transform .2s linear;
  }
  .panel__text {
    padding-top: grid-size(3);
    padding-bottom: grid-size(3);
    opacity: 1;
    max-height: 200vh;
    transform: rotateX(0);
    transition: all .2s ease-in-out;
  }
}
