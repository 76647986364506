.header-form {
  position: relative;
  padding: grid-size(3);
  margin-left: -#{grid-size(3)};
  margin-right: -#{grid-size(3)};
  margin-top: grid-size(6);
  border-radius: $border-radius;
  background-color: $color-white;
  @include media('>medium') {
    padding: grid-size(6);
    margin-left: -#{grid-size(6)};
    margin-right: -#{grid-size(6)};
  }
  @include media('>large') {
    margin-top: 0;
  }
}

.header-form__background {
  position: absolute;
  background-color: white;
  left: -#{grid-size(2)};
  right: -#{grid-size(2)};
  top: grid-size(15);
  bottom: -#{grid-size(13)};
  pointer-events: none;
  z-index: 0;
  @include media('>medium') {
    top: grid-size(24);
    left: -#{grid-size(20)};
    right: -#{grid-size(20)};
  }
  @include media('>large') {
    display: none;
  }
}

.header-form__fact {
  @include typography_fact
}

.header-form__slogan {
  @include typography_slogan
}

.header-form__form {
  margin-top: grid-size(8);
  margin-left: grid-size(-6);
  margin-right: grid-size(-6);
  overflow-x: hidden;

  .form__options--radio-list {
    display: flex;
    justify-content: space-between;
    .form__option {
      flex: 0 0 calc(50% - #{grid-size(2)})
    }
  }
}

.slider-form {
  overflow: hidden;
}

.slider-form__slide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: grid-size(6);
  padding-right: grid-size(6);
  margin-bottom: 0;
}

.slider-form__errors {
  margin-left: grid-size(6);
  margin-right: grid-size(6);
  width: auto;
}

.slider-form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 grid-size(6);
  margin-top: grid-size(6);
}

.slider-form__errors + .slider-form__footer {
  margin-top: grid-size(10);
}

.slider-form__apply-button {
  margin-left: auto;
  z-index: 1;
}

.slider-form__pagination {
  &.swiper-pagination-bullets.swiper-pagination-horizontal {
    position: relative;
    bottom: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    gap: grid-size(12);
    margin-left: grid-size(3);
    @include media('>large') {
      gap: grid-size(20);
    }
  }
}

.slider-form__bullet {
  font-size: 0;
  position: relative;
  display: block;
  width: grid-size(8);
  height: grid-size(8);
  border-radius: grid-size(4);
  color: $color-coolblue;
  border: solid 3px $color-coolblue;
  background-color: $color-coolblue;
  text-align: center;
  user-select: none;
  cursor: pointer;
  overflow: visible;

  &-label {
    position: absolute;
    left: 50%;
    top: -#{grid-size(7)};
    transform: translate(-50%, 0);
    font-size: 11px;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: -52px;
    transform: translateY(-50%);
    width: 50px;
    height: 3px;
    background-color: $color-coolblue;
    @include media('>large') {
      left: -84px;
      width: 84px;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 14px;
    background-image: static-url('images/check.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:first-of-type {
    &:before {
      content: none;
    }
  }

}

.slider-form__bullet--active {
  @include typography_h2;
  line-height: 1.5;
  margin: 0;
  color: $color-text;
  border-color: $color-white;
  background-color: $color-white;

  &:after {
    background-image: static-url('images/bus_bullet.svg');
    width: 42px;
    height: 30px;
    z-index: 1;
  }

  @include media('>small') {
    font-size: 16px;
  }
}

.slider-form__bullet--active ~ .slider-form__bullet {
  line-height: 1.5;
  margin: 0;
  color: $color-raingrey;
  border-color: $color-greyblue;
  background-color: $color-white;

  &:before {
    background-color: $color-greyblue;
  }

  &:after {
    content: none;
  }

  @include media('>small') {
    font-size: 16px;
  }
}
