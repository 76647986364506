.foldout {

}

.foldout__panel {
  &:first-child {
    .panel__title {
      padding-top: grid-size(2);
    }
  }
}

.foldout__title {
  @include typography_h2;
  color: $color-coolblack;
}
