.ie {
  // Slider
  .slider__item {
    @include media('>small') {
      flex: 0 0 50%;
      &--hidden {
        opacity: 1;
      }
    }

    @include media('>medium') {
      flex: 0 0 33.3%;
    }

    @include media('>large') {
      flex: 0 0 25%;
    }
  }

  .slider--start .slider__button--prev,
  .slider--end .slider__button--next {
    opacity: 1;
    pointer-events: auto;
  }

  // Grid
  .grid__item {
    @include media('>small') {
      flex: 0 0 48%;
    }

    @include media('>medium') {
      flex: 0 0 31%;
    }

    @include media('>large') {
      flex: 0 0 23%;
    }
  }

  .related .slider__item {
    &:last-child {
      flex: 0 0 95%;
    }

    @include media('>small') {
      flex: 0 0 100%;
      &:last-child {
        flex: 0 0 100%;
      }
    }

    @include media('>medium') {
      flex: 0 0 50%;
      &:last-child {
        flex: 0 0 50%;
      }
    }

    @include media('>large') {
      flex: 0 0 50%;
      &:last-child {
        flex: 0 0 50%;
      }
    }
  }
}
