.campaign-block {
  padding-bottom: 36px;

  &--grey {
    background-color: $color-whitesmoke;
  }

  .page-content & .container__inner {
    background-color: transparent;
  }
}

.page-content .campaign-block.container:last-child {
  margin-bottom: 0;
}

.page-content .campaign-block .campaign-block__inner-container {

  padding: 12px 0;
  margin: 0;
  max-width: 100%;

  @include media('>small') {
    padding: 12px 0;
    margin: 0;
  }

  @include media('>large') {
    padding: 12px 32px;
    margin: 0 auto;
    max-width: 1000px;
  }
}

.page-content .container--wide.campaign-block .campaign-block__inner-container {
  max-width: 1248px;
}

.campaign-block__container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 0 16px;
  scroll-padding: 0 16px;

  @include media('>small') {
    padding: 0 64px;
    scroll-padding: 0 64px;
  }

  @include media('>medium') {
    padding: 0 84px;
    scroll-padding: 0 84px;
  }

  @include media('>large') {
    padding: 0;
    scroll-padding: 0;
    scroll-snap-type: none;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: 32px;
    grid-template-columns: none;
    overflow: visible;
  }
}

.campaign-card {
  flex: 0 0 calc(80vw);
  margin-right: 16px;
  scroll-snap-align: center;

  @include media('>small') {
    flex: 0 0 calc(60vw);
  }

  @include media('>medium') {
    flex: 0 0 calc(30vw);
  }

  @include media('>large') {
    margin-right: 0;
  }

  .image-card__wrapper {
    height: auto;
    overflow: visible;
  }

  .image-card__link {
    display: block;
  }

  .image-card__media-wrapper {
    position: relative;
    padding-top: 141.8%;
  }

  .image-card__image-wrapper {
    position: absolute;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .image-card__title {
    margin-top: 24px;
    padding: 0 8px;
  }
}

.campaign-card--with-frame {
  .image-card__image-wrapper {
    margin: 18% 16.5%;
  }
}
