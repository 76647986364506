.related-block__header {
  margin-bottom: $margin-medium;
}

.related-block__fact {
  @include typography_fact;
}

.related-block__slogan {
  @include typography_slogan;
}

.related-block__inner {
  display: flex;
  flex-wrap: wrap;

  @include media('>small') {
    flex-wrap: nowrap;
  }

  .card {
    flex: 0 0 100%;
    margin-bottom: $margin-medium;

    @include media('>small') {
      flex: 0 0 calc(50% - #{$margin-small});
      margin-bottom: 0;
      margin-right: $margin-small;
    }

    @include media('>medium') {
      flex: 0 0 calc(50% - #{$margin-medium});
      margin-right: $margin-medium;
    }
  }
}
