.cross-link {
  display: block;
  color: $color-text;

  &:hover {
    text-decoration: none;
    .cross-link__label {
      text-decoration: underline;
    }
  }
}

.cross-link__inner {


}

.cross-link__title {
  @include typography_h2;
  margin-top: 0;
}

.cross-link__text {
  margin-bottom: 10px;
}

.cross-link__label {
  display: inline;
  svg path {
    fill: $color-coolblue;
  }
}
