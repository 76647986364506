$image-width: 150px;

.recruiter {
  margin-top: grid-size(10);

  .container__inner {
    position: relative;
    z-index: 3;
    background-color: white;
  }
}

.recruiter--wide {
  margin-top: 0;
  @include media('>medium') {
    background-color: $color-greyblue;

    .container__inner {
      background-color: #F2F7FC;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.recruiter__inner {
  position: relative;
  background-color: $color-greyblue;
  border-radius: $border-radius;

  @include media('>medium') {
    padding-right: $image-width + 30px;
  }
}

.recruiter__unpublished {
  display: none;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0;

  @include media('>medium') {
    display: block;
    flex-display: row;
    padding: 0;
    padding-top: 30px;
    padding-left: 25px;
  }
}

.recruiter__unpublished-title {
  @include typography_h2;
}

.recruiter__titles {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - #{$image-width});

  padding: 15px;
  padding-bottom: 0;

  @include media('>medium') {
    flex-direction: row;
    padding: 0;
    padding-top: 30px;
    padding-left: 25px;
    max-width: 80%;
  }
}

.recruiter__title {
  @include typography_h3;
  margin-top: 0;
}

.recruiter__subtitle {
  font-family: $font-opensans;
  display: block;

  @include media('>medium') {
    display: inline;
  }
}

.recruiter__contact {
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  border-top: 1px solid $color-grey;
  background-color: $color-greyblue;

  padding-top: 15px;
  padding-bottom: 15px;

  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;

  @include media('>medium') {
    position: inherit;
    border: 0;

    margin: 0;
    margin-top: 16px;

    padding: 0;
    padding-left: 25px;
  }

  @include media('>large') {
    flex-direction: row;

    .recruiter--narrow & {
      flex-direction: column;
    }
  }
}

.recruiter__contact-top {
  display: flex;
}

.recruiter__contact-bottom {
  .recruiter__contact-top + & {
    margin-left: 0;
    margin-top: 10px;

    @include media('>large') {
      margin-left: 15px;
      margin-top: 0;

      .recruiter--narrow & {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

.recruiter__message {
  position: relative;
  z-index: 2;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: grid-size(4);
  color: $color-darkgrey;
  font-size: 13px;
  background-color: $color-greyblue;

  @include media('>medium') {
    position: inherit;
    margin: 0;
    margin-top: grid-size(7);

    padding: 0;
    padding-left: 25px;
    padding-bottom: grid-size(4);
  }
}

.recruiter__image {
  $offset: 20px;

  position: absolute;
  right: 15px;
  top: -$offset;

  width: $image-width;
  height: calc(100% + #{$offset});

  overflow: hidden;

  img {
    display: block;
    height: auto;
    width: $image-width;
  }

  @include media('>medium') {
    right: $offset;

    .recruiter--bottom & {
      top: auto;
      bottom: 0;
      height: auto;
    }
  }
}
