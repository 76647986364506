.media-block__background {
  position: relative;
  width: 100%;
  height: 220px;
  margin-bottom: -46px;
  @include media('>small') {
    margin-bottom: -82px;
  }
  @include media('>large') {
    height: 560px;
    margin-bottom: -102px;
  }
}

.page-content .media-block .media-block__inner {
  background-color: transparent;
}

.media-block__background-image {
  @include image-background;
  position: absolute;
  height: 100%;
  background-size: cover;
}

.media-block__header {
  @include container-padding;
  background-color: $color-white;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  @include media('>large') {
    padding-top: map_get($container-padding, large);
    margin-right: 290px;
  }
}

.media-block__fact {
  @include typography_fact;
}

.media-block__slogan {
  @include typography_slogan;
}

.media-block__intro {
  @include container-padding;
  padding-bottom: 0;
  background-color: $color-white;
  @include media('>large') {
    padding-bottom: $margin-small;
    margin-right: 290px;
  }
}

.media-block__content {
  @include container-padding;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.media-block__text-wrapper {
  flex: 0 0 100%;
  @include media('>large') {
    flex: 0 0 272px;
    margin-right: $margin-small;
  }
}

.media-block__text {
  margin-bottom: 12px;
}

.media-block__cta {
  margin-bottom: 12px;
}

.media-block__media {
  position: relative;
  flex: 1 1;
  height: 200px;

  @include media('>medium') {
    height: 376px;
  }
}

// First image is narrower than the second
.media-block__text-wrapper + .media-block__media {
  flex: 0 0 40%;
  margin-right: $margin-tiny;
  @include media('>large') {
    flex: 0 0 212px;
  }
}

.media-block__video-wrapper {
  flex: 1 1 100%;

  @include media('>medium') {
    flex: 1 1 auto;
  }
}

.media-block__image {
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  background-size: cover;
}

.media-block--teaser {
  overflow: hidden;

  @include media('>large') {
    height: 52.14vw;
  }
  .media-block__background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .media-block__inner {
    margin: grid-size(18) auto;
    min-height: grid-size(146);
  }
  .media-block__intro {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding-bottom: 16px;
  }
  .media-block__content {
    display: none;
  }
}

.media-block--colored {
  background-color: $color-coolblue;
  .media-block__header,
  .media-block__intro {
    background-color: transparent;
    color: $color-white;
  }
  .media-block__fact,
  .media-block__slogan {
    color: $color-white;
  }
  .media-block__content {
    padding-top: grid-size(10);
    padding-bottom: grid-size(25);
  }
}

.media-block--colored + .container.media-block--default,
.page-content .container.media-block--colored {
  margin-top: 0;
  @include media('>medium') {
    margin-top: 0;
  }
}

.media-block--colored.media-block--inset {
  background-color: transparent;
  &:after {
    content: '';
    background-color: $color-coolblue;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - #{grid-size(28)});
    z-index: -1;
  }
}

.media-block--inset {
  .media-block__inner {
    margin-top: -#{grid-size(28)};
  }
}

.media-block--location {
  .media-block__intro {
    @include typography_h2();
  }

  .media-block__select {
    @extend .planner__select;
    display: none;
  }

  .media-block__background {
    @include media('>large') {
      height: 420px;
    }
  }
}
