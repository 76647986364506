$theme-accent: #0c81d2;
$nav-link-hover: #199be5;
$nav-link-active: #1476c7;

:root {
  --theme-background-primary: #{$color-coolblue};
  --theme-background-secondary: #{$color-coolblue};
  --theme-accent: #{$theme-accent};
  --theme-hover: #{$nav-link-hover};
  --theme-active: #{$nav-link-active};
  --theme-border: #{rgba($color-dayblue, .5)};
}
