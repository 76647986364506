.account-menu {
  opacity: 0;
  transform: scale(0.8);
  transform-origin: top left;
  transition: transform 200ms, opacity 200ms;
  pointer-events: none;

  border: 1px solid $color-grey;
  border-radius: $border-radius;
  background-color: white;

  &.account-menu--visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
  }
}

.account-menu__inner {
  padding: 15px;
}

.account-menu__link {
  display: block;
}
