.rich-text {
  max-width: 720px;
  margin-right: auto;

  h1 {
    @include typography_h1;
    color: $color-coolblack;
  }

  h2 {
    @include typography_h2;
    color: $color-coolblack;
  }

  h3 {
    @include typography_h3;
    color: $color-coolblack;
  }

  h4 {
    @include typography_h4;
    color: $color-coolblack;
  }

  h5 {
    @include typography_h5;
    color: $color-coolblack;
  }

  b {
    font-weight: 700;
  }

  a {
    color: $color-coolblue;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ol {
    padding-left: $margin-small;
    margin-bottom: $margin-small;
    list-style-type: decimal;
  }

  ul {
    padding-left: $margin-small;
    margin-bottom: $margin-small;
    list-style-type: disc;
  }

  img {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
