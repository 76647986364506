.button {
  display: inline-block;
  padding: 14px 20px;
  color: $color-coolblue;
  cursor: pointer;
  background-color: transparent;

  @include typography_button;
  @include button-border;
}

.button__inner {
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
}

// button sizing / positioning
.button--centered {
  display: block;
  margin: 0 auto;
  width: fit-content;
  text-align: center;
}

.button--wide {
  padding: 14px 70px;
  @include media('<=small') {
    width: 100%;
  }

  &.button--icon-before {
    padding-left: 70px;
  }

  &.button--icon-after {
    padding-right: 70px;
  }
}

.button--full-width {
  width: 100%;
  padding: 14px;

  &.button--icon-before {
    padding-left: 14px;
  }

  &.button--icon-after {
    padding-right: 14px;
  }

  &.button--icon-before:before,
  &.button--icon-after:after {
    display: inline-block;
    left: auto;
    right: auto;
  }

  &.button--icon-before:before {
    margin-left: -26px;
  }

  &.button--icon-after:after {
    margin-left: 10px;
  }
}

// button shape variants
.button--primary {
  @include button-border(true);
}

.button-round {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-family: $font-opensans--bold;
  font-size: 18px;
  line-height: 22px;
  border: none;
  border-radius: 19px;
  padding: grid-size(2) grid-size(5);
  white-space: nowrap;
  cursor: pointer;

  svg {
    width: auto;
    height: 9px;
    margin-left: 10px;
  }
}

// button color variants
.button--green {
  background-color: $color-freshgreen;
  border-color: #009900;
  box-shadow: inset 0 -2px 0 0 #009900;
  color: $color-white;

  &:hover {
    border-color: #005e00;
    background-color: #009600;
    box-shadow: inset 0 -2px 0 0 #005e00;
  }
}

.button--orange {
  background-color: $color-hotorange;
  color: $color-white;
}

.button--white {
  background-color: $color-white;
  color: $color-coolblue;
}

.button[disabled] {
  color: $color-darkgrey;
  background-color: $color-grey;
  border: none;
  border-bottom: 1px solid $color-raingrey;
  box-shadow: inset 0 -2px 0 0 $color-raingrey;
}

// button icons
.button--icon-before,
.button--icon-after {
  position: relative;
}

.button--icon-before:before,
.button--icon-after:after {
  content: '';
  position: absolute;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  width: 12px;
  height: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.button--icon-before {
  padding-left: 50px;

  &:before {
    left: 16px;
  }
}

.button--icon-after {
  padding-right: 40px;

  &:after {
    right: 16px;
  }
}

// icon sizing
.button--icon-medium {
  &:before,
  &:after {
    width: 16px;
    height: 16px;
  }
}

.button--icon-large {
  &:before,
  &:after {
    width: 20px;
    height: 20px;
  }
}

// icon variants
.button--icon-arrow-tail-blue {
  &:before,
  &:after {
    background-image: static-url('images/arrow-tail-blue.svg');
  }
}

.button--icon-chevron-down {
  &:before,
  &:after {
    background-image: static-url('images/chevron-down.svg');
  }
}

.button--icon-search {
  &:before,
  &:after {
    background-image: static-url('images/search-white.svg');
  }
}

.button--icon-share {
  &:before,
  &:after {
    background-image: static-url('images/share.svg');
  }
}

.button--icon-microsoft{
  &:before,
  &:after {
    background-image: static-url('images/microsoft-logo.svg');
  }
}
