.page-content {
  margin-top: 20px;

  .container__inner {
    background-color: $color-white;
  }
  .container {
    margin-top: grid-size(2);
    @include media('>medium') {
      margin-top: grid-size(7);
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: grid-size(5);
      @include media('>medium') {
        margin-bottom: grid-size(7);
      }
    }
  }
}

.page-content .container--talent-pool-block {
  margin-top: -32px;
  margin-bottom: 40px;

  @include media('>medium') {
    margin-top: -40px;
  }
}
