.language-switch-simple {
  font-size: 14px;
  line-height: 14px;
  color: $color-coolblack;

  cursor: default;
}

.language-switch-simple__country {
  display: flex;
  align-items: center;
  flex-direction: row;

  padding: 15px;

  border-bottom: 1px solid $color-grey;
}

.language-switch-simple__flag {
  margin-right: 10px;
  width: 13px;
  height: 13px;
  background-size: cover;
  @include language-flag-modifiers;
}

.language-switch-simple__label {
  font-family: $font-opensans--bold;
  flex: 1;
  margin-right: 10px;
}

.language-switch-simple__extended-link {
  color: $color-raingrey;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $color-darkgrey;
  }
}

.language-switch-simple__languages {
  margin: 15px;

  div, a {
    display: block;
    margin-top: 15px;
  }
  div {
    font-family: $font-opensans--semibold;
  }
}
