.grid {
  margin-bottom: grid-size(10);

  .page-content .slider + & {
    @include media('<=small') {
      margin-top: 0;
    }
  }
}

.grid__container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 12px;

  @include media('>medium') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('>large') {
    grid-template-columns: repeat(3, 1fr);

    &--extra-whitespace {
      padding-top: 20px;
    }
  }
}

.grid__title,
.grid__title--desktop,
.grid__title--mobile {
  @include typography_h2;
  color: $color-coolblack;
  margin: 0;
}

.grid__title--desktop {
  display: none;

  @include media('>small') {
    display: block;
  }
}

.grid__title--mobile {
  display: block;

  @include media('>small') {
    display: none;
  }
}

.grid__item {
  position: relative;
}

.grid__label {
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  background-color: $color-coolblue;
  padding: 0 20px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  display: flex;
  align-items: center;

  svg {
    width: 9px;
    height: auto;
    margin-right: 10px;
  }
}

.grid__label-text {
  font-family: $font-dobraslab-book;
  font-size: 11px;
  color: $color-white;
  line-height: 1;
}

.grid__label + .grid__card {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.grid__card {
  @include button-border;
  height: 100%;

  .grid__item--has-label & {
    padding-top: 20px;
  }

  .grid__container--extra-whitespace & {
    @include media('>large') {
      padding-top: 20px;
    }
  }
}

.grid__card-link {
  position: relative;
  display: block;
  padding: 8px 16px 10px;
  text-decoration: none;

  @include media('>large') {
    padding: 8px 20px 24px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .grid__item--has-label & {
    &:after {
      content: '';
      position: absolute;
      display: block;
      cursor: pointer;
      top: -20px;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.grid__card-title {
  font-family: $font-opensans--bold;
  font-size: 16px;
  line-height: 20px;
  color: $color-coolblue;
  margin-bottom: 4px;
}

.grid__card-description {
  font-family: $font-opensans;
  text-decoration: none;
  font-size: 13px;
  line-height: 17px;
  color: $color-darkgrey;
}

.grid__overview-button {
  display: none;

  @include media('>large') {
    display: block;
  }
}
