.apply-timeline__heading {
  @include typography_h2;
  margin-bottom: $margin-medium;
}

.apply-timeline__sub-heading-h2 {
  @include typography_h2;
  margin: 0;
  color: $color-coolblue;
}

.apply-timeline__sub-heading {
  @include typography_h4;
  margin: 0;
  color: $color-coolblue;
}
