@use "sass:math";

.gallery-block {
  &--filled {
    --gallery-background: var(--theme-background-primary);

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 50%;
      top: 0;
      left: 0;
      background-color: var(--gallery-background);
      z-index: -1;
    }
  }
}

.vacancy-header--form {
  .gallery-block {
    margin-top: grid-size(4);
  }
  .gallery-block--filled:before {
    background-color: transparent;
  }
}

.gallery-block__images {
  display: flex;
  gap: grid-size(3);

  img {
    flex: 0 0 100%;
    border-radius: $border-radius;
  }
}

.gallery-block__image-wrapper {
  position: relative;
  flex: 0 0 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.gallery-block__images--dual {
  .gallery-block__image-wrapper {
    flex: 1 1 50%;
    padding-top: 50%;

    @include media('>medium') {
      flex: 1 0 math.percentage(math.div(5, 12));
      padding-top: math.percentage(math.div(5, 12));
    }
  }

  .gallery-block__image-wrapper + .gallery-block__image-wrapper {
    @include media('>medium') {
      flex: 1 1 math.percentage(math.div(7, 12));
    }
  }
}


.gallery-block__swiper-wrapper {
  @include media('>medium') {
    display: flex;
    flex-direction: row;
    gap: grid-size(3);
  }
}

.gallery-block__swiper-main {
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  border-radius: $border-radius;

  @include media('>small') {
    margin-left: -80px;
    margin-right: -80px;
    padding: 0 80px;
  }

  @include media('>medium') {
    flex: 1 1 math.percentage(math.div(9, 12));
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.gallery-block__swiper-thumbs {
  display: none;

  @include media('>medium') {
    display: block;
    flex: 1 1 math.percentage(math.div(3, 12));
    border-radius: $border-radius;
  }
}

:root {
  --swiper-theme-color: #{$color-hotorange};
  --swiper-pagination-bullet-inactive-color: #{$color-white};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 3px;
}

.gallery-block__slide {
  width: math.percentage(math.div(11, 12));

  @include media('>small') {
    width: 100%;
  }

  .gallery-block__swiper-thumbs & {
    cursor: pointer;
    height: auto;
  }

  &.swiper-slide-thumb-active {
    opacity: 0.8;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
  .video {
    padding-bottom: 62.5%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $color-white;

  &.swiper-button-disabled {
    display: none;
  }

  &:after {
    content: none;
  }

  svg {
    position: absolute;
    width: 12px;
    height: auto;
    top: 50%;
    left: calc(50% + 1px);
    transform: translate(-50%, -50%);
  }
}

.swiper-button-prev {
  @include media('>small') {
    left: 30px;
  }

  @include media('>medium') {
    left: 10px;
  }

  svg {
    left: calc(50%  - 1px);
    transform: translate(-50%, -50%) scaleX(-1);
  }
}

.swiper-button-next {
   @include media('>small') {
    right: 30px;
  }

  @include media('>medium') {
    right: 10px;
  }
}

.swiper-pagination {
  pointer-events: none;
}
