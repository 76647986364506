.cookie-settings {

}

.cookie-settings__title {
  @include typography_h2;
  color: $color-coolblack;
}

.cookie-settings__intro {
  max-width: grid-size(180);
}

.cookie-settings__intro {
  margin-bottom: grid-size(5);
}

.cookie-settings__options {
  .rich-text {
    margin: grid-size(3) 0 grid-size(10);
    ul {
      margin: grid-size(2) 0;
      li {
        margin-bottom: grid-size(1);
      }
    }
  }
}

.cookie-settings__option {
  @include media('>medium') {
    max-width: grid-size(68);
  }
}
