.contact-button {
  right: 20px;
  width: 70px;
  height: 70px;
  transform: translateX(0);
  transition: 0.5s ease transform;

  &--mobile {
    display: block;
    position: absolute;
    top: -10px;
    transform: translate(0, -100%);

    @include media('>medium') {
      display: none;
    }
  }

  &--desktop {
    display: none;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 5;

    @include media('>medium') {
      display: block;
    }
  }

  &--hidden {
    transform: translate(100px, -100%);

    @include media('>medium') {
      transform: translateX(100px);
    }
  }
}

.contact-button__image {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: $color-white;
  background-size: cover;
  border: solid 5px $color-coolblue;
  overflow: hidden;
  cursor: pointer;
}

.contact-button__svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-button__card {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 24px;
  border-radius: 3px 3px 45px 3px;
  background-color: $color-greyblue;

  box-shadow: 0 6px 15px 0 rgba($color-dayblue, 0.1);

  .contact-button--desktop:hover & {
    display: block;
  }

  .contact-button--hidden &,
  .contact-button--hidden:hover & {
    display: none;
  }

  .recruiter-contact-link {
    margin-left: 0;
    margin-bottom: 8px;
    display: inline;
    line-height: 1.5;
    white-space: nowrap;

    div {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.contact-button__title {
  font-family: $font-opensans;
  font-size: 13px;
  white-space: nowrap;
  margin-bottom: 16px;

  span {
    display: inline;
    font-family: $font-opensans--bold;
  }
}

.contact-button__items {
  margin-right: 70px;
}
