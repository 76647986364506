.apply-timeline-step-cards-list {
  display: flex;
  padding: 12px 0 20px 0 !important;
  overflow: hidden;
}

.apply-timeline-step-card {
  position: relative;
  border: 1px solid $color-grey;
  border-radius: 3px;
  box-shadow: inset 0px -2px 0px $color-grey;
  margin-right: 8px;
}

.apply-timeline-step-card__location {
  background-image: url('/static/images/apply-timeline-step-card__location.png');
  background-position: 50% 50%;
}

.apply-timeline-step-card__image {
  height: 223px;
  width: 260px;
  border-radius: 3px 3px 0 0;

  @include media('<small') {
    height: 183px;
    width: 224px;
  }
}

.apply-timeline__video-play {
  position: absolute;
  top: 30%;
  left: 40%;
  z-index: 3;

  width: grid-size(15);
  height: grid-size(15);

  background-image: static-url('images/play.svg');
  background-size: grid-size(15);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
}

.apply-timeline-step-card--clickable {
  cursor: pointer;
  &:hover {
    .procedure__image {
      transform: scale(1.1);
    }
  }
}

.apply-timeline-step-card__link {
  margin: 0;
  padding: 20px;

  .chevron-link {
    font-family: $font-opensans;
    font-size: 14px;
    color: $color-coolblue;

    path {
      fill: $color-coolblue;
    }
  }
}
