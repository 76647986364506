.checkmark-block {
  background-color: $color-greyblue;
}

.checkmark-block__inner.container__inner {
  .page-content & {
    background-color: transparent;
  }
}

.checkmark-block__intro {
  margin-bottom: grid-size(6);

  h2 {
    @include typography_h2;
    color: $color-coolblack;
  }
}

.checkmark-block__list {
  margin-bottom: grid-size(6);

  @include media('>large') {
    margin-bottom: grid-size(9);
  }
}

.checkmark-block__item {
  position: relative;
  padding-left: grid-size(5);
  margin-bottom: grid-size(3);

  @include media('>large') {
    margin-bottom: grid-size(6);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    transform: translate(-50%, 50%);
    background-image: static-url('images/check-blue.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  h3 {
    @include typography_h3;
    font-size: 14px;
    color: $color-coolblack;

    @include media('>medium') {
      font-size: 15px;
    }
  }
}
