.salary-calculator-popover {
  padding: 0 grid-size(5);
}

.salary-calculator-block {
  position: relative;
  background-color: $color-greyblue;
  margin-bottom: grid-size(7);
  display: block;
  visibility: visible;

  @include media('>medium') {
    margin-bottom: grid-size(18);
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: grid-size(12);
    background: linear-gradient(0deg, rgba(40, 93, 171, 0.3) 0%, rgba(40, 93, 171, 0) 60.38%);
  }
}

.salary-calculator-block__inner {
  overflow: hidden;
  transition: max-height 1s ease-in-out;

  .page-content & {
    background-color: transparent;
  }
}

.salary-calculator-block__button {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  opacity: 1;
  transition: opacity 0.2s linear 0.2s;
  z-index: 1;
}

.salary-calculator-block__title {
  @include typography__title;
  font-family: $font-dobraslab-book;
  margin: grid-size(3) 0;

  @include media('>small') {
    margin-bottom: grid-size(9);
  }
}

.salary-calculator-block__wrapper {
  margin-bottom: grid-size(12);
}

.salary-calculator {
  position: relative;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  transition: transform 0.5s ease;

  @include media('>small') {
    max-width: 394px;
  }

  &--flipped {
    transform: rotateY(180deg);
  }

  .salary-calculator-block & {
    margin: 0 auto;
  }
}

.salary-calculator__front,
.salary-calculator__back {
  padding: 20px;
  border-radius: 20px;
  transform: rotateX(0deg);
  backface-visibility: hidden;
}

.salary-calculator__front {
  position: relative;
  background-color: $color-coolblue;
}

.salary-calculator__back {
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  background-color: $color-white;
}

.salary-calculator__close {
  position: absolute;
  right: 8px;
  top: -40px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: $color-white;
  border: none;
  cursor: pointer;
  backface-visibility: hidden;

  @include media('>small') {
    right: -16px;
    top: -16px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $color-coolblue;
    backface-visibility: hidden;
  }

  .salary-calculator__back & {
    background-color: $color-greyblue;
  }
}

.salary-calculator__screen {
  width: 100%;
  margin-bottom: 24px;
  border-radius: 5px;
  background-color: $color-greyblue;

  &--hidden {
    background-color: $color-silver;

    .salary-calculator__screen-result {
      background-color: $color-grey;
    }

    .salary-calculator__explain-link,
    .salary-calculator__euro,
    .salary-calculator__value,
    .salary-calculator__unit,
    .salary-calculator__screen-message {
      opacity: 0;
    }
  }
}

.salary-calculator__screen-result {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: $color-white;
}

.salary-calculator__euro,
.salary-calculator__value {
  color: $color-coolblue;
  font-family: $font-avenir;
  font-size: 70px;
  line-height: 57px;
}

.salary-calculator__euro {
  display: block;

  svg {
    width: auto;
    height: 55px;
  }
}

.salary-calculator__error {
  display: none;
  color: $color-coolblue;
  font-family: $font-dobraslab-book;
  font-size: 19px;
  line-height: 21px;
  max-width: 65%;
  margin-top: 16px;
}

.salary-calculator__value {
  text-align: right;
}

.salary-calculator__unit {
  display: block;
  color: $color-coolblue;
  font-family: $font-dobraslab-book;
  font-size: 15px;
  line-height: 17px;
  text-align: right;
}

.salary-calculator__screen-message {
  display: block;
  color: $color-hotorange;
  font-family: $font-dobraslab-book;
  font-size: 15px;
  line-height: 17px;
  text-align: right;
}

.salary-calculator__explain-link {
  display: block;
  padding: 8px 0;
  color: $color-dayblue;
  font-family: $font-opensans--bold;
  font-size: 13px;
  line-height: 1.1;
  text-align: center;
  cursor: pointer;

  svg {
    margin-left: 6px;
    vertical-align: middle;
    height: 10px;
    width: auto;
  }

  .salary-calculator__back & {
    color: $color-coolblue;
    text-align: left;
    margin-bottom: 32px;
    padding: 0;

    svg {
      transform: scaleX(-1);
      margin-left: 0;
      margin-right: 6px;
    }
  }
}

.salary-calculator__button-group {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 24px;
}

.salary-calculator__group-heading {
  color: $color-white;
  font-family: $font-opensans--bold;
  font-size: 15px;
  margin-bottom: 16px;
}

.salary-calculator__button-input {
  display: none;
}

.salary-calculator__button-label {
  display: block;
  width: 100%;
  padding: 15px 0px;
  border-radius: 30px;
  font-family: $font-opensans--bold;
  font-size: 13px;
  line-height: 1.2;
  text-align: center;
  color: $color-coolblue;
  background-color: $color-white;
  border: solid 1px $color-grey;
  box-shadow: inset 0px -2px 0px $color-grey;
  cursor: pointer;

  .salary-calculator__button-input:hover + & {
    border-color: $color-silver;
    box-shadow: inset 0 -2px 0px 0 $color-silver;
  }

  .salary-calculator__button-input:active + & {
    box-shadow: none;
  }

  .salary-calculator__button-input:checked + & {
    color: $color-white;
    background-color: $color-dayblue;
    border-color: $color-maritimeblue;
    box-shadow: inset 0px -2px 0px $color-maritimeblue;
  }
}

.salary-calculator__apply-button {
  width: 100%;
  margin-bottom: 16px;
  display: block;

  .salary-calculator__back & {
    margin-top: 32px;
  }
}

.salary-calculator__disclaimer {
  color: #ffffff;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: grid-size(2);

  + p {
    margin-bottom: 0;
  }
}

.salary-calculator-block__wrapper
  > .salary-calculator
  > .salary-calculator__front
  > .salary-calculator__apply-button.button.button--green,
.salary-calculator-block__wrapper
  > .salary-calculator
  > .salary-calculator__back
  > .salary-calculator__apply-button.button.button--green {
  // Hide Apply button on mobile, but for the page section only - this
  // should not affect the popover version of the salary calculator
  display: none;

  @include media('>small') {
    display: block;
  }
}
