.planner__content {

}

.planner__message {
  padding: 10px;
  border-radius: $border-radius;
  background-color: $color-greyblue;
}

.planner__row {
  margin-top: 20px;
}

.planner__row--submit,
.planner__row--time {
  display: none;
}

.planner__row--submit {
 .button {
   width: 100%;
 }
}

.planner__row--visible {
  display: block;
}

.planner__slots {
  display: none;
  flex-wrap: wrap;

  input, label {
    cursor: pointer;
    &.planner__radio-label--disabled {
      cursor: default;
    }
  }
}

.planner__slots--visible {
  display: flex;
}

.planner__day-slots {
  display: flex;
}

.planner__label {
  @include typography_h4;
  color: $color-text;
  display: block;
  margin-bottom: 10px;
}

.planner__select {
  @include typography_p;

  cursor: pointer;

  padding: 9px 12px;
  border: 1px solid $color-grey;
  border-radius: $border-radius;
  width: 100%;

  appearance: none;
  background-color: #fff;
  background-image: static-url('images/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 17px auto;

  &:focus {
    outline: none;
  }
}
.planner__radio {
  display: none;
}
.planner__radio-label {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

  &:before {
    content: '';
    border: 1px solid $color-grey;
    background-color: $color-white;
    width: grid-size(4);
    height: grid-size(4);
    position: absolute;
    left: 0;
    top: calc(50% - #{grid-size(2)});
    border-radius: 100%;
  }
}

.planner__radio:checked + .planner__radio-label {
  &:before {
    background: white none;
    border: 5px solid $color-coolblue;
  }
}

.planner__radio-label--disabled {
  cursor: not-allowed;
  color: $color-raingrey;
}
