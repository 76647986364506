.game {
  height: 100vh;
  position: relative;
  display: none;
  @include media ('>large') {
    display: block;
  }
  .g__container {
    transform: none;
  }
}

.game__start-window {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: grid-size(44);
  width: grid-size(118);
  border-radius: $border-radius;
  background-color: $color-dayblue;
  padding: grid-size(7);
}
.game__start-window--hidden {
  opacity: 0;
  transition: opacity .2s linear;
  pointer-events: none;
}

.game__start-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.game__start-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.game__back-button {
  position: absolute;
  z-index: 1000;
  top: grid-size(4);
  right: grid-size(4);
  height: grid-size(10);
  width: grid-size(10);
  border-radius: 50%;
  background-color: $color-coolblue;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    transform: rotate(270deg);
  }
}

.game__start-title--fact {
  @include typography_fact;
  color: $color-white;
}
.game__start-title--slogan {
  @include typography_slogan;
  color: $color-arcticblue;
}
