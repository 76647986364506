.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: $border-radius;
  background-size: cover;
  background-position: center;

  &:hover {
     cursor: pointer;

    .video__play-button--enabled {
      cursor: pointer;
      transform: translate(-50%, -50%) scale(1.2);
    }

    .video__overlay {
      opacity: 0.3;
    }
  }
}

.video__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.video__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.1;
  pointer-events: none;

  transition: opacity .2s linear;
}

.video__player {
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s linear;
}

.video__play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: grid-size(24);
  height: grid-size(24);
  transform: translate(-50%, -50%);
  text-indent: -100vw;
  background-image: static-url('images/play.svg');
  background-size: grid-size(24);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;

  opacity: .3;
  pointer-events: none;
  transition: opacity .2s linear, transform .2s linear;
}

.video__play-button--enabled {
  opacity: 1;
}

.video--is-playing {
  .video__player {
    opacity: 1;
    pointer-events: all;
  }

  .video__play-button {
    opacity: 0;
    pointer-events: none;
  }
}
