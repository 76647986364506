.card-block {
  .card-block__inner {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
  }

  .card-block__tags {
    &:after {
      content: '';
      display: block;
      min-width: grid-size(2);
      height: 47px;
      @include media('>small') {
        min-width: grid-size(13);
      }
      @include media('>medium') {
        min-width: grid-size(18);
      }
      @include media('>large') {
        min-width: grid-size(5);
      }
    }
  }

  .card-block__tag {
    border: 1px solid $color-silver;
    border-radius: $border-radius;
    padding: grid-size(3) grid-size(5);
    margin: 0 grid-size(3) grid-size(3) 0;
    color: $color-coolblue;
    cursor: pointer;
    white-space: nowrap;

    &--active {
      color: $color-text;
      border-color: $color-coolblue;
      border-width: 2px;
      background-color: #F2F7FC;
    }
  }

  .slider__inner {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include media('>extra-large') {
      max-width: grid-size(250);
    }
  }

  .slider__button {
    top: -60px;
    height: 47px;
  }

  .card {
    margin-bottom: grid-size(8);
    flex: 0 0 100%;
  }
  .card--normal {
    @include media('>medium') {
      flex: 0 0 calc(50% - #{grid-size(5)});
    }
  }
  .card--wide {
  }
  .card--hidden {
    display: none;
  }

  .card-block__button-row {
    flex: 0 0 100%;
  }
  .card-block__loadmore--hidden {
    display: none;
  }
}
