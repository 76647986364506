.card {
  @include button-border;
  padding: grid-size(3) grid-size(3);
  @include media('>small') {
    padding: grid-size(3) grid-size(6);
  }
  a:hover, a:active {
    text-decoration: none;
  }
}

.card--disabled {
  &:hover {
    border: 1px solid $color-grey;
    border-radius: $border-radius;
    box-shadow: inset 0 -2px 0 0 $color-grey;
  }
}

.card__image-container {
  padding-bottom: 51.3%;
  transition: $image-transition;
}
.card__image {
  overflow: hidden;
}

.card__title {
  @include typography_h3;
  color: $color-coolblue;
  margin-top: 0;
}

.card__text {
  color: $color-text;
}

.card__meta {
  margin-top: grid-size(3);
  background-position: center left;
  background-repeat: no-repeat;
  color: $color-text;
  padding-left: grid-size(6);
  padding-right: grid-size(7);
}

.card__meta--contract {
  background-image: static-url('images/clock.svg');
  background-size: 14px;
}

.card__link {
  margin-top: grid-size(2);
  background-position: center right 3px;
  background-repeat: no-repeat;
  padding-right: grid-size(6);
  background-image: static-url('images/chevron-right.svg');
  background-size: 8px;
}

.card--overview {
  padding: 0;
  overflow: hidden;
  &:hover {
    .card__image-container {
      transform: scale(1.1);
    }
  }
  .card__image-container {
    background-repeat: no-repeat;
    background-size: cover;
  }
  a {
    display: block;
    height: 100%;
  }
}
.card__content {
  padding: grid-size(5);
}

.card--wide {
  border: none;
  position: relative;

  .card__content {
    background-color: $color-white;
    position: absolute;
    left: grid-size(5);
    bottom: grid-size(5);
    border-radius: $border-radius;
    max-width: calc(100% - #{grid-size(10)});
    @include media('>small') {
      max-width: 80%;
    }
    @include media('>medium') {
      max-width: 60%;
    }
  }
  .card__title {
    font-size: 18px;
    @include media('>small') {
      font-size: 23px;
    }
    font-family: $font-dobraslab-book;
    margin-bottom: grid-size(5);
  }
  .card__text {
    display: none;
  }
  .card__link {
    font-family: $font-opensans--bold;
    color: $color-dayblue;
  }
}

.card--normal {
  .card__title {
    font-family: $font-opensans--bold;
    @include media('>small') {
      font-size: 18px;
    }
    margin-bottom: grid-size(3);
  }
  .card__link {
    display: none;
  }
}
