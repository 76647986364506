.nothing-found {
  hr {
    border: none;
    border-top: 1px solid $color-brightgrey;
    margin: 12px 0;
  }
}

.nothing-found__link {
  color: $color-coolblue;
  svg path {
    fill: $color-coolblue;
  }
}
