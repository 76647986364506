.salary-raise {
  width: 100%;

  svg {
    margin-left: 10px;
    width: 100%;
  }
}

.salary-raise--folded-out {
  .salary-raise__title {
    &:before {
      top: 5px;
      transform: rotate(90deg);
    }
  }

  .salary-raise__content {
    display: block;
    margin-top: 24px;
  }
}

.salary-raise__title {
  position: relative;
  margin-top: 20px;
  font-family: $font-opensans--bold;
  font-size: 13px;
  line-height: 18px;
  color: $color-coolblue;

  padding-left: 15px;

  @include media(">small") {
    cursor: pointer;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 4px;
    content: '';
    display: block;
    width: 11px;
    height: 11px;
    background-position: center right 3px;
    background-repeat: no-repeat;
    background-image: static-url('images/chevron-right.svg');
    background-size: 7px;
  }
}

.salary-raise__content {
  display: none;
}

.salary-raise__intro {
  margin-top: 8px;
  margin-bottom: 24px;
}

.salary-raise__result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  padding: 15px;
  border-radius: $border-radius;
  background-color: $color-greyblue;

  @include media(">small") {
    padding: 18px 23px;
  }
}

.salary-raise__result-years {
  b {
    font-family: $font-opensans--bold;
    line-height: 22px;
  }
  .salary-raise__result-row {
    font-size: 18px;
  }
  .salary-raise__result-row:nth-child(2) {
    font-size: 15px;
  }
}

.salary-raise__result-salary {
  text-align: right;
  b {
    font-family: $font-opensans--bold;
    font-size: 28px;
    line-height: 19px;
  }
}
