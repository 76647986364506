.usp-block {
  margin-top: grid-size(6);

  .container__inner {
    padding-top: 0;
    padding-bottom: 0;
    @include media('<=medium') {
      padding: 0;
      max-width: none;
    }
  }
}

.usp-block__list {
  background-color: $color-greyblue;
  padding: grid-size(2) 0;
  border-radius: $border-radius;
  position: relative;
  min-height: grid-size(11);
  overflow: hidden;

  @include media('>medium') {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: grid-size(3) grid-size(6)
  }
}

.usp-block__item {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: grid-size(2) 0;
  font-size: 13px;
  line-height: 2;
  transform: translateX(100%);
  transition: transform 1s ease-in-out;
  opacity: 0;
  text-align: center;

  @include media('>medium') {
    text-align: left;
    position: relative;
    line-height: 1.5;
    padding: 0 grid-size(2) 0 grid-size(6);
    width: auto;
    opacity: 1;
    transform: none;
  }

  &--visible {
    transform: translateX(0);
    opacity: 1;
  }
  &--hidden {
    transform: translateX(-100%);
    opacity: 1;
  }
}

.usp-block__item-text {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    display: block;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: grid-size(4);
    height: grid-size(4);
    background-image: static-url('images/check-green.svg');
    background-size: 66%;
    background-repeat: no-repeat;
    background-position: center;
  }
}
