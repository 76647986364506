.code-highlights {
  display: flex;
  flex-direction: column;
}

.code-highlights__intro {
  flex: 1;
  order: 2;

  @include media(">medium") {
    order: 1;
  }

  .code-block--highlight-active & {
    display: none
  }
}

.code-highlights__item {
  display: none;
  flex: 1;
  order: 2;

  @include media(">medium") {
    order: 1;
  }
}

.code-highlights__item--active {
  display: block;
}

.code-highlights__title {
  font-family: $font-opensans--bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
}

.code-highlights__buttons {
  display: flex;
  justify-content: space-between;
  order: 1;
  margin-bottom: 20px;

  @include media(">medium") {
    order: 2;
    margin-bottom: 0;
  }
}

.code-highlights__button {
  font-family: $font-opensans--bold;
  background-size: 8px;
  background-repeat: no-repeat;
  transition: background-position 200ms, opacity 200ms;
  @include media(">medium") {
    &:hover {
      cursor: pointer;

      &.code-highlights__prev {
        background-position: center left 3px;
      }

      &.code-highlights__next {
        background-position: center right 3px;
      }
    }
  }
}

.code-highlights__prev {
  background-position: center left 6px;
  padding-left: grid-size(5);
  background-image: static-url('images/chevron-left-white.svg');
}

.code-highlights__next {
  text-align: right;
  background-position: center right 6px;
  padding-right: grid-size(5);
  background-image: static-url('images/chevron-right-white.svg');
}

.code-highlights__button--hidden {
  opacity: 0;
  pointer-events: none;
}
