.alternative-vacancies {
  overflow: hidden;

  @include media('>medium') {
    overflow: visible;
  }
}

.alternative-vacancies__inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  margin-left: grid-size(-10);
  margin-right: grid-size(-10);
  padding-left: grid-size(10);
  padding-right: grid-size(10);
  padding-bottom: grid-size(4);
  scroll-padding-left: grid-size(10);
  scroll-padding-right: grid-size(10);

  position: relative;
  animation: bounce 1.5s;
  animation-iteration-count: 1;
  animation-delay: 1s;
  animation-timing-function: cubic-bezier(.22, .61, .36, 1);

  @include media('>small') {
    margin-left: -64px;
    margin-right: -64px;
    padding-left: 64px;
    padding-right: 64px;
    scroll-padding-left: 64px;
    scroll-padding-right: 64px;
  }

  @include media('>medium') {
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: visible;
    animation: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    scroll-padding-left: 0;
    scroll-padding-right: 0;
  }
}

@keyframes bounce {
  0% {
    left: 0px;
  }

  25% {
    left: -20px
  }

  50% {
    left: 0px;
  }

  75% {
    left: -20px;
  }

  100% {
    left: 0px;
  }
}

.alternative-vacancies__heading {
  display: flex;
  margin-bottom: grid-size(8);

  @include media('>medium') {
    display: flex;
    flex: 1 0 100%;
  }

  .tooltip {
    flex: 0 0 calc((100% - 12px) / 2);
    margin-right: grid-size(3);

    @include media('>medium') {
      flex: 0 0 360px;
      margin-right: grid-size(12);
    }
  }
}

.alternative-vacancies__mobile-items {
  display: flex;
  flex-wrap: nowrap;
  gap: grid-size(3);

  @include media('>medium') {
    flex: 0 0 384px; // 360px item width + 24px padding
  }
}

.alternative-vacancies__slider {
  display: none;

  @include media('>medium') {
    display: block;
    position: relative;
    flex: 1 1 0%;
    background-color: $color-antarcticblue;
    overflow: hidden;
    margin-right: calc(-100vw / 2 + (100% - 32px) / 2 - 32px);

    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    &:after {
      content: '';
      pointer-events: none;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100vw / 2 - (100% - 32px) / 2 + 32px);
      height: 100%;
      z-index: 10;
      background: linear-gradient(90deg, rgba($color-white, 0), rgba($color-white, 0.5));
    }
  }

  @include media('>large') {
    margin-right: calc(-100vw / 2 + 1000px / 2 - 32px);

    &:after {
      width: calc(100vw / 2 - 1000px / 2 + 32px);
    }
  }

  .slider__button {
    z-index: 20;
    top: -480px;
  }

  .slider__button--prev {
    left: 20px;
  }

  .slider__button--next {
    right: 20px;
  }

  .slider__item--hidden {
    opacity: 1;
  }
}

.alternative-vacancies__slider-inner {
  @include media('>medium') {
    display: flex;
    overflow-x: scroll;
    gap: grid-size(6);
    padding: grid-size(6);
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scroll-padding-left: grid-size(6);
    scroll-padding-right: grid-size(6);
  }
}

.alternative-vacancies__filler {
  flex: 0 0 20px;

  @include media('>medium') {
    flex: 0 0 calc(100% - 360px * 2 - 48px);
  }
}
