.nav-list {
  --nav-list-background: var(--theme-background-primary);

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 5;

  background-color: $color-white;
  width: 85%;
  max-width: 320px;

  pointer-events: none;
  transform: translateX(-100%);
  transition: transform 300ms;

  font-size: 14px;
  line-height: 14px;

  @include media($nav-header-flip) {
    display: none;
    visibility: hidden;
  }
}

.nav-list--open {
  pointer-events: all;
  transform: translateX(0);
}

.nav-list--show-languages {
  overflow: hidden;

  .nav-list__navigation {
    transition: transform 300ms, opacity 300ms;
    transform: translateX(-20%);
    opacity: 0;
    pointer-events: none;
  }

  .nav-list__language-switch {
    transition: transform 300ms, opacity 300ms;
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
  }
}

.nav-list__header {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: #{map-get($nav-header-height, small) + 2};
  background-color: var(--nav-list-background);
}

.nav-list__title {
  font-size: 16px;
  font-family: $font-opensans--bold;
  color: $color-white;
}

.nav-list__button {
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;

  height: 50px;
  width: 50px;

  svg {
    display: block;
    fill: $color-white;
    margin-left: 15px;
  }
}

.nav-list__navigation {

}

.nav-list__links {
  margin-top: 12.5px;
}

.nav-list__link-section {
  border-top: 1px solid $color-grey;
  margin-top: 12.5px;
  padding-top: 12.5px;
}

.nav-list__language-switch {
  position: absolute;
  top: map-get($nav-header-height, small);
  left: 0;
  right: 0;

  transform: translateX(20%);
  opacity: 0;
  pointer-events: none;
}

.nav-list__language-switch-button {
  border-top: 1px solid $color-grey;
  padding: 25px $margin-medium;
  margin-top: 12.5px;
}

@mixin menu-icon {
  background-repeat: no-repeat;
  background-position: right grid-size(2) center;
  padding-right: grid-size(10);
}

.nav-list__link {
  display: block;
}

.nav-list__link-text {
  display: inline-block;
  padding: 12.5px $margin-medium;

  &--relocation {
    @include menu-icon;
    background-image: static-url('images/relocation.svg');
  }

  &--museum {
    @include menu-icon;
    background-image: static-url('images/museum.svg');
  }
}
