.slide__wrapper {
  position: relative;
  overflow: hidden;
  height: 200px;
  margin-right: 12px;
  border-radius: $border-radius;
}

.slide__link {
  width: 100%;
  height: 100%;
  display: flex;
  &:hover {
    text-decoration: none;
    .slide__image {
      transform: scale(1.1);
      transition: $image-transition;
    }
  }
}

.slide__image {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: $image-transition;
}

.slide__title {
  z-index: 1;
  padding: 0 grid-size(5);
  min-width: grid-size(37);
  margin: 0 auto grid-size(4);
  text-align: center;
  line-height: grid-size(11);
  align-self: flex-end;
  border-radius: 22px;
  background-color: $color-white;
  color: $color-coolblue;
  font-family: $font-opensans--bold;
  text-decoration: none;
}
