@keyframes spin {
  0% {
    transform: rotateZ(0deg)
  }
  42% {
    transform: rotateZ(180deg)
  }
  100% {
    transform: rotateZ(360deg)
  }
}

@keyframes squash {
  0%, 50%, 100% {
    transform: scaleX(1)
  }
  21%, 71% {
    transform: scaleX(1.5)
  }
  45%, 95% {
    transition-timing-function: ease-in-out
  }
}

@keyframes change-color {
  0%, 45%, 100% {
    background: #0090e3
  }
  50%, 95% {
    background: #f60
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4rem;
  height: 4rem;
  margin: -2rem 0 0 -2rem;
  border: .8rem solid #eee;
  border-radius: 50%;
  animation: spin .7s linear 0s infinite;

  &:before {
    content: '';
    position: absolute;
    top: -.8rem;
    left: .8rem;
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    background-color: #f60;
    animation: squash 1.4s linear 0s infinite, change-color 1.4s linear 0s infinite;
  }
}
