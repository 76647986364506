.cookie-bar {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity 300ms;
}

.cookie-bar--visible {
  opacity: 1;
  pointer-events: auto;
}

.cookie-bar--extended {
  @include media('>medium') {
    .cookie-bar__panel {
      width: 800px;
    }
    .cookie-bar__content {
      flex: 0 0 510px;
    }
  }
}

.cookie-bar--options-visible {
  .cookie-bar__options {
    visibility: visible;
    display: inline-flex;
  }

  .cookie-bar__change {
    display: none;
    visibility: hidden;
  }

  .cookie-bar__accept-selected {
    display: flex;
    visibility: visible;
  }

  .cookie-bar__text {
    display: none;
    visibility: hidden;
  }

  .cookie-bar__extended-text {
    display: block;
    visibility: visible;
  }
}

.cookie-bar__inner {
  position: relative;
  width: 100%;
  height: 100%;
  @include media('>medium') {
    padding-top: grid-size(2);
    padding-bottom: grid-size(2);
    display: flex;
    justify-content: space-between;
  }
}

.cookie-bar__panel {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  transform: translate(-50%, -50%);
  background-color: $color-white;
  border-radius: $border-radius;

  @include media('>medium') {
    width: grid-size(175);
    min-height: grid-size(90);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.cookie-bar__content {
  flex: 0 0 410px;
  padding: grid-size(7);
  @include media('>medium') {
    padding: grid-size(8);
  }
}

.cookie-bar__image {
  display: none;

  @include media('>medium') {
    display: block;
    position: relative;
    flex: 1 1;
    margin: grid-size(10) grid-size(5) 0;

    img {
      width: auto;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.cookie-bar__fact {
  @include typography_fact;
  @include media('>small') {
    font-size: 28px;
    line-height: 24px;
  }
}

.cookie-bar__slogan {
  @include typography_slogan;
  @include media('>small') {
    font-size: 23px;
    line-height: 23px;
  }
}

.cookie-bar__extended-text,
.cookie-bar__text {
  max-width: grid-size(180);
  margin-top: grid-size(3);
  font-size: 13px;
}

.cookie-bar__extended-text {
  display: none;
  visibility: hidden;
}

.cookie-bar__options {
  visibility: hidden;
  display: none;

  flex-direction: column;
  margin: grid-size(4) 0;
  gap: grid-size(2);

  @include media(">medium") {
      flex-direction: row;
  }
}

.cookie-bar__option-input {
  display: none;
}

.cookie-bar__option-label {
  position: relative;
  padding-left: grid-size(5);

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    border-radius: $border-radius;
    border: 1px solid $color-grey;
  }
}

.cookie-bar__option-input:checked + .cookie-bar__option-label {
  &:after {
    border-radius: $border-radius;
    background-color: $color-coolblue;
    background-image: static-url('images/check.svg');
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.cookie-bar__option-input:disabled + .cookie-bar__option-label {
  &:after {
    background-color: $color-grey;
  }
}


.cookie-bar__buttons {
  display: flex;
  column-gap: grid-size(3);
  margin-top: grid-size(3);

  & > .button {
    flex: 1;
    text-align: center;
  }
}

.cookie-bar__icon {
  fill: $color-white;
  width: 16px;
  height: 14px;
  margin-right: grid-size(2);
  transform: translateY(2px);
}

.cookie-bar__accept-all {
  width: 100%;
  text-align: center;
}

.cookie-bar__accept-selected {
  display: none;
  visibility: hidden;
}

.cookie-bar__change {
  display: flex;
}

.cookie-bar__change,
.cookie-bar__accept-selected {
  justify-content: center;
  flex-direction: column;
}
