.subform {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.subform__form--hidden,
.subform__confirmation--hidden {
  display: none;
}

.subform__form {
  width: 100%;
}

.subform__confirmation {
  width: 100%;

  p + .subform__subtext {
    margin-top: 16px;
  }

  ul {
    background-color: #F2F7FC;
    padding: 20px;
    list-style-type: none;
    margin-top: 16px;
    margin-bottom: 16px;

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 7px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:before {
        content: '';
        display: inline-block;
        background-image: static-url('images/check-green.svg');
        background-repeat: no-repeat;
        background-size: 11px;
        width: 11px;
        height: 11px;

        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
}

.subform__subtext {
  color: #666666;
  font-size: 13px;
}
