.recurring-event-popover {
  position: relative;
  height: 100%;
}

.recurring-event-popover__inner {
  padding: grid-size(3) grid-size(5);
  @include media('<=small') {
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(76px + env(safe-area-inset-bottom));
    width: 100%;
  }
}

.recurring-event-popover__button-row {
  padding: grid-size(6) grid-size(5);
  button {
    width: 100%;
  }
  @include media('<=small') {
    position: absolute;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    width: 100%;
  }
}

.recurring-event-card-list {
}

.recurring-event-card-list__item {
  margin-bottom: grid-size(2);
  &:last-child {
    margin-bottom: 0;
  }
}

.recurring-event-card {
  @include button-border;
  box-shadow: none;
  position: relative;

  a:hover,
  a:active {
    text-decoration: none;
  }

  &:hover {
    box-shadow: none;
  }
}

.recurring-event-card__inner {
  margin-left: 40px;
  padding-bottom: grid-size(3);
}

.recurring-event-card__radio:checked {
  & + .recurring-event-card__title {
    &:after {
      transform: rotate(180deg);
    }
  }
  & + .recurring-event-card__title + .recurring-event-card__inner {
    display: block;
  }
}

.recurring-event-card__radio {
  position: absolute;
  top: grid-size(3);
  left: grid-size(3);
  margin: 0;
  margin-top: 3px;
}

.recurring-event-card__title {
  @include typography_h3;
  color: $color-coolblue;
  margin: 0;
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: grid-size(3) grid-size(3) grid-size(1) grid-size(3);

  &:before {
    top: 28px !important;
  }

  &:after {
    position: absolute;
    //transition: transform 300ms;
    content: '';
    right: grid-size(3);
    top: grid-size(4);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 15px;
    width: 15px;
  }
}

.recurring-event-card__text {
  color: $color-text;
  padding: grid-size(3) grid-size(3) grid-size(1) grid-size(3);
}
