.page-header {
  --page-header-background: var(--theme-background-primary);

  .page-header___background {
    background-color: var(--page-header-background);
    width: 100%;
    z-index: -1;
    margin-bottom: -46px;
    height: 152px + map-get($nav-header-height, small);

    @include media('>small') {
      padding-top: map-get($nav-header-height, small);
      height: 220px + map-get($nav-header-height, medium);
      margin-bottom: -94px;
    }

    @include media($nav-header-flip) {
      padding-top: map-get($nav-header-height, medium);
      height: 260px + map-get($nav-header-height, medium);
    }

    @include media('>large') {
      height: 340px + map-get($nav-header-height, medium);
      margin-bottom: -102px;
    }
  }

  .content-page & {
    margin-bottom: grid-size(3);

    @include media('>small') {
      margin-bottom: grid-size(6);
    }
  }
}

.page-header--back-nav {
  .page-header___background {
    margin-bottom: -69px;
    @include media('>small') {
      margin-bottom: -117px;
    }
    @include media('>large') {
      margin-bottom: -125px;
    }
  }
}

.page-header--fact-slogan {
  @include media('<=small') {
    .page-header___background {
      margin-bottom: -58px;
    }
  }
}

.page-header--fact-slogan.page-header--back-nav {
  @include media('<=small') {
    .page-header___background {
      margin-bottom: -78px;
    }
  }
}

.page-header--large {
  .page-header___background {
    height: 180px + map-get($nav-header-height, small);


    @include media('>small') {
      height: 272px + map-get($nav-header-height, medium);
    }

    @include media('>large') {
      height: 500px  + map-get($nav-header-height, medium);
    }
  }
}

.page-header--xlarge {
  .page-header___background {
    height: 272px + map-get($nav-header-height, small);

    @include media($nav-header-flip) {
      height: 360px + map-get($nav-header-height, medium);
    }

    @include media('>large') {
      height: 520px + map-get($nav-header-height, medium);
    }
  }

  @include media('>extra-large') {
    .page-header__inner {
      max-width: 1248px;
    }
  }
}

.page-header__inner {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.page-header__image {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.page-header__video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.page-header__video-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;

  @include media('>small') {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    //height: auto; // vertically center video
  }
}

.page-header__video-element {
  height: 100%;
  @include media('>small') {
    height: auto;
    width: 100%;
  }
}

.page-header .page-header__inner {
  padding-top: map_get($container-padding, small);
  background-color: $color-white;
  padding-bottom: 0;
  position: relative;
  z-index: 3;

  @include media('>small') {
    padding-top: map_get($container-padding, medium);
  }

  @include media('>large') {
    padding-top: map_get($container-padding, large);
  }
}

.page-header__publish-date {
  font-size: 14px;
  color: $color-raingrey;
  line-height: 21px;
  transform: translateY(-20px);

  svg {
    width: 16px;
    height: auto;
    display: inline-block;
    fill: $color-raingrey;
    margin-right: $margin-extratiny;
    vertical-align: sub;
  }
}

.page-header__back {
  transform: translateY(-4px);
  @include media('>small') {
    transform: translateY(-12px);
  }
  @include media('>large') {
    transform: translateY(-20px);
  }
}

.page-header__link {
  position: relative;
  padding-left: grid-size(4);
  font-family: $font-opensans--semibold;

  &:before {
    content: '';
    background-image: static-url('images/chevron-left.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: grid-size(3);
    height: grid-size(3);
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.page-header__title {
  @include typography__title;
}

.page-header__title--fact {
  @include typography_fact;
}

.page-header__title--slogan {
  @include typography_slogan;
}

.page-header__intro {
  margin: $margin-small 0;
  max-width: 720px;
  margin-right: auto;
  @include typography_intro;
}
