.sticky-bar {
  position: fixed;
  width: 100%;
  z-index: 5;
  transform: translateY(calc(-100% - 12px));
  transition: transform 300ms ease-in;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
}

.sticky-bar--top {
  top: 0;
}

.sticky-bar--visible {
  transform: translateY(0);
}

.sticky-bar--planner {
  background-color: white;
  padding: 10px 20px;
  text-align: center;
  bottom: 0;
  transform: translateY(100%);

  @include media(">small") {
    display: none;
  }

  &.sticky-bar--visible {
    @include media("<=small") {
      transform: translateY(0);
    }
  }

  .button {
    width: 100%;
  }
}
