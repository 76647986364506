.nav-header {
  --nav-header-background: var(--theme-background-primary);
  --nav-link-hover: var(--theme-hover);
  --nav-link-active: var(--theme-active);
  --nav-border: var(--theme-border);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  border-bottom: 2px solid;
  border-color: var(--nav-border);

  .container {
    background-color: var(--nav-header-background);
  }

  .vertical-scrollbar.no-overflow & {
    margin-right: 15px;
  }
}

.nav-header--float {
  position: fixed;
  transform: translateY(-100%);
}

.nav-header--anim {
  transition: transform 300ms;
}

.nav-header--sticky {
  transform: translateY(0);
}

.nav-header--xlarge {
  @include media('>extra-large') {
    .container__inner {
      max-width: 1248px;
    }
  }
}

.nav-header__search-backdrop {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 200vh;
  opacity: 0;
  background-color: $color-dayblue;
  transition: opacity .3s linear;

  &--open {
    display: block;
    opacity: .15;
  }
}

.nav-header--search {
  @include media($nav-header-flip-reverse) {
    .nav-header__right-align {
      display: block;
      flex: 1;
    }
    .nav-header__account-mobile-button,
    .nav-header__button,
    .nav-header__logo,
    .nav-header__notification {
      display: none;
      visibility: hidden;
    }
  }

  @include media($nav-header-flip) {
    .nav-header__link--museum {
      display: none;
      visibility: hidden;
    }
  }
}

.nav-header__inner {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  height: map-get($nav-header-height, small);

  @include media($nav-header-flip) {
    justify-content: space-between;
    padding: 0;
    height: map-get($nav-header-height, medium);
  }
}

.nav-header__button {
  flex: 1;

  svg {
    display: block;
  }

  @include media($nav-header-flip) {
    display: none;
    visibility: hidden;
  }
}

.nav-header__logo {
  svg {
    display: block;
    height: 50px;
  }

  @include media($nav-header-flip) {
    align-self: flex-start;

    svg {
      display: block;
      height: map-get($nav-header-height, medium);
      width: 109px;
    }
  }
}

.nav-header__large-logo {
  display: none;
  visibility: hidden;

  position: absolute;
  z-index: 11;
  left: -20px;
  top: -48px;

  svg {
    display: block;
    height: 221px;
    width: 218px;
  }
}

.nav-header__left-align {
  @include media($nav-header-flip) {
    display: flex;
    align-items: center;

    width: 100%;
    margin-left: 20px;
  }
}

.nav-header__items {
  flex: 1;
  display: flex;
  align-items: center;

  @include media($nav-header-flip) {
    position: relative;
    flex: inherit;
    align-self: flex-end;
    height: map-get($nav-header-height, medium);
  }
}

.nav-header__right-align {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.nav-header__search {
  position: relative;
  z-index: 1;
  order: 1;
  margin-right: 4px;

  @include media($nav-header-flip) {
    flex: inherit;
    order: 0;
  }

  &:last-child {
    @include media($nav-header-flip) {
      margin-right: 20px;
    }
  }
}

.nav-header__notification {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 38px;
  height: 41px;
  border-radius: $border-radius;

  .notification {
    pointer-events: none;
    top: 3px;
    right: 5px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--theme-hover);

    @include media('>medium') {
      .tooltip {
        display: block;
      }
    }
  }

  .tooltip {
    display: none;
    position: absolute;
    pointer-events: none;
    top: 32px;
    left: -38px;
  }

  svg {
    pointer-events: none;
    width: auto;
    height: 21px;
  }

  &:last-child {
    @include media($nav-header-flip) {
      margin-right: 20px;
    }
  }
}

.nav-header__account-mobile-button {
  margin-right: 10px;

  svg {
    display: block;
  }

  @include media($nav-header-flip) {
    display: none;
    visibility: hidden;
  }
}

.nav-header__language-switch,
.nav-header__language-switch-button,
.nav-header__account-menu,
.nav-header__account-menu-button {
  display: none;
  visibility: hidden;

  @include media($nav-header-flip) {
    display: block;
    visibility: visible;
  }
}

.nav-header__account-menu,
.nav-header__language-switch {
  pointer-events: none;
  position: absolute;
  top: calc(100% - 15px);
}

.nav-header__account-menu {
  min-width: 140px;
}

.nav-header__language-switch {
  right: 0;
  min-width: 200px;
}

@mixin menu-icon {
  background-repeat: no-repeat;
  background-position: grid-size(2) center;
  padding-left: grid-size(10);
}

.nav-header__link {
  display: none;
  visibility: hidden;

  @include media($nav-header-flip) {
    display: inline;
    white-space: nowrap;
    visibility: visible;

    font-family: $font-opensans--bold;
    font-size: 14px;
    line-height: 14px;

    text-decoration: none;
    color: $color-white;

    margin-right: 20px;

    border-radius: $border-radius;
    padding: 15px 10px;

    &:focus,
    &:hover {
      background-color: var(--nav-link-hover);
      text-decoration: none;
    }
    &--active {
      background-color: var(--nav-link-active);
      text-decoration: none;
    }
  }

  &--relocation {
    @include menu-icon;
    background-image: static-url('images/relocation.svg');
  }

  &--museum {
    @include menu-icon;
    background-image: static-url('images/museum.svg');
  }
}

.account-menu-button {
  &:focus,
  &:hover {
    background-color: var(--nav-link-hover);
    text-decoration: none;
  }

  &--active {
    background-color: var(--nav-link-active);
    text-decoration: none;
  }
}
