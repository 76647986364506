@mixin rotate {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-duration: 4s;
}

.error-page {
  background-color: $color-coolblue;

  .nav-header {
    border-bottom: 2px solid transparentize($color-dayblue, .5);
  }

  .page-content {
    .error {
      margin-bottom: 0;
    }

    .error__inner {
      background-color: transparent;
    }
  }
}

.error {
  padding-top: map_get($nav-header-height, small);
  margin-bottom: 0;

  @include media('>medium') {
    padding-top: map_get($nav-header-height, medium);
  }
}

.error__inner {
  position: relative;
}

.error--404 {
  overflow: hidden;
  padding: 5% 0;
  margin-top: map_get($nav-header-height, small);

  @include media('>medium') {
    margin-top: map_get($nav-header-height, medium);
  }
}

.error__content {
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  z-index: 3;

  @include media('>medium') {
    left: 50%;
    transform: translate(-50%, -50%);
    width: grid-size(94);
  }
}

.error__fact {
  @include typography_fact;
  color: $color-white;
}

.error__slogan {
  @include typography_slogan;
  color: $color-white;
  margin-bottom: grid-size(8);
}

.error__text {
  color: $color-white;
  margin-bottom: grid-size(8);
}

.error__button {
  background-color: $color-white;
}

.error__animation {
  position: relative;
  width: 100%;
  padding-bottom: 85%;
}

.error__animation-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: static-url('images/road.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.error__car {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center right;
  background-image: static-url('images/van.svg');
  background-repeat: no-repeat;
  background-size: 12%;
  z-index: 1;
  @include rotate;
}

.error__shadow {
  position: absolute;

  // Shadow offset
  top: 5px;
  right: 15px;

  width: 100%;
  height: 100%;
  background-position: center right;
  background-image: static-url('images/van-shadow.svg');
  background-repeat: no-repeat;
  background-size: 12%;
  z-index: 0;
  @include rotate;
}

@keyframes rotate {
  0% {
    transform: translateY(0) rotateZ(0);
  }
  25% {
    transform: translateY(-7%) rotateZ(90deg);
  }
  50% {
    transform: translateY(0) rotateZ(180deg);
  }
  75% {
    transform: translateY(7%) rotateZ(270deg);
  }
  100% {
    transform: translateY(0) rotateZ(360deg);
  }
}
