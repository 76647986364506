.home-page__search-bar {
  display: block;

  @include media('>small') {
    display: none;
  }
}

.search-bar--hidden {
  display: none;
}

.search-bar {
  display: flex;
  position: relative;
  margin-top: grid-size(6);
  z-index: 3
}

.search-bar__container {
  @include media('>extra-large') {
    max-width: 1248px;
  }
}

.search-bar__input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 1 100%;
  border: 1px solid $color-grey;
  border-radius: $border-radius;
  z-index: 5;

  @include media('>medium') {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus-within {
      border: 1px solid $color-coolblue;
      border-right: none;
    }
  }
}

.search-bar__input {
  width: 100%;
  @include typography_p;
  font-size: 14px;
  padding: grid-size(4) grid-size(5);
  line-height: 1.2;
  border: none;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $color-raingrey;
  }

  @include media('>large') {
    font-size: 16px;
  }
}

.search-bar__submit-mobile {
  display: block;
  border: none;
  background-color: $color-white;
  padding: grid-size(3);
  height: 100%;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 19px;
    height: auto;
  }

  @include media('>medium') {
    display: none;
  }
}

.search-bar__submit {
  display: none;
  flex: 0 0 auto;
  margin-left: -3px;
  z-index: 6;

  &:hover {
    cursor: pointer;
  }

  @include media('>medium') {
    display: block;
  }
}

.search-bar__suggest.suggest {
  z-index: 4;
  top: 100%;
  width: calc(100% + 2px);
  left: -1px;
  border: solid 1px $color-grey;
  border-top: solid 1px $color-grey;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
}

.search-bar__reset {
  flex: 0 0 auto;
  padding: 8px;
  pointer-events: auto;
  background-color: $color-white;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  @include media('>medium') {
    padding-right: 16px;
  }

  svg {
    height: 12px;
    width: 12px;
    opacity: 0;

    path {
      fill: $color-silver;
    }
  }
}

.search-bar__input:not(:placeholder-shown) ~ .search-bar__reset {
  svg {
    opacity: 1;
  }
}
