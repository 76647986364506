.vacancy-card {
  background-color: $color-white;
  user-select: none;

  --vacancy-card-primary: #{$color-coolblue};
  --vacancy-card-icon-clock: #{get_inline_icon('clock')};
  --vacancy-card-icon-euro: #{get_inline_icon('euro-alt')};
  --vacancy-card-icon-pin: #{get_inline_icon('pin')};
  --vacancy-card-icon-study: #{get_inline_icon('study')};

  &--internal {
    --vacancy-card-primary: var(--theme-background-primary);
    --vacancy-card-icon-clock: #{get_inline_icon('clock', 'dayblue')};
    --vacancy-card-icon-euro: #{get_inline_icon('euro-alt', 'dayblue')};
    --vacancy-card-icon-pin: #{get_inline_icon('pin', 'dayblue')};
    --vacancy-card-icon-study: #{get_inline_icon('study', 'dayblue')};
  }
}

.vacancy-card--button {
  @include button-border;
}

.vacancy-card--banner {
  background-color: $color-greyblue;
  overflow: hidden;

  .vacancy-card__link {
    padding: 0;
    display: flex;
    flex-direction: column;
    @include media('>=large') {
      flex-direction: row;
    }
  }

  .vacancy-card__image-wrapper {
    flex: 0 0 150px;
    @include media('>=large') {
      flex: 0 0 190px;
      margin-bottom: 2px;
    }
    background-size: cover;
  }

  .vacancy-card__content-wrapper {
    padding: grid-size(3) grid-size(3) grid-size(2);

    @include media('>small') {
      padding: grid-size(4) grid-size(5) grid-size(3);
    }
  }
}

.vacancy-card--fixed-summary {
  .vacancy-card__summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    height: 40px;

    @include media('>small') {
      height: 42px;
    }
  }
}

.vacancy-card__link {
  display: block;
  padding: grid-size(3) grid-size(3) grid-size(2);

  @include media('>small') {
    padding: grid-size(4) grid-size(5) grid-size(3);
  }

  &:active,
  &:focus {
    text-decoration: none;
  }
}

.vacancy-card__banner-link {
  background-image: static-url('images/chevron-right.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 6px;
  padding-left: 12px;
  margin-top: 8px;
}

.vacancy-card__header {
  margin-bottom: grid-size(2);
}

.vacancy-card__title {
  @include typography_h2;
  color: var(--vacancy-card-primary);
  margin-top: 0;
  margin-right: grid-size(2);

  @include media('>small') {
    font-size: 16px;
  }
}

.vacancy-card__label {
  border: 1px solid $color-coolblue;
  border-radius: 9px;
  line-height: 18px;
  font-size: 11px;
  padding: 0 6px;
  display: inline-block;
  position: relative;
  font-family: $font-dobraslab-book;

  &--filled {
    color: $color-white;
    border-color: var(--vacancy-card-primary);
    background-color: var(--vacancy-card-primary);
  }
}

.vacancy-card__icon {
  height: 12px;
  position: absolute;
  top: 2px;

  svg {
    width: 12px;
    height: 12px;
    & > * {
      fill: $color-coolblue;
    }
  }

  &--iconssunrise,
  &--iconsflames,
  &--iconscrown {
    svg > * {
      fill: $color-hotorange;
    }
  }
}

.vacancy-card__icon + .vacancy-card__label-text {
  margin-left: grid-size(4);
}

.vacancy-card__label-text {
  white-space: nowrap;
}

.vacancy-card__summary {
  color: $color-hurricanegrey;
  font-size: 13px;
}

.vacancy-card__usps {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin-top: grid-size(3);
}

.vacancy-card__usp {
  flex: 0 0 initial;
  color: $color-raingrey;
  font-size: 12px;
  position: relative;
  padding-left: grid-size(6);
  padding-right: grid-size(4);
  margin-bottom: grid-size(1);
  white-space: nowrap;

  @include media('>small') {
    padding-right: grid-size(7);
  }

  &:before {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: grid-size(4);
    height: grid-size(4);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:last-child {
    flex: 1 0;
  }
}

.vacancy-card__usp--location {
  padding-left: 21px;

  &:before {
    width: 13px;
    background-image: var(--vacancy-card-icon-pin);
  }
}

.vacancy-card__usp--contract-hours {
  &:before {
    background-image: var(--vacancy-card-icon-clock);
  }
}

.vacancy-card__usp--education {
  padding-left: grid-size(7);

  &:before {
    width: grid-size(6);
    left: -4px;
    background-image: var(--vacancy-card-icon-study);
  }
}

.vacancy-card__usp--salary {
  &:before {
    background-image: var(--vacancy-card-icon-euro);
  }

  .results__items--no-salary & {
    display: none;
  }
}

.vacancy-card__link:focus {
  outline: none;
}

.vacancy-card__link:hover {
  text-decoration: none;
}
