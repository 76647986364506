.toast {
  width: 100%;
  padding: grid-size(1) grid-size(2);
  margin-bottom: grid-size(3);
  border: solid 0.5px;
  border-radius: $border-radius;

  font-size: 11px;
  line-height: 20px;

  color: $color-maritimeblue;
  border-color: $color-maritimeblue;
  background-color: $color-antarcticblue;

  &--success {
    color: $color-freshgreen;
    border-color: $color-freshgreen;
    background-color: $color-celadon;
  }

  &--error {
    color: $color-scarletred;
    border-color: $color-scarletred;
    background-color: $color-errorbg;
  }

  &--hidden {
    display: none;
  }
}
