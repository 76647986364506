.salary-calculator-result {
  @include typography_p();
  width: 100%;
  border-radius: $border-radius;
}

.salary-calculator-result__extra {
  padding: 15px;
  font-size: 15px;
  line-height: 19px;
  border-radius: 7px;
  background-color: $color-greyblue;

  b {
    font-family: $font-opensans--bold;
  }

  .salary-calculator-result__row {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
  }

  .salary-calculator-result__column {
    span {
      display: block;
      font-size: 13px;
      font-family: $font-opensans--semibold;

      @include media(">small") {
        font-size: 15px;
      }
    }
  }

  .salary-calculator-result__column:nth-child(1) {
    flex: 1;
    max-width: 60px;
  }

  .salary-calculator-result__column:nth-child(2) {
    flex: 1;
    font-size: 13px;
  }

  @include media(">small") {
    padding: 20px;
  }
}

.salary-calculator-result__note {
  margin-top: 16px;
  font-size: 13px;
  line-height: 19px;
  color: $color-darkgrey;
}
