.filter-form {
  button,
  input {
    appearance: none;

    &:focus {
      outline: none;
    }
  }
}

.filter-form__row {
  padding: grid-size(3) grid-size(5);
  border-bottom: 1px solid transparentize($color-darkgrey, .8);
  &:last-child {
    border-bottom: none;
  }
  @include media('>medium') {
    padding: grid-size(3) 0;
    border: none;
  }
}

.filter-form__row--internal {
  input[type="checkbox"] {
    display: none;
  }

  .filter-form__button {
    border: 1px solid $color-grey;
    border-radius: $border-radius;
    padding: grid-size(3);
    position: relative;
    color: $color-dayblue;
    line-height: grid-size(4);
    font-family: $font-dobraslab-book;
    font-size: 13px;
    display: block;
    transition: background-color .2s ease-in-out, border-color .3s linear;
    cursor: pointer;

    &:hover {
      border-color: $color-dayblue;
    }

    svg {
      transform: translateY(2px);
      margin-right: grid-size(1);
      max-height: grid-size(4);
      max-width: grid-size(4);
      path {
        stroke: $color-dayblue;
        fill: $color-dayblue;
      }
    }
  }

  .filter-form__button:after {
    content: '';
    display: block;
    top: 50%;
    right: 13px;
    transform: translate(-100%, -50%);
    position: absolute;
    width: grid-size(4);
    height: grid-size(4);
    border-radius: 10px;
    background-color: $color-white;
    transition: transform .2s ease-in-out;
  }

  .filter-form__button:before {
    content: '';
    display: block;
    top: 50%;
    right: grid-size(3);
    transform: translateY(-50%);
    position: absolute;
    width: grid-size(8);
    height: grid-size(4);
    border: 1px solid $color-grey;
    border-radius: 10px;
    background-color: $color-grey;
    box-sizing: content-box;
  }
  #filter-form-internal:checked + .filter-form__button {
    background-color: $color-dayblue;
    border-color: $color-dayblue;
    color: $color-white;
    svg path {
      fill: $color-white;
      stroke: $color-white;
    }
    &:after {
      transform: translate(0, -50%);
    }
    &:before {
      border-color: $color-freshgreen;
      background-color: $color-freshgreen;
      transition: all .2s ease-in-out;
    }
  }
}


.filter-form__label {
  position: relative;
  display: inline-block;
  @include typography_h5;
  margin-top: grid-size(1);
}

.filter-form__show-all {
  position: relative;
  display: inline-block;
  color: $color-coolblue;
  font-size: 13px;
  padding-left: 24px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 6px;
    width: 12px;
    height: 12px;
    background-image: static-url('images/chevron-down-blue.svg');
    background-repeat: no-repeat;
    background-size: 12px;
    transform-origin: center;
    transition: transform .2s linear .2s;
  }
}

.filter-form__explainer-link {
  position: absolute;
  top: -5px;
  right: -25px;
  height: 25px;
  width: 25px;

  svg {
    margin: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}

.filter-form__row--query {
  display: flex;
  position: relative;
  border: none;
  @include media('>medium') {
    padding-top: 0;
    &:hover .filter-form__field {
      border-color: $color-coolblue;
    }
  }
  .filter-form__label {
    display: none;
  }
  .filter-form__field {
    @include typography_p;
    padding: 9px 12px;
    border: 1px solid $color-grey;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;

    @include input-hover($color-coolblue);

    @include media('>medium') {
      border-right: 1px solid $color-grey;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  .filter-form__button--reset {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s linear;
    top: 16px;
    right: 61px;
    border-radius: 0;
    border: none;
    padding: 9px 12px;
    text-indent: -200vw;
    background-image: static-url('images/close-grey.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px;
    background-color: transparent;
    width: grid-size(9);
    height: grid-size(10);

    @include media('>medium') {
      top: 1px;
      right: 41px;
    }
  }

  .filter-form__button--query {
    border-top: 1px solid $color-coolblue;
    border-right: 1px solid $color-coolblue;
    border-bottom: 1px solid $color-coolblue;
    border-left: none;
    color: $color-white;
    display: inline-block;
    padding: 14px 20px;
    text-indent: -200vw;
    background-color: $color-coolblue;
    background-image: static-url('images/search-white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    width: grid-size(10);

    @include media('>medium') {
      position: absolute;
      right: 1px;
      top: 1px;
      border: none;
      background-color: transparent;
      background-image: static-url('images/search.svg');
      border-color: $color-grey;
    }
  }

  &:hover {
    .filter-form__field {
      padding-right: grid-size(9);
    }
    .filter-form__button--reset {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.filter-form__has-subopts {
  > .filter-form__checkbox-label .filter-form__checkbox-text {
    display: inline;

    &:after {
      content: '';
      width: grid-size(4);
      height: grid-size(4);
      position: absolute;
      right: -20px;
      top: 2px;
      background-image: static-url('images/chevron-down-darkgrey.svg');
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.filter-form__subopts {
  margin-left: grid-size(6);
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .2s linear;
}

.filter-form__checkbox {
  display: block;
  margin: grid-size(2) 0;

  input {
    display: none;
  }

  .filter-form__checkbox-text {
    cursor: pointer;
    font-size: 13px;
    width: 100%;
    padding-left: grid-size(6);
    position: relative;

    &:before {
      content: '';
      border: 1px solid $color-grey;
      border-radius: $border-radius;
      background-color: $color-white;
      width: grid-size(4);
      height: grid-size(4);
      position: absolute;
      left: 0;
      top: 2px;
    }
  }

  input:checked + .filter-form__checkbox-label {
    .filter-form__checkbox-text:before {
      border-color: $color-coolblue;
      background-color: $color-coolblue;
      background-image: static-url('images/check.svg');
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .filter-form__checkbox-text:after {
      transform: rotateX(180deg);
    }

    & + .filter-form__subopts {
      max-height: 100vh;
      opacity: 1;
    }
  }

  .filter-form__checkbox-icon {
    display: none;
    align-self: flex-end;
    position: relative;
    height: grid-size(5);
    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) scale(.7);
      transform-origin: right center;
    }
    &--bus svg {width: 30px;height: 21px;}
    &--bike svg {width: 48px;height: 18px;}
    &--warehouse svg {width: 31px;height: 25px;}
    &--appliance svg {width: 19px;height: 24px;}
    &--solarpanels svg {width: 30px;height: 30px;}
    &--charging svg {width: 28px;height: 28px;}
    &--forklift svg {width: 33px;height: 24px;}
    &--office svg {width: 28px;height: 18px;}
  }
}

.filter-form__checkbox--collapsed {
  display: none;
}
