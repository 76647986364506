$number-of-icons: 5;
$icon-size: 27px;
$icon-margin: 8px;

$offset: 4px;

.share-options {
  display: none;
  visibility: hidden;
  position: relative;
  white-space: nowrap;

  @include media(">small") {
    display: block;
    visibility: visible;
  }
}

.share-options__inner {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
}

.share-options__label {
  color: $color-white;
  text-align: right;
}

.share-options__icon {
  width: $icon-size;
  height: $icon-size;
  opacity: 0.8;
  margin-right: grid-size(2);

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: $color-white;
  }
}
