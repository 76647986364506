.location-card {
  @include button-border;
  box-shadow: none;
  position: relative;

  a:hover, a:active {
    text-decoration: none;
  }

  &:hover {
    box-shadow: none;
  }
}

.location-card__inner {
  display: none;
  margin-left: 32px;
  padding-bottom: grid-size(3);
}

.location-card__radio:checked {
  & + .location-card__title {
    &:after {
      transform: rotate(180deg);
    }
  }
  & + .location-card__title + .location-card__inner {
    display: block
  }
}

.location-card__radio {
  position: absolute;
  top: grid-size(3);
  left: grid-size(3);
  margin: 0;
  margin-top: 3px;
}

.location-card__title {
  @include typography_h3;
  color: $color-coolblue;
  margin: 0;
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: grid-size(3) grid-size(9) grid-size(3) grid-size(8);

  &:after {
    position: absolute;
    //transition: transform 300ms;
    content: '';
    right: grid-size(3);
    top: grid-size(4);
    background-image: static-url('images/chevron-down-blue.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 15px;
    width: 15px;
  }
}

.location-card__distance {
  @include typography_p;
  font-size: 12px;
  color: $color-text;
}

.location-card__text {
  color: $color-text;
}

.location-card__meta {
  margin-top: grid-size(3);
  background-position: center left;
  background-repeat: no-repeat;
  color: $color-text;
  padding-left: grid-size(6);
  padding-right: grid-size(7);
}

.location-card__meta--contract {
  background-image: static-url('images/clock.svg');
  background-size: 14px;
}

.location-card__link {
  display: inline-block;
  margin-top: grid-size(3);
  background-position: center right 12px;
  background-repeat: no-repeat;
  padding-right: grid-size(7);
  background-image: static-url('images/chevron-right.svg');
  background-size: 8px;
}
