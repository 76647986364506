.interactive-calendar {
  overflow: hidden;
  @include media('>medium') {
    background-color: $color-greyblue;
  }
}

.page-content .interactive-calendar .interactive-calendar__inner {
  padding-bottom: 0;

  @include media('>medium') {
    background-color: $color-greyblue;
  }
}

.interactive-calendar__wrapper {
  position: relative;
  background-color: $color-greyblue;
  padding: grid-size(5) grid-size(4) grid-size(10) grid-size(4);
  @include media('>medium') {
    padding: 0;
    display: flex;
    flex-direction: row;
  }
}

.interactive-calendar__title {
  @include typography_h2;
  color: $color-coolblack;
  @include media('>medium') {
    padding-bottom: grid-size(7);
    margin-bottom: grid-size(10);
    border-bottom: 1px solid $color-antarcticblue;

    .interactive-calendar--list & {
      max-width: 80%;
    }
  }
}

.interactive-calendar__nav {
  margin-bottom: grid-size(4);
  @include media('>medium') {
    width: grid-size(45);
    margin-right: grid-size(8);
  }
}

.interactive-calendar__days {
  display: flex;
  @include media('>medium') {
    display: block;
  }
}
.interactive-calendar__day {
  flex: 1;
  @include media('>medium') {
    flex: auto;
  }
}

.interactive-calendar__weeknumber {
  @include typography_h5;
  margin-bottom: grid-size(3);
  color: $color-coolblue;
  @include media('>medium') {
    padding-left: grid-size(4);
  }
}

.interactive-calendar__day.active {
  .interactive-calendar__day-short {
    background-color: $color-antarcticblue;
  }

  .interactive-calendar__day-long {
    background-color: $color-antarcticblue;

    &:hover {
      //background-color: $color-greyblue;
      //border: 1px solid $color-antarcticblue;
      cursor: default;
    }
  }
}

.interactive-calendar__day-short {
  @include typography_fact;
  font-size: 16px;
  line-height: 46px;
  background-color: $color-white;
  border: 1px solid $color-antarcticblue;
  border-radius: $border-radius;
  margin-right: grid-size(1);
  text-align: center;
  display: block;

  @include media('>small') {
    font-size: 16px;
    line-height: 46px;
  }

  @include media('>medium') {
    display: none;
  }
}

.interactive-calendar__day-long {
  @include typography_fact;
  display: none;
  @include media('>medium') {
    display: block;
    font-size: 14px;
    line-height: grid-size(9);
    padding-left: grid-size(4);
    border-radius: $border-radius;
    border: 1px solid transparent;
    cursor: pointer;
    margin-bottom: grid-size(1);

    &:hover {
      //background-color: $color-antarcticblue;
      border: 1px solid $color-antarcticblue;
    }
  }
}

.interactive-calendar__content {
  position: relative;
  height: grid-size(123);
  width: 100%;

  .interactive-calendar--list & {
    height: auto;
  }

  @include media('>medium') {
    height: grid-size(128);

    .interactive-calendar--list & {
      height: auto;
      max-width: 80%;
      margin-bottom: grid-size(10);
    }
  }
}

.interactive-calendar__day-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s linear;

  .interactive-calendar--list & {
    position: relative;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.interactive-calendar__item {
  position: relative;
  background-color: $color-white;
  border: 1px solid transparent;
  border-radius: $border-radius;
  padding: grid-size(3) grid-size(4);
  margin-bottom: grid-size(2);

  @include media('>medium') {
    padding: grid-size(3) grid-size(5);
  }

  &--red {
    background-color: #FFDFCA;
  }

  &--blue {
    background-color: #BBE6FF;
  }

  &--yellow {
    background-color: #FFF1B6;
  }
}

.interactive-calendar__item--popover {
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    right: grid-size(4);
    top: calc(#{grid-size(3)} + 5px);
    width: 7px;
    height: 12px;
    background-image: url(/static/images/chevron-right.svg);
    background-repeat: no-repeat;
    background-size: 7px;
    transform-origin: center;
    transition: transform 200ms ease-out;

    @include media(">medium") {
      right: grid-size(5);
      top: calc(#{grid-size(3)} + 5px);
    }
  }

  &:hover {
    border: 1px solid $color-grey;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.10);

    &:after {
      transform: translateX(5px);
    }
  }
}

.interactive-calendar__metadata {
  display: flex;
}

.interactive-calendar__metadata-item {
  font-size: 10px;
  line-height: 18px;
  background-position: top 3px left;
  background-repeat: no-repeat;
  background-size: grid-size(3);
  padding-right: grid-size(7);
  padding-left: grid-size(4);
  margin-bottom: $margin-extratiny;
}

.interactive-calendar__metadata-item--time {
  background-image: static-url('images/clock.svg');
}

.interactive-calendar__metadata-item--location {
  background-image: static-url('images/pin.svg');
}

.interactive-calendar__item-title {
  font-size: 15px;
  line-height: 22px;
}

.interactive-calendar__overlay {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: grid-size(10);
  background-image: linear-gradient(180deg, transparent, $color-dayblue);
  opacity: .2;
  @include media('>medium') {
    display: block;
  }

  &--mobile {
    display: block;
    @include media('>medium') {
      display: none;
    }
  }
}
