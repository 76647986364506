.testimonial-slider {
  .slider__inner {
    @include media('>extra-large') {
      max-width: 1000px;
    }
  }

  .slider__button {
    top: 60px;
  }
}

.testimonial-slider__title {
  @include typography_h2();
}

.testimonial-slider-item {
  flex: 0 0 66.666%;
  margin-bottom: grid-size(4);

  @include media('>small') {
    flex: 0 0 50%;
  }

  @include media('>medium') {
    flex: 0 0 33.333%;
  }

  @include media('>large') {
    flex: 0 0 calc(25% + 3px);
    margin-bottom: grid-size(10);
  }
}

.testimonial-slider-item__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: grid-size(4);
  border-radius: $border-radius;
  margin-right: grid-size(3);
  background-color: $color-greyblue;
}

.testimonial-slider-item__picture {
  width: 96px;
  height: 96px;
  margin-bottom: grid-size(2);
  border-radius: 50%;
  background-color: $color-antarcticblue;
  background-image: static-url('images/face-happy.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.testimonial-slider-item__name {
  @include typography_h2();
  text-align: center;
  margin-top: 0;
  margin-bottom: grid-size(4);
}

.testimonial-slider-item__quote {
  flex: 1 1 auto;
  font-family: $font-dobraslab-book;
  font-size: 16px;
  line-height: 1.25;
  color: $color-darkgrey;
  margin-bottom: grid-size(4);
}

.testimonial-slider-item__feat {
  @include typography_h4();
  text-align: center;
  color: $color-coolblue;
}
