.vacancy-map-window {
  @include typography_p;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.vacancy-map-window__vacancies {
  border-radius: $border-radius;
  overflow: hidden;
}

.vacancy-map-window__vacancy--hidden {
  display: none;
}

.vacancy-map-window__controls {
  @include typography_button;
  position: relative;
  display: flex;
  color: $color-white;
  margin-bottom: 10px;
  height: 27px;
}

.vacancy-map-window__controls-backward,
.vacancy-map-window__controls-current,
.vacancy-map-window__controls-forward,
.vacancy-map-window__controls-close {
  padding: 4px 10px 6px 10px;
  background-color: $color-dayblue;
  box-shadow: inset 0 -2px 0 0 $color-maritimeblue;
}

.vacancy-map-window__controls-backward,
.vacancy-map-window__controls-forward,
.vacancy-map-window__controls-close {
  transition: all .2s ease-in-out;

  &:hover {
    @include button-hover(true);
    cursor: pointer;
  }

  svg {
    height: 10px;
    width: 10px;

    path {
      fill: white;
    }
  }
}

.vacancy-map-window__controls-group {
  display: flex;
}

.vacancy-map-window__control--disabled {
  pointer-events: none;

  svg {
    opacity: 0.3;
  }
}

.vacancy-map-window__controls-backward {
  border-radius: $border-radius 0 0 $border-radius;

  svg {
    transform: rotate(180deg);
  }
}

.vacancy-map-window__controls-current {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.vacancy-map-window__controls-forward {
  border-radius: 0 $border-radius $border-radius 0;
}

.vacancy-map-window__controls-close {
  border-radius: $border-radius;
  position: absolute;
  right: 0;
  top: 0;

  padding: 4px 6px 1px;

  svg {
    height: 19px;
    width: 19px;
  }
}
