.code-block {
  color: white;
  pre {
    white-space: pre-wrap;
  }
  .container__inner {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    @include media('>large') {
      max-width: 1038px;
    }
  }
}

.code-block__inner {
  @include media(">medium") {
    display: flex;
  }
}

.code-block__code {
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: $margin-small;
  background-color: #29292D;

  border-radius: $border-radius $border-radius 0 0;

  font-family: 'Consolas', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', monospace;
  font-size: 10px;

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #B7B7B7 #585858;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #585858;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #B7B7B7;
    border-radius: 20px;
    border: 3px solid #B7B7B7;
  }

  scroll-behavior: smooth;

  @include media(">medium") {
    flex: 1;
    font-size: 12px;
    max-height: 70vh;
    padding: $margin-medium;
    border-radius: $border-radius 0 0 $border-radius;
  }

  @media screen and (prefers-reduced-motion: reduce) {
      scroll-behavior: auto;
  }
}

.code-block__highlights {
  flex: 0.3;
  padding: $margin-medium;
  background-color: #191919;
  border-radius: 0 0 $border-radius $border-radius;

  @include media(">medium") {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.code-block__line-number {
  color: #727272;
  max-width: 36px;
  width: 36px;
  padding-right: 20px;
  display: inline-block;
}

.code-block__line {
  word-break: break-all;
}

.code-block__highlighted {
  background-color: rgba(255, 70, 70, 0.3);
  border-radius: $border-radius;

  span {
    color: white;
  }

  .code-block__line-number {
    color: #B8B8B8;
  }

  .highlight__n {
    color: white;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 70, 70, 0.5);
  }
}

.code-block__highlighted--active {
  background-color: rgba(255, 70, 70, 0.7);

  &:hover {
    background-color: rgba(255, 70, 70, 0.7);
  }
}

$color-keyword: #C586C0;
$color-name-function: #DCDCAA;
$color-operator: #D4D4D4;
$color-name-other: #4EC9B0;
$color-name-variable: #9CDCFE;
$color-string: #CE9178;


.highlight__c { color: #776e71 } /* Comment */
.highlight__err { color: #ef6155 } /* Error */
.highlight__k { color: $color-keyword } /* Keyword */
.highlight__l { color: #f99b15 } /* Literal */
.highlight__n { color: #e7e9db } /* Name */
.highlight__o { color: $color-operator } /* Operator */
.highlight__p { color: #e7e9db } /* Punctuation */
.highlight__ch { color: #776e71 } /* Comment.Hashbang */
.highlight__cm { color: #776e71 } /* Comment.Multiline */
.highlight__cp { color: #776e71 } /* Comment.Preproc */
.highlight__cpf { color: #776e71 } /* Comment.PreprocFile */
.highlight__c1 { color: #776e71 } /* Comment.Single */
.highlight__cs { color: #776e71 } /* Comment.Special */
.highlight__gd { color: #ef6155 } /* Generic.Deleted */
.highlight__ge { font-style: italic } /* Generic.Emph */
.highlight__gh { color: #e7e9db; font-weight: bold } /* Generic.Heading */
.highlight__gi { color: #48b685 } /* Generic.Inserted */
.highlight__gp { color: #776e71; font-weight: bold } /* Generic.Prompt */
.highlight__gs { font-weight: bold } /* Generic.Strong */
.highlight__gu { color: #5bc4bf; font-weight: bold } /* Generic.Subheading */
.highlight__kc { color:$color-keyword } /* Keyword.Constant */
.highlight__kd { color: $color-keyword } /* Keyword.Declaration */
.highlight__kn { color: $color-keyword } /* Keyword.Namespace */
.highlight__kp { color: $color-keyword } /* Keyword.Pseudo */
.highlight__kr { color: $color-keyword } /* Keyword.Reserved */
.highlight__kt { color:$color-keyword } /* Keyword.Type */
.highlight__ld { color: #48b685 } /* Literal.Date */
.highlight__m { color: #f99b15 } /* Literal.Number */
.highlight__s { color: #48b685 } /* Literal.String */
.highlight__na { color: $color-name-function } /* Name.Attribute */
.highlight__nb { color:  $color-name-function } /* Name.Builtin */
.highlight__nc { color: $color-name-function } /* Name.Class */
.highlight__no { color: #ef6155 } /* Name.Constant */
.highlight__nd { color: #5bc4bf } /* Name.Decorator */
.highlight__ni { color: #e7e9db } /* Name.Entity */
.highlight__ne { color: #ef6155 } /* Name.Exception */
.highlight__nf { color: $color-name-function } /* Name.Function */
.highlight__nl { color: $color-name-other } /* Name.Label */
.highlight__nn { color: $color-name-other } /* Name.Namespace */
.highlight__nx { color: $color-name-other } /* Name.Other */
.highlight__py { color: #e7e9db } /* Name.Property */
.highlight__nt { color: #5bc4bf } /* Name.Tag */
.highlight__nv { color: $color-name-variable } /* Name.Variable */
.highlight__ow { color: #5bc4bf } /* Operator.Word */
.highlight__w { color: #e7e9db } /* Text.Whitespace */
.highlight__mb { color: #f99b15 } /* Literal.Number.Bin */
.highlight__mf { color: #f99b15 } /* Literal.Number.Float */
.highlight__mh { color: #f99b15 } /* Literal.Number.Hex */
.highlight__mi { color: #f99b15 } /* Literal.Number.Integer */
.highlight__mo { color: #f99b15 } /* Literal.Number.Oct */
.highlight__sa { color: $color-string } /* Literal.String.Affix */
.highlight__sb { color: $color-string } /* Literal.String.Backtick */
.highlight__sc { color: $color-string } /* Literal.String.Char */
.highlight__dl { color: $color-string } /* Literal.String.Delimiter */
.highlight__sd { color: $color-string } /* Literal.String.Doc */
.highlight__s2 { color: $color-string } /* Literal.String.Double */
.highlight__se { color: $color-string } /* Literal.String.Escape */
.highlight__sh { color: $color-string } /* Literal.String.Heredoc */
.highlight__si { color: $color-string } /* Literal.String.Interpol */
.highlight__sx { color: $color-string } /* Literal.String.Other */
.highlight__sr { color: $color-string } /* Literal.String.Regex */
.highlight__s1 { color: $color-string } /* Literal.String.Single */
.highlight__ss { color: $color-string } /* Literal.String.Symbol */
.highlight__bp { color: #e7e9db } /* Name.Builtin.Pseudo */
.highlight__fm { color: #06b6ef } /* Name.Function.Magic */
.highlight__vc { color: #ef6155 } /* Name.Variable.Class */
.highlight__vg { color: #ef6155 } /* Name.Variable.Global */
.highlight__vi { color: #ef6155 } /* Name.Variable.Instance */
.highlight__vm { color: #ef6155 } /* Name.Variable.Magic */
.highlight__il { color: #f99b15 } /* Literal.Number.Integer.Long */
