.testimonial {
  flex: 0 0 100%;
  min-height: grid-size(31);
  margin-top: grid-size(2);
  position: relative;

  a {
    display: flex;
    flex-direction: row;
    background-color: $color-greyblue;
    border-radius: $border-radius;
    height: 100%;
    text-decoration: none;

    &:hover {
      background-color: transparentize($color-greyblue, .5);
      .testimonial__link {
        text-decoration: underline;
      }
    }
  }

  @include media('>medium') {
    flex: 0 0 calc(50% - 6px);
    margin-right: grid-size(3);
    min-height: grid-size(50);

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}

.testimonial__content {
  flex: 0 0 70%;
  padding: grid-size(4);
  display: flex;
  flex-direction: column;
  align-content: space-between;

  @include media('>medium') {
    flex: 0 0 60%;
    padding: grid-size(6) grid-size(4);
  }
}

.testimonial__link svg path {
  fill: $color-coolblue;
}

.testimonial__image-wrapper {
  flex: 0 0 30%;
  position: relative;
  @include media('>medium') {
    flex: 0 0 40%;
  }
}

.testimonial__quote {
  font-family: $font-dobraslab-book;
  font-size: 16px;
  line-height: 20px;
  color: $color-darkgrey;
  margin-bottom: grid-size(3);
  flex: 1 1;

  @include media('>medium') {
    font-size: 20px;
    line-height: 25px;
  }
}

.testimonial__link {
}

.testimonial__image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  @include media('>medium') {
    top: -16px;
    bottom: 0;
    height: auto;
  }
}
