.usp-block-alt__title {
  @include typography_h2();
}
.usp-block-alt__container__inner {
  padding-top: 0;
}

.usp-block-alt__list {
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 12px;

  @include media('>medium') {
   grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
}

.usp-block-alt__item {
  position: relative;
  padding-bottom: 100%;
  border: 1px solid #006CAA;
  border-radius: 3px;
  box-shadow: inset 0px -2px 0px #006CAA;
  color: $color-white;
  background-color: $color-coolblue;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #006CAA;
  }
}

.usp-block-alt__content {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  svg {
    width: 46px;
    height: auto;
    margin-bottom: grid-size(3);
  }
}

.usp-block-alt__usp {
  font-family: $font-avenir;
  font-size: 16px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: grid-size(1);

  @include media('>small') {
    font-size: 20px;
  }
  @include media('>medium') {
    font-size: 16px;
  }
  @include media('>large') {
    font-size: 24px;
  }
}

.usp-block-alt__intro {
  font-family: $font-dobraslab-book;
  font-size: 14px;
  line-height: 1;
  text-align: center;

  @include media('>small') {
    font-size: 16px;
  }

  @include media('>medium') {
    font-size: 14px;
  }
  @include media('>large') {
    font-size: 20px;
  }
}
